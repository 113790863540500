import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Alert, Label, Input, FormGroup } from 'reactstrap';
import { updateEvent, setUpdated, addEvent } from '../../../redux/actions/eventActions'
import { eventInputs } from '../../variables'
import { sendEventInvite } from '../../../redux/actions/eventActions'

import EmailList from './EmailList/EmailList'
import MemberManagement from './MemberManagement/MemberManagement';

const EventUpdate = ({ openEventModal, eventModal, event, onChange, addMode, groups, userLoggedPermissionLevel }) => {
  const dispatch = useDispatch()

  const [disabled, setDisabled] = useState(false)
  const { message, id } = useSelector(state => state.errorReducer)
  const { updated, success, membersUpdated } = useSelector(state => state.eventReducer)
  const { users } = useSelector(state => state.userReducer)
  const [emailModal, setEmailModal] = useState(false)
  const [memberModal, setMemberModal] = useState(false)
  const authReducer = useSelector(state => state.authReducer)

  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);

  useEffect(() => {
    if (updated) {
      dispatch(setUpdated(false))
      openEventModal()
    }
  }, [updated])

  useEffect(() => {
    if (membersUpdated) {
      openEventModal(event)
    }
  }, [membersUpdated])

  useEffect(() => {
    setVisible(false)
  }, [event])

  useEffect(() => {
    if (success) {
      setDisabled(false)
      setVisible(true)
    }
  }, [success])

  const getValidUsers = () => {
    let arr = [];
    if (event.group === "all") {
      if (event.country === "ALL") {
        arr = users.map(e => {
          return {
            _id: e._id,
            RSVP: e.status.toLowerCase() == 'active' ? "No" : null,
            email: e.email,
            name: e.personalDetails.name,
            status: e.status,
            checked: false,
            group: e.membershipDetails?.group
          }
        })
      } else {
        arr = groups.filter(group => event.country === group.country).map(group => group.members).flat()?.map(e => {
          const user = users.find(user => user._id == e)
          if (user) {
            return {
              _id: e,
              RSVP: user.status.toLowerCase() == 'active' ? "No" : null,
              email: user.email,
              name: user.personalDetails.name,
              status: user.status,
              checked: false,
              group: user.membershipDetails?.group
            }
          }
        }).filter(e => e)
      }
    } else {
      arr = groups.find(group => event.group === group._id)?.members?.map(e => {
        const user = users.find(user => user._id == e)
        if (user) {
          return {
            _id: e,
            RSVP: user.status.toLowerCase() == 'active' ? "No" : null,
            email: user.email,
            name: user.personalDetails.name,
            status: user.status,
            checked: false,
            group: user.membershipDetails?.group
          }
        }
      }).filter(e => e)
    }
    return arr;
  };

  const onSubmit = e => {
    e.preventDefault()
    if (addMode) {
      const reqBody = event;
      reqBody.users = getValidUsers();
      dispatch(addEvent(reqBody))
    } else {
      var confirm = true
      if (confirm) {
        dispatch(updateEvent(event))
      }
    }
  }

  const emailInvite = (emailList) => {
    setDisabled(true)
    dispatch(sendEventInvite({ event, emailList }))
  }

  const openInviteList = () => { setEmailModal(!emailModal) }
  const openMemberList = () => { setMemberModal(!memberModal) }
  let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  if (!event) return null
  const eventGroup = event.group && groups ? groups.find(g =>
    g._id == event.group
  ) : null;
  const eventGroupCountryCode = eventGroup ? eventGroup.country : 'MY';
  const eventGroupName = eventGroup ? eventGroup.name : 'N/A';

  return (
    <div>
      <Modal isOpen={eventModal} toggle={() => openEventModal()} size='lg'>
        <ModalHeader toggle={() => openEventModal()}>{addMode ? 'Create New Event' : 'Update Event'}</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <EmailList
              emailModal={emailModal}
              openInviteList={openInviteList}
              event={event}
              emailInvite={emailInvite}
              membersUpdated={membersUpdated}
            />

            <MemberManagement
              memberModal={memberModal}
              openMemberList={openMemberList}
              event={event}
              members={users}
              membersUpdated={membersUpdated}
              groups={groups}
            />

            {id === 'EVENT_FAIL' && < Alert color='danger'>{typeof message === 'object' ? message.message : message}</Alert>}
            < Alert color='success' isOpen={visible} toggle={onDismiss}>Email Sent</Alert>
            {eventInputs.map(({ title, type, name, id = null, required = false, options = [] }, i) => {
              if (addMode && !required) { return null };
              if (userLoggedPermissionLevel !== 'Admin') {
                if (name == 'bocEvent' || name == 'country') { return null };
              };
              return <FormGroup key={name + i}>
                <Label>{title}</Label>
                {options.length > 0
                  ? <Input
                    type={type}
                    value={id && event[id] ? event[id][name] : event[name]}
                    name={name}
                    id={id}
                    required={required}
                    onChange={onChange}
                  >
                    {options.map((e, i) => <option key={e.value + i} value={e.value}>{e.name}</option>)}
                  </Input>
                  : <Input
                    type={type}
                    value={id && event[id] ? event[id][name] : event[name]}
                    name={name}
                    id={id}
                    required={required}
                    onChange={onChange}
                  />}
              </FormGroup>
            })}
            {userLoggedPermissionLevel == 'Admin' && <FormGroup>
              <Label>Group</Label>
              <Input
                type='select'
                value={event.group}
                name='group'
                id='group'
                required={true}
                onChange={onChange}
              >
                <option key='' value=''>
                  Please select
                </option>
                <option key='all' value='all'>
                  All Groups
                </option>
                {groups.map((e) => {
                  if (event.country && event.country !== "ALL" && event.country !== e.country) { return null };
                  return <option key={e._id} value={e._id}>
                    {e.name} ({regionNames.of(e.country)})
                  </option>
                })}
              </Input>
            </FormGroup>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type='submit'>{addMode ? 'Create Event' : 'Save'}</Button>{' '}
            {!addMode && <Fragment>
              <Button color="success" onClick={() => openMemberList()}>Manage Members</Button>
              <Button color="info" disabled={disabled} onClick={() => openInviteList()}>Email Invite</Button>
            </Fragment>}{' '}
            <Button color="secondary" onClick={() => openEventModal()}>Close</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div >
  );
}

export default EventUpdate;