import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { Form, Input, Button, FormGroup, Label, Row, Alert } from 'reactstrap'

import logo from '../../Assets/logo.png'
import { login } from '../../redux/actions/authActions';

function Landing() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch();
    const { isAuthenticated, authLoading } = useSelector((state) => state.authReducer);
    const { message, id } = useSelector((state) => state.errorReducer);

    const onSubmit = (e) => {
        e.preventDefault();
        // create a user object
        const user = {
            email: email.toLowerCase(),
            password,
        };
        //Attempt to Register
        dispatch(login(user));
    };

    // If parameters are provided use email
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString).get('email');
        setEmail(urlParams);
        if (urlParams) {
            toggle()
        }
    }, [])

    // Redirect if logged in
    if (isAuthenticated) {
        return <Redirect to='/posts' />;
        // return <Redirect to='/profile' />;

    }

    return (
        <Fragment>
            <center>
                <br />
                <br />
                <h3>Welcome members of</h3>
                <img src={logo}
                    alt='BoC Logo'
                    style={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '250px',
                        objectFit: 'contain',
                    }}
                />
                <Form onSubmit={onSubmit} style={{ maxWidth: 350, textAlign: 'left' }}>
                    {message && id === 'LOGIN_FAIL' && <Alert color="danger">{message.message}</Alert>}
                    <FormGroup>
                        <Label>Email</Label>
                        <Input
                            type="email"
                            defaultValue={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password" >Password</Label>
                        <Input
                            type="password"
                            defaultValue={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                        />
                    </FormGroup>
                    {/* <a href="/resetpassword" >Passwort vergessen</a> */}
                    <Button
                        color="primary"
                        style={{ marginTop: "2rem" }}
                        block
                        disabled={authLoading}
                    >
                        Login
                        </Button>
                </Form>
            </center>
        </Fragment>
    );
}

export default Landing;