import React, { Fragment, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import EventCardWithScore from './EventCardWithScore';
import './Awards.css';

function EventWinners(props) {
    const { pastEvents, users } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [numberOfCardsToShow, setNumberOfCardsToShow] = useState(3);
    const bocEvents = pastEvents.filter(function (item) { return item.bocEvent && item.eventType == 'Golf Event' });

    useEffect(() => {
        if (searchTerm == '') {
            setNumberOfCardsToShow(3)
        } else {
            setNumberOfCardsToShow(100)
        }
    }, [searchTerm])

    const handleShowMore = function () {
        if (bocEvents.length > numberOfCardsToShow + 3) {
            setNumberOfCardsToShow(numberOfCardsToShow + 3)
        } else {
            setNumberOfCardsToShow(bocEvents.length)
        }
    }

    const shouldShowMoreButton = function () {
        if (bocEvents.length > numberOfCardsToShow) {
            return true
        } else {
            return false
        }
    }

    return (
        <Fragment>
            <div>
                <input placeholder='Search events' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} style={{ width: '80%', marginLeft: '10%' }}></input>
                <div style={{ marginTop: '30px' }}>
                    {bocEvents.sort((a, b) => {
                        const dataA = new Date(a.date);
                        const dataB = new Date(b.date);
                        if (dataA < dataB) {
                            return 1;
                        }
                        if (dataA > dataB) {
                            return -1;
                        }
                        return 0;
                    }).slice(0, numberOfCardsToShow).map(r => {
                        if (searchTerm == '' || r.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return (
                                <div key={r._id}>
                                    <EventCardWithScore event={r} users={users} />
                                </div>
                            );
                        }
                    })}
                    {shouldShowMoreButton() && <Button color='primary' style={{ marginTop: '40px' }} onClick={handleShowMore}>View More</Button>}
                </div>
            </div>
        </Fragment>
    );
}

export default EventWinners;