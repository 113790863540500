import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { InputGroupAddon, Input, Spinner } from 'reactstrap';

import { getUserList, getUsers } from '../../redux/actions/userActions'
import FloatingButton from '../QR/FloatingButton';

//Import Components
import ProfileTable from './ProfileTable/ProfileTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import './Search.css';

function Search() {
    const dispatch = useDispatch()
    const [filter, setFilter] = useState('')
    const [loading, setLoading] = useState(true)
    const [iconColor, setIconColor] = useState('#696d70')
    const authReducer = useSelector(state => state.authReducer)
    const isAdmin = authReducer.user && authReducer.user.user.permissionLevel && authReducer.user.user.permissionLevel === 'Admin'

    useEffect(() => {
        if(isAdmin) dispatch(getUsers())
        else dispatch(getUserList())
    }, [])

    return (
        <Fragment> 
            <div className="search-group">
                <InputGroupAddon addonType="prepend">
                    <span className="input-group-text"><FontAwesomeIcon icon={faSearch} color={iconColor} /></span>
                </InputGroupAddon>
                <Input 
                    placeholder='Search Profiles'
                    value={filter}
                    className="search"
                    onChange={e => setFilter(e.target.value)}
                    disabled={loading}
                    onFocus={() => setIconColor('#495057')}
                    onBlur={()=>setIconColor('#696d70')}
                />
           
            </div> 
            <br />
            <ProfileTable
                filter={filter}
                setLoading={setLoading}
                loading={loading}
            />

            <FloatingButton/>

            {loading && <center><Spinner color='danger'></Spinner></center>}

        </Fragment>
    );
}

export default Search;