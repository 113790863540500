import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MultipleEmail from "../../components/MultipleEmail/MultipleEmail";
import { getNotificationEmails } from "../../redux/actions/notificationEmailActions";

const Notifications = (props) => {
  const dispatch = useDispatch();
  const { notificationEmails } = useSelector(
    (state) => state.notificationEmailReducer
  );

  const [email, setEmail] = useState({
    invoice: { emails: [], currentEmail: "" },
    noRenewal: { emails: [], currentEmail: "" },
    lowTokensUsers: { emails: [], currentEmail: "" },
    birthday: { emails: [], currentEmail: "" },
  });

  useEffect(() => {
    dispatch(getNotificationEmails());
  }, []);

  useEffect(() => {
    if (notificationEmails) {
      const transformedGroups = {};
      notificationEmails.forEach((group) => {
        transformedGroups[group.topic] = {
          emails: group.emails, // Emails array from DB
          currentEmail: "", // Start with an empty currentEmail
        };
      });

      setEmail(transformedGroups);
    }
  }, [notificationEmails]);

  // Function to update the current email for a group
  const setCurrentEmailForGroup = (group, currentEmail) => {
    setEmail((prevGroups) => ({
      ...prevGroups,
      [group]: { ...prevGroups[group], currentEmail },
    }));
  };

  // Function to update the emails for a group
  const setEmailsForGroup = (group, emails) => {
    setEmail((prevGroups) => ({
      ...prevGroups,
      [group]: { ...prevGroups[group], emails },
    }));
  };
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2>Email Receiver</h2>
        {/* Render MultipleEmail component for each group */}
        {Object.keys(email).map((group, index) => (
          <div key={index}>
            <MultipleEmail
              label={`${group}`}
              emails={email[group].emails}
              setEmails={(emails) => setEmailsForGroup(group, emails)}
              currentEmail={email[group].currentEmail}
              setCurrentEmail={(currentEmail) =>
                setCurrentEmailForGroup(group, currentEmail)
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;
