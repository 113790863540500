import { ADD_INVOICE, DELETE_INVOICE, INVOICE_FAIL, GET_INVOICE, GET_INVOICES, SET_INVOICE_UPDATED, UPDATE_INVOICE, SET_INVOICE, EMAIL_INVOICE, NORENEWAL_EMAIL } from '../types'
import axios from 'axios'
import { tokenConfig } from './authActions'
import { returnErrors, clearErrors } from './errorActions'
import { baseURL } from '../helpers'

export const getInvoices = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/invoices`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_INVOICES,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, INVOICE_FAIL));
            dispatch({
                type: INVOICE_FAIL,
            })
        })
}

export const getInvoicesById = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/invoices/user_id`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_INVOICES,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, INVOICE_FAIL));
            dispatch({
                type: INVOICE_FAIL,
            })
        })
}

export const getInvoice = (id) => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/invoices/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_INVOICE,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, INVOICE_FAIL));
            dispatch({
                type: INVOICE_FAIL,
            })
        })
}

export const addInvoice = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/invoices`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: ADD_INVOICE,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, INVOICE_FAIL));
            dispatch({
                type: INVOICE_FAIL,
            })
        })
}

export const emailInvoice = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/invoices/email`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: EMAIL_INVOICE,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, INVOICE_FAIL));
            dispatch({
                type: INVOICE_FAIL,
            })
        })
}

export const noRenewalEmail = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/invoices/noRenewal`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: NORENEWAL_EMAIL,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, INVOICE_FAIL));
            dispatch({
                type: INVOICE_FAIL,
            })
        })
}

export const deleteInvoice = id => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.delete(`${baseURL}/invoices/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: DELETE_INVOICE,
                payload: id
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, INVOICE_FAIL));
            dispatch({
                type: INVOICE_FAIL,
            })
        })
}

export const updateInvoice = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    console.log(requestBody)
    axios.patch(`${baseURL}/invoices`, requestBody, tokenConfig(getState))
        .then(response => {
            //check if status is paid -> show invoice preview in modal box
            //generate pdf -> store in gdrive
            dispatch({
                type: UPDATE_INVOICE,
                payload: response.data
            })


        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, INVOICE_FAIL));
            dispatch({
                type: INVOICE_FAIL,
            })
        })
}

export const setInvoice = invoice => (dispatch) => {
    dispatch({
        type: SET_INVOICE,
        payload: invoice
    })
}

export const setUpdated = state => (dispatch) => {
    dispatch({
        type: SET_INVOICE_UPDATED,
        payload: state,
    })
}

export const uploadDrive = requestBody => (dispatch, getState) => {
    axios.post(`${baseURL}/invoices/driveUpload`, requestBody, tokenConfig(getState)) //request body = pdf file
        .then(response => {
            console.log('upload to drive')

        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, INVOICE_FAIL));
            dispatch({
                type: INVOICE_FAIL,
            })
        })
}