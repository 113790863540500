import { ADD_NOTIFICATION_EMAIL, GET_NOTIFICATION_EMAILS, NOTIFICATION_EMAILS_FAIL } from '../types';

function initialState() {
    return {
        notificationEmails: [],
    };
}

const notificationEmailReducer = function (state = initialState(), action) {
    const { type, payload } = action
    switch (type) {
        case GET_NOTIFICATION_EMAILS:
            return {
                ...state,
                notificationEmails: payload,
            }

        case ADD_NOTIFICATION_EMAIL:
            return {
                ...state,
            }

        case NOTIFICATION_EMAILS_FAIL:
        default:
            return {
                ...state,
            }
    }
};

export default notificationEmailReducer;
