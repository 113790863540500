import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Button, Form, Modal, Nav, NavItem, NavLink, TabContent, TabPane, ModalHeader, ModalBody, ModalFooter, Col } from 'reactstrap';
import { deleteUser, setUser, updateUserDetails } from '../../../redux/actions/userActions'

import './UserTable.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faKey, faTrashAlt, faFileInvoiceDollar, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import InvoiceTable from '../InvoiceModal/InvoiceTable/InvoiceTable';
import UserEdit from '../UserModal/UserEdit';
import InvoiceModal from '../InvoiceModal/InvoiceModal'
import axios from 'axios';
import './MembersModal.css';
import InvoiceView from '../InvoiceModal/InvoiceView/InvoiceView';


function MembersModal({ setMembersModalVisible, MembersModalVisible, userDetails }) {
    const [activeTab, setActiveTab] = useState('1');
    // const [usersWithPhoto, setUsersWithPhoto] = useState([])
    const [invoiceModal, setInvoiceModal] = useState(false)
    const [invoiceState, setInvoiceState] = useState(false)
    const [viewState, setViewState] = useState(false)
    const [invoice, setInvoice] = useState(null)
    const [deleteModalState, setDeleteModalState] = useState(false)
    const authReducer = useSelector(state => state.authReducer)
    const [user, setUser] = useState(userDetails)
    const dispatch = useDispatch();


    // For managing Invoice Modal
    const openInvoiceModal = (user = userDetails) => {
        setInvoiceModal(!invoiceModal)
        setUser(user)
    }

    const onChange = e => {
        var object = { ...user }
        const { id, value, name } = e.target
        if (id) {
            // To create an object if it doesn't exist yet
            if (!object[id]) object[id] = {}
            object[id] = { ...object[id], [name]: value }
        } else {
            object[name] = value
        }
        setUser(object)
    }

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    const onDelete = (id) => {
        var confirm = window.confirm('Permanently delete this Member?')
        if (confirm) dispatch(deleteUser(id))
    }

    const resetPassword = async (email) => {
        // const confirm = window.confirm('Reset Password?')
        // if (!confirm) return null
        const user = {
            email
        }
        try {
            const response = await axios.post('api/auth/resetPasswordRequest', user)
            window.alert(response.data.message)
        } catch (err) {
            console.log(err)
        }
    }

    function closeToggle() {
        setMembersModalVisible(!MembersModalVisible)
    }

    function resetPasswordButton(user) {
        return (

            <span
                className='resetPasswordButton'
                onClick={() => resetPassword(user.email)}
            >
                <FontAwesomeIcon icon={faKey} /> Reset Password
            </span>)
    }

    function deleteAccountButton(user) {
        return (

            <span
                className='deleteAccountButton'
                onClick={() => onDelete(user._id)}
            >
                <FontAwesomeIcon icon={faTrashAlt} /> Delete Account
            </span>)
    }

    const onSubmit = e => {
        e.preventDefault()
        var confirm = true
        if (authReducer.user.user.id === user._id && user.status !== 'active') {
            confirm = window.confirm('Changing your status to inactive will prevent access. Proceed?')
        }
        if (confirm) {
            dispatch(updateUserDetails(user))
            closeToggle()
        }
    }

    function renderTabColor(tabId){
        return activeTab == tabId ? '#ffffff' : '#8C8C8C'
    }
    
    const viewInvoice = invoice => {
        setInvoice(invoice)
        toggleViewState()
    }

    const toggleViewState = () => {
        setViewState(!viewState)
    }

    return (
        <Fragment>
            <Modal 
                isOpen={MembersModalVisible} 
                toggle={()=>setMembersModalVisible(!MembersModalVisible)} 
                className="modal-md" 
                id="members-modal"
                style={{marginTop:'8vh'}}
                >
                <ModalBody>
                    {user && 
                        <React.Fragment>
                            <div onClick={closeToggle} className="text-right" style={{margin:'0px 16px 10px 16px' }}>
                                <FontAwesomeIcon className="icons" icon={faTimes} style={{ fontSize: 28 }} color="#606060" />
                            </div>
                            <div className="card">
                                <div class="media">
                                    {user.photoSRC && <img className="profile-avatar mr-3" src={user.photoSRC} />}
                                    <div class="media-body">
                                        <div style={{ fontWeight: 'bold' }} >{user.personalDetails.name}</div>
                                        <div>{user.companyDetails.jobTitle}</div>
                                    </div>
                                </div>
                                <div style={{ fontWeight: 'bold' }}> {user.companyDetails.name} </div>
                                <div>{user.companyDetails.industry}</div>
                            </div>
                            <div className="row">
                                <Col md="6" sm="6" xs="6">
                                    <span>{user.permissionLevel}</span>
                                </Col>
                                <Col md="6" sm="6" xs="6">
                                    <span className={`membershipStatus ${user.status}`}>{user.status}</span>
                                </Col>
                                <Col md="6" sm="6" xs="6">
                                    <span>{user.member_id}</span>
                                </Col>
                                <Col md="6" sm="6" xs="6">
                                    <span>{user.membershipDetails ? user.membershipDetails.expiryDate : null}</span>
                                </Col>
                            </div>
                            <hr />
                            <Nav>
                                <NavItem className="tab-buttons">
                                    <NavLink 
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggle('1'); }}>
                                        <FontAwesomeIcon icon={faFileInvoiceDollar} color={renderTabColor(1)} /> Invoice
                                    </NavLink>
                                </NavItem>
                                <NavItem className="tab-buttons">
                                    <NavLink 
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggle('2'); }}>
                                        <FontAwesomeIcon icon={faEdit} color={renderTabColor(2)} /> Edit Profile
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTab} style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 20 }}>
                                <TabPane tabId="1" >
                                    <Form onSubmit={(e) => {
                                        e.preventDefault()
                                        setInvoiceState(!invoiceState)
                                    }}>
                                        <InvoiceTable viewInvoice={viewInvoice} allDetails={user} />
                                        {/* has not been able to create new invoice */}
                                        <br />
                                        <Button className="btn-block" color="primary" type='submit'>Create Invoice</Button>
                                    </Form>
                                    <InvoiceView
                                        viewState={viewState}
                                        toggleViewState={toggleViewState}
                                        user={user}
                                        invoice={invoice}
                                    />
                                </TabPane>
                                <TabPane tabId="2" >
                                    <Form onSubmit={onSubmit}>
                                        <UserEdit user={user} onChange={onChange} addMode={false} />
                                        <Button color="primary" type='submit' style={{ width: '100%' }}> Save Profile </Button>
                                    </Form>
                                </TabPane>
                                <div>
                                    <hr />
                                    <Button
                                        className="btn-block bordered"
                                        color="basic"
                                        type='button'
                                        onClick={() => setDeleteModalState(!deleteModalState)}>
                                        <FontAwesomeIcon icon={faKey} color="#3C3C3C" /> Reset Password
                                    </Button>
                                    <div className="text-center text-red" style={{ marginTop: 15, cursor: "pointer" }}>
                                        <FontAwesomeIcon icon={faTrash} /> Delete Account
                                    </div>
                                </div>
                            </TabContent>
                        </React.Fragment >
                   }
                </ModalBody>
            </Modal>
            <Modal isOpen={deleteModalState} toggle={()=>setDeleteModalState(!deleteModalState)}>
                <ModalBody className="text-center">
                    <p style={{ fontSize: 18 }}>Confirm reset password?</p>
                    <Button color="primary" style={{ paddingLeft: 25, paddingRight: 25 }} onClick={()=>{
                          resetPassword();
                          setDeleteModalState(!deleteModalState);
                    }}>Yes</Button>{' '}
                    <Button color="danger" style={{ paddingLeft: 25, paddingRight: 25 }} onClick={() => setDeleteModalState(!deleteModalState)}>No</Button>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default MembersModal; 