import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateNotificationEmail } from '../../redux/actions/notificationEmailActions';

const MultipleEmail = (props) => {
    const { label, emails, setEmails, currentEmail, setCurrentEmail } = props;
    const dispatch = useDispatch();
    // Function to handle input change
    const handleEmailChange = (e) => {
        setCurrentEmail(e.target.value);
    };

    // Function to add email on "Enter" or "," press
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addEmail();
        }
    };

    // Function to add email to list
    const addEmail = () => {
        const trimmedEmail = currentEmail.trim();
        if (trimmedEmail && validateEmail(trimmedEmail)) {
            setEmails([...emails, trimmedEmail]);
            setCurrentEmail('');
            dispatch(updateNotificationEmail({
                topic: label,
                emails: [...emails, trimmedEmail]
            }))
        }
    };

    // Function to validate email format
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Function to remove an email from the list
    const removeEmail = (indexToRemove) => {
        setEmails(emails.filter((email, index) => index !== indexToRemove));
        dispatch(updateNotificationEmail({
            topic: label,
            emails: emails.filter((email, index) => index !== indexToRemove)
        }))
    };

    return (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'space-between', maxWidth: '800px', margin: '12px auto',alignItems:'center' }}>
            <div style={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'uppercase', lineHeight: '' }}>{label}</div>
            <div style={{ display: 'flex', flexWrap: 'wrap', border: '1px solid black', padding: '5px', minHeight: '50px', width: '100%', maxWidth: '600px' }}>
                {emails.map((email, index) => (
                    <div key={index} style={{ margin: '5px', padding: '5px', backgroundColor: '#e0e0e0', borderRadius: '5px' }}>
                        {email}
                        <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => removeEmail(index)}>x</span>
                    </div>
                ))}
                <input
                    type="text"
                    value={currentEmail}
                    onChange={handleEmailChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter email addresses..."
                    style={{ border: 'none', outline: 'none', flex: '1' }}
                />
            </div>
        </div>
    );

}

export default MultipleEmail;