import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from 'reactstrap'

import { getInvoices } from '../../redux/actions/invoiceActions'
import { getUser, getUsers } from '../../redux/actions/userActions'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf'

//Import Components
import InvoiceTable from '../UserList/InvoiceModal/InvoiceTable/InvoiceTable'
import InvoiceView from '../UserList/InvoiceModal/InvoiceView/InvoiceView'

function Invoices() {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.userReducer)
    const { statusPaid } = useSelector(state => state.invoiceReducer)

    useEffect(() => {
        dispatch(getInvoices())
        dispatch(getUsers())
    }, [])



    // For managing Invoice Modal
    const [invoice, setInvoice] = useState(null)
    const [viewState, setViewState] = useState(false)
    const [textButton, settextButton] = useState(false) // added

    useEffect(() => {
        if (invoice && invoice.user_id) {
            dispatch(getUser(invoice.user_id))
        }
    }, [invoice])

    const toggleViewState = () => {
        setViewState(!viewState)
    }

    const viewInvoice = (invoice = null, txtBtn = null, user) => {
        settextButton (txtBtn)
        setInvoice(invoice)
        toggleViewState()
    }

    // create another useEffect function to trigger the modal box view
    useEffect(() => {
        // check if the statusPaid == true
        // if true then show the modal box
        // how ? by updating the state value to true, point to toggleViewState() function
        // add one more state called driveUpload
    }, [statusPaid])

    return (
        <Fragment>
            <InvoiceView
                txtBtn = {textButton}
                viewState={viewState}
                toggleViewState={toggleViewState}
                user={user}
                invoice={invoice}
                
            />
            <InvoiceTable viewInvoice={viewInvoice} allDetails={true} />
        </Fragment>
    );
}

export default Invoices;