import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone, faEnvelope, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import profilePlaceholder from '../../../Assets/placeholder.png';
import './ProfileTable.css';
import { baseURL } from '../../../redux/helpers';

function ProfileTable({ loading, filter, setLoading }) {
    const { users } = useSelector(state => state.userReducer)
    const token = useSelector(state => state.authReducer.token)
    const [windowHeight, setWindowHeight] = useState(`${window.innerHeight - 200}px`)
    const [filteredUsers, setFilteredUsers] = useState([])
    const [usersWithPhoto, setUsersWithPhoto] = useState([])
    const authReducer = useSelector(state => state.authReducer)
    const isAdmin = authReducer.user && authReducer.user.user.permissionLevel && authReducer.user.user.permissionLevel === 'Admin'

    const statusFilteredUsers =(isAdmin)?users:users.filter(us => us.status.toLowerCase() == 'new' || us.status.toLowerCase() == 'active' || us.status.toLowerCase() == 'exempted');
    //Used to adjust view based on screen size
    useEffect(() => {
        function handleResize() {
            setWindowHeight(`${window.innerHeight - 210}px`)
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // To get profile photo
    useEffect(async () => {
        if (statusFilteredUsers && statusFilteredUsers.length) {
            setUsersWithPhoto(statusFilteredUsers)
            setLoading(false)
            // Headers
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            //if there is a token then add it to the header
            if (token) {
                config.headers['x-auth-token'] = token;
            }
            const usersWithPhoto = []
            for (var i = 0; i < statusFilteredUsers.length; i++) {
                if (statusFilteredUsers[i].photo) {
                    const thePhotoType = statusFilteredUsers[i].photo.substring(0, 4);
                    if (thePhotoType == 'http' || thePhotoType == 'data') {
                        const originalPhoto = statusFilteredUsers[i].photo;
                        usersWithPhoto.push({ ...statusFilteredUsers[i], photoSRC: originalPhoto });
                    } else {
                        const { data } = await axios.post(`${baseURL}/users/getPhoto`, { photo: statusFilteredUsers[i].photo }, config)
                        let buf = Buffer.from(data.Body);
                        const file = statusFilteredUsers[i].photo.split('.')
                        const fileType = file[file.length - 1]
                        let base64 = buf.toString('base64');
                        const photoSRC = `data:image/${fileType};base64,${base64}`
                        usersWithPhoto.push({ ...statusFilteredUsers[i], photoSRC })
                    }
                } else {
                    const photoSRC = profilePlaceholder
                    usersWithPhoto.push({ ...statusFilteredUsers[i], photoSRC })
                }
            }
            setUsersWithPhoto(usersWithPhoto)
        }
    }, [users]);

    useEffect(() => {
        if (filter) {
            var userList = []
            usersWithPhoto.forEach(user => {
                var toInclude = false
                filter = filter.toLowerCase()
                Object.keys(user).forEach(key => {
                    if (
                        key == '_id' ||
                        key == "permissionLevel" ||
                        key == "password" ||
                        key == "createdAt" ||
                        key == "updatedAt" ||
                        key == "photoSRC" ||
                        key == "membershipDetails" ||
                        key == "socialMedia" ||
                        key == "availableTokens"
                    ) return
                    switch (typeof user[key]) {
                        case 'object':
                            if(user[key]){
                                Object.keys(user[key]).forEach(secondaryKey => {
                                if (user[key][secondaryKey] && user[key][secondaryKey].toString().toLowerCase().includes(filter)) {toInclude = true}
                                })
                            }
                            break
                        default:
                            if (user[key] && user[key].toString().includes(filter)) {
                                toInclude = true
                            }
                            break
                    }
                })
                if (toInclude) userList.push(user)
            })
            setFilteredUsers(userList)
        } else {
            setFilteredUsers([])
        }
    }, [filter, usersWithPhoto])
    if (loading) return <div></div>

    function renderActionBtn(user) {
        return (
            <Fragment>
                <span className="action-btn" onClick={() => window.open(`/profile/${user._id}`, "_blank")}>
                    <FontAwesomeIcon icon={faUser} color="#3C3C3C" /> Profile
                </span>
                {user.companyDetails && user.companyDetails.phone ? (
                    <span className="action-btn" onClick={() => window.open(`callto:${user.companyDetails.phone}`, "_self")}>
                        <FontAwesomeIcon icon={faPhone} color="#3C3C3C" /> Call
                    </span>
                ) : null}
                {user.email ? (
                    <span
                        className="action-btn"
                        onClick={() => window.open(`mailto:${user.email}`, "_self")}>
                        <FontAwesomeIcon icon={faEnvelope} color="#3C3C3C" /> Email
                    </span>
                ) : null}
                {user.companyDetails && user.companyDetails.website ? (
                    <span
                        className="action-btn"
                        onClick={() => {
                            let website = user.companyDetails.website
                            if (!website.match(/^https?:/)) {
                                website = "//" + website
                            }
                            window.open(website, "_blank")
                        }}>
                        <FontAwesomeIcon icon={faExternalLinkAlt} color="#3C3C3C" /> Website
                    </span>
                ) : null}
            </Fragment>
        )
    };

    return (
        <div className="profile-table">
            {filteredUsers.map(user => {
                var personalDetailsFlag = user.personalDetails ? true : false
                var companyDetailsFlag = user.companyDetails ? true : false
                user.membershipDetails && user.membershipDetails.expiryDate && console.log()
                return (
                    <div className="row-wrapper" key={user._id} onClick={() => {
                        if (window.innerWidth < 427) {
                            window.open(`/profile/${user._id}`, "_self")
                        }
                    }}>
                        <div className="row">
                            <div className="col" style={{ maxWidth: 250 }}>
                                <div className="media">
                                    {user.photoSRC && <img className="profile-avatar mr-3" src={user.photoSRC} />}
                                    <div className="media-body">
                                        <div style={{ fontWeight: 'bold' }} >{personalDetailsFlag && user.personalDetails.name}</div>
                                        <div>{companyDetailsFlag && user.companyDetails.jobTitle}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col hidden-mobile" style={{ maxWidth: 250 }}>
                                <div style={{ fontWeight: 'bold' }}> {companyDetailsFlag && user.companyDetails.name} </div>
                                <div>{companyDetailsFlag && user.companyDetails.industry}</div>
                            </div>
                            <div className="col desc-wrapper">
                                <div className="description">{user.companyDetails ? user.companyDetails.description : ''}</div>
                                <div className="web-action-button">
                                    {renderActionBtn(user)}
                                </div>
                            </div>
                        </div>
                        <div className="row visible-tablet">
                            <div className="col">
                                <div className="description text-left">
                                    {user.companyDetails ? user.companyDetails.description : ''}
                                </div>
                                <div className="action-tablet">
                                    {renderActionBtn(user)}
                                </div>
                            </div>
                        </div>
                        <div className="row visible-mobile">
                            <div className="col-sm-12">
                                <div style={{ fontWeight: 'bold' }}> {companyDetailsFlag && user.companyDetails.name} </div>
                                <div>{companyDetailsFlag && user.companyDetails.industry}</div>
                            </div>
                            <div className="col-sm-12" style={{ marginBottom: 15, marginTop: 15 }}>
                                <div className="description">{user.companyDetails ? user.companyDetails.description : ''}</div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default ProfileTable;
