import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Alert, Label, Input, FormGroup, Col } from 'reactstrap';
// import members from './MemberList csv.json'
import { emailInvoice, updateInvoice, uploadDrive } from '../../../../redux/actions/invoiceActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

import InvoiceTemplate from './InvoiceTemplate/InvoiceTemplate'

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf'
import InvoiceTemplateOld from './InvoiceTemplate/InvoiceTemplateOld';



const InvoiceView = ({ viewState, toggleViewState, user, invoice, txtBtn }) => {
    const dispatch = useDispatch()
    const [template, setTemplate] = useState('')
    const [disabled, setDisabled] = useState(false)
    const { success } = useSelector(state => state.invoiceReducer)
    const authReducer = useSelector(state => state.authReducer)
    const isAdmin = authReducer.user && authReducer.user.user.permissionLevel && authReducer.user.user.permissionLevel === 'Admin'

    // useEffect(() => {
    //     if (user && invoice) {
    //         const newTemplate = invoiceTemplate(user, invoice)
    //         setTemplate(newTemplate)
    //     }
    // }, [invoice])

    useEffect(() => {
        if (success) {
            setDisabled(false)
        }
    }, [success])

    const downloadPDF = () => {
        var body = document.getElementById('invoiceTemplate');

        var fileName = `${invoice.invoiceNo}.pdf`
        html2canvas(body).then(function (canvas) {
            var image = canvas.toDataURL('image/png');
            let doc
            if (canvas.width > canvas.height) {
                doc = new jsPDF('l', 'mm', [canvas.width + 60, canvas.height + 60]);
            }
            else {
                doc = new jsPDF('p', 'mm', [canvas.height + 60, canvas.width + 60]);
            }
            doc.addImage(image, 'PNG', 30, 30, canvas.width, canvas.height, '', 'FAST');
            doc.save(fileName);

        });
    }

    const onSubmit = e => {
        e.preventDefault()
        var body = document.getElementById('invoiceTemplate');
        window.scrollTo(0, 0);

        var fileName = `${invoice.invoiceNo}.pdf`


        html2canvas(body).then(function (canvas) {
            var image = canvas.toDataURL('image/png');
            // var doc = new jsPDF('p', 'mm', "a4");
            // var width = doc.internal.pageSize.getWidth();
            // var height = doc.internal.pageSize.getHeight();
            // doc.addImage(image, 'PNG', 30, 10, width - 60, height - 60, '', 'FAST');
            let doc
            if (canvas.width > canvas.height) {
                doc = new jsPDF('l', 'mm', [canvas.width + 60, canvas.height + 60]);
            }
            else {
                doc = new jsPDF('p', 'mm', [canvas.height + 60, canvas.width + 60]);
            }
            doc.addImage(image, 'PNG', 30, 30, canvas.width, canvas.height, '', 'FAST');

            setDisabled(true)
            const data = new FormData();
            data.append('email', user.email);
            data.append('name', user.personalDetails.name);
            data.append('invoiceNo', invoice.invoiceNo);
            const pdf = doc.output('blob');
            data.append('pdf', pdf);

            if (txtBtn) {
                dispatch(updateInvoice({ _id: invoice._id, status: 'Paid' }))
                dispatch(uploadDrive(data))
            }
            else {
                dispatch(emailInvoice(data))
            }

        });

    }

    if (!user) return null
    if (!invoice) return null
    return (
        <Fragment>
            <Modal isOpen={viewState} size='lg' toggle={() => toggleViewState()}>
                <div class="modal-header">
                    <Col>
                        <h5 class="modal-title">{invoice.invoiceNo}</h5>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-end">
                        <Button color="danger" onClick={() => downloadPDF()}>
                            <FontAwesomeIcon icon={faFilePdf} /> Download
                        </Button> &nbsp;&nbsp;
                        {isAdmin && <div>

                            {txtBtn ? <Button color="primary" type='submit' disabled={disabled}>
                                {txtBtn}
                            </Button> : <Button color="primary" type='submit' onClick={(e) => { onSubmit(e) }} disabled={disabled}>
                                Send Invoice
                            </Button>}{' '}

                        </div>}&nbsp;&nbsp;
                        <button type="button" class="close mx-0 mb-1 mt-0" onClick={() => toggleViewState()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Col>
                </div>
                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        {
                            invoice.sst ?
                                <InvoiceTemplate
                                    user={user}
                                    invoice={invoice}
                                /> :
                                <InvoiceTemplateOld
                                    user={user}
                                    invoice={invoice}
                                />
                        }

                    </ModalBody>
                </Form>
            </Modal>
        </Fragment>
    );
}

export default InvoiceView;
