import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import { getEvents, getPastEvents } from '../../redux/actions/eventActions';
import { getGroupList } from '../../redux/actions/groupActions';
import { getUserList } from '../../redux/actions/userActions';
//Import Variables
import { defaultEvent } from '../variables';

//Import Components
import EventModal from './EventModal/EventModal';
import EventCard from '../../components/EventCard/EventCard';
import "../../components/EventCard/EventDetails.css";
import MobileModal from '../../components/EventCard/MobileModal/MobileModal';
import EventFilter from './EventFilter/EventFilter';
import RSVPModal from '../../components/EventCard/RSVPModal/RSVPModal';

function EventList() {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.authReducer)
    const { groups } = useSelector(state => state.groupReducer)
    const { membersUpdated, events: rEvents, event: rEvent } = useSelector(state => state.eventReducer)
    const events = rEvents.map((e) => ({
        ...e,
        showScoresAt: e?.showScoresAt ? e.showScoresAt.split('T')[0] : null
    }));

    const [event, setEvent] = useState(null)
    const [addMode, setAddMode] = useState(false)
    const userLoggedPermissionLevel = user && user.user && user.user.permissionLevel || {}
    const [eventType, setEventType] = useState('1')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getUserList())
        dispatch(getEvents())
        dispatch(getGroupList())
    }, []);

    // useEffect(() => {
    //     setLoading(false);
    // }, [events]);

    useEffect(() => {
        if (rEvent) {
            setEvent(rEvent)
        }
    }, [rEvent])

    // For managing Event Modal
    const [eventModal, setEventModal] = useState(false);
    const [groupOfUser, setGroupOfUser] = useState();
    const [editModal, setEditModal] = useState(false);

    const openEventModal = (event = defaultEvent, addMode = false) => {
        if (membersUpdated && rEvent) {
            dispatch({
                type: 'SET_EVENT_MEMBERS_UPDATED',
                payload: false,
            })
            setEventModal(true)
        } else {
            setEventModal(!eventModal)
        }
        setEvent(event)
        setAddMode(addMode)
    }

    const onChange = e => {
        var object = { ...event }
        const { id, value, name } = e.target
        if (id) {
            // To create an object if it doesn't exist yet
            if (!object[id]) object[id] = {}
            object[id] = { ...object[id], [name]: value }
        } else {
            object[name] = value
        }
        var nestedGroup = object?.group?.group;
        if (nestedGroup) { object.group = nestedGroup };
        if (userLoggedPermissionLevel !== 'Admin' && groupOfUser) {
            object.bocEvent = false;
            object.group = groupOfUser._id;
        };
        setEvent(object)
    }

    const filterEventList = (value) => {
        setEventType(value)
        if (value === '1') {
            dispatch(getEvents())
        } else {
            dispatch(getPastEvents())
        }
    }

    const userIsGroupMember = () => {
        let groupFound = false;
        groups.map(g => {
            if (user.user && g.members.includes(user.user.id)) {
                if (!groupOfUser) { setGroupOfUser(g) };
                groupFound = true;
            }
        });
        return groupFound;
    }
    const [currentEvent, setCurrentEvent] = useState(null);
    const [rsvpModal, setRsvpModal] = useState(false);
    const [userList, setUserList] = useState([]);
    const [hover, setHover] = useState(null);



    const openRsvpList = (userList = []) => {
        setRsvpModal(!rsvpModal);
        setUserList(userList);
    };

    return (
        <Fragment>
            {userLoggedPermissionLevel === "Admin" || userIsGroupMember() ? <Fragment>
                <Button onClick={() => openEventModal(defaultEvent, true)} className='add-event'>Add Event</Button>
                <br></br>
                <br></br>
                <EventModal
                    openEventModal={openEventModal}
                    eventModal={eventModal}
                    event={event}
                    onChange={onChange}
                    addMode={addMode}
                    groups={groups}
                    userLoggedPermissionLevel={userLoggedPermissionLevel}
                />
            </Fragment> : null}
      
            <EventFilter filterEventList={filterEventList} setLoading={setLoading} />
            {loading ?
                <h2 style={{ textAlign: 'center', marginTop: '32px' }}>Loading events ...</h2> :
                Array.isArray(events) && events.length>0?
                 React.Children.toArray(events.sort((a, b) => {
                    const sortValue = eventType === '1' ? -1 : 1
                    if (a.date > b.date) return -sortValue
                    if (a.date < b.date) return sortValue
                    return 0
                }).map(event => {
                    var isAdmin = userLoggedPermissionLevel == "Admin";
                    var userHasGroup = groupOfUser ? true : false;
                    var eventGroupId = event.group;
                    var userGroupMatchesEventGroup = userHasGroup ? groupOfUser._id == eventGroupId : false;
                    var globalPost = event.group == 'all' && event.country == 'ALL';

                    // if (isAdmin || userGroupMatchesEventGroup || globalPost) {
                    return <>
                        <EventCard
                            event={event}
                            isTable={true}
                            openEventModal={openEventModal}
                            setEventModal={setEventModal}
                            userLoggedPermissionLevel={userLoggedPermissionLevel}
                            setEditModal={setEditModal}
                            setCurrentEvent={setCurrentEvent}
                            openRsvpList={openRsvpList}
                            hover={hover}
                            setHover={setHover}
                        />
                    </>
                    // } else {return null}
                })):<h2 className='noEventDetails'>
                    No events ....
                    </h2>}
            <br />
            <br />
            {userLoggedPermissionLevel === "Admin" || userIsGroupMember() ? <div className="add-event-bottom">
                <div
                    className="event-button"
                    onClick={() => openEventModal(defaultEvent, true)}
                >
                    Add Events
                </div>
            </div> : null}

            <MobileModal
                modal={editModal}
                toggle={() => setEditModal(!editModal)}
                event={currentEvent}
                openRsvpList={openRsvpList}
                hover={hover}
                setHover={setHover}
                user={user.user}
            />
             <RSVPModal
                    modal={rsvpModal}
                    toggle={openRsvpList}
                    userList={userList}
                />
        </Fragment>
    );
}

export default EventList;
