import React, { Fragment, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Row, ToggleButton } from 'react-bootstrap';

import '../../../components/EventCard/EventDetails.css';

function EventFilter({ filterEventList, setLoading }) {
    const [radioValue, setRadioValue] = useState('1');
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const radios = [
    { name: 'Upcoming Events', value: '1' },
    { name: 'Past Events', value: '2' },
];

  const setNewEventList = async (value) => {
    await filterEventList(value);
    setRadioValue(value);
    await delay(1000);
    setLoading(false);
  };

  return (
    <center>
      <ButtonGroup>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={idx % 2 ? 'outline-dark' : 'outline-dark'}
            name="radio"
            className="toggle-button"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => {
              setLoading(true);
              setNewEventList(e.target.value);
            }}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </center>
  );
}

export default EventFilter;