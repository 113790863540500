import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersWithTokens, addCustomTokens } from '../Tokens.redux';
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import JsonToExcel from './GenerateExcel';
import warningCircle from '../../../Assets/warningCircle.png';
import css from "./TokensAdmin.module.css";
import CustomSpinner from '../../../components/CustomSpinner/CustomSpinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadTokenTab, updateTokenTab} from '../../../redux/actions/tabActions';
import Exclamation from '../../../Assets/exclamation-solid.svg';
import moment from 'moment';

const stateColors = {
    active: { bg: 'green', clr: 'white' },
    new: { bg: '#FFAA29', clr: 'white' },
    renewal: { bg: '#F32828', clr: 'white' },
    exempted: { bg: '#72bcd4', clr: 'black' },
    expired: { bg: 'black', clr: 'white' },
};

const TokensAdmin = (props) => {
    const dispatch = useDispatch();
    const { usersWithTokens, customMessage, error, loading } = useSelector(state => state.tokenManagementReducer);
    const { setUserToShow } = props;

    const [isCustomTokensModalOpen, setIsCustomTokensModalOpen] = useState(false);
    const [customTokens, setCustomTokens] = useState({});
    const [maxDeduction, setMaxDeduction] = useState(0);
    const [onlyInsufficientMembers, setOnlyInsufficientMembers] = useState(false);
    const [filterActive, setFilterActive] = useState(true);
    const [filterNew, setFilterNew] = useState(true);
    const [filterRenewal, setFilterRenewal] = useState(true);
    const [filterExpired, setFilterExpired] = useState(true);

    const membersWithNoEnoughTokens = usersWithTokens.filter(us => us.availableTokens <= 3);
    const toShowMembers = onlyInsufficientMembers ? membersWithNoEnoughTokens : usersWithTokens;
    const {accessTokenTab,userName,updatedAt} = useSelector((state) => state.tabReducer);
    const [memberViewTokenTab, setmemberViewTokenTab] = useState(false);
    const {user} = useSelector(state => state.authReducer);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const getTokenColor = count => {
        if (count > 3) {
            return stateColors.active.bg;
        } else if (count > 0) {
            return stateColors.new.bg;
        } else {
            return stateColors.renewal.bg;
        };
    };

    const showToast = (text, status) => {
        const params = {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        };

        if (status == 'error') {
            toast.error(text, params);
        } else {
            toast.success(text, params);
        };
    };

    useEffect(() => {
        dispatch(getUsersWithTokens());
        dispatch(loadTokenTab());
    }, []);

    useEffect(() => {
        if (customMessage) {
            showToast(customMessage, 'success');
        };
    }, [customMessage]);

    useEffect(() => {
        if (error) {
            showToast(error, 'error');
        };
    }, [error]);

    useEffect(()=>{
        setmemberViewTokenTab(accessTokenTab);
    },[accessTokenTab]);

    return <div>
        <h2 style={{ textAlign: "center", marginBottom: "50px" }}>
            Token Management Board
        </h2>

        <ToastContainer />
        {loading ? <CustomSpinner /> : null}

        <div className={css.tokensStats}>
            <div className={css.tokensStatsItem}>
                <h3>{usersWithTokens.length}</h3>
                <div>Total number of members</div>
            </div>
            <div className={css.tokensStatsItem}>
                <h3 style={{ color: stateColors.active.bg }}>
                    {usersWithTokens.length - membersWithNoEnoughTokens.length}
                </h3>
                <div>Members with sufficient tokens</div>
            </div>
            <div className={css.tokensStatsItem}>
                <h3 style={{ color: stateColors.renewal.bg }}>
                    {membersWithNoEnoughTokens.length}
                </h3>
                <div>Members with insufficient tokens</div>
            </div>
        </div>

       <div className={css.flexExcel}>
            <JsonToExcel />
            <div className={css.viewOwnToken}>
                <div style={{marginRight:'10px'}}>Member View Own Token</div>
                <ToggleSwitch
                    value={memberViewTokenTab}
                    setValue={(value)=>{
                        
                        setmemberViewTokenTab(value);
                        const data={
                            token_tab: value,
                            user_id:user.user.id,
                        }
                        dispatch(updateTokenTab(data));
                        
                    }}
                    color={stateColors.active.bg}/>
                    {userName &&    <div className={css.tooltip}>
                        <img width={2} src={Exclamation} />
                        <span className={css.tooltiptext}>
                            <div>
                               Updated by {userName??''}
                            </div>
                            <div>
                                Updated date {updatedAt?moment(updatedAt).format('DD-MM-YYYY'):'N/A'}
                            </div>
                        </span>
                    </div> }
               
            </div>
       </div> 

        <div className={css.toggleAction}>
            <div style={{ color: stateColors.active.bg }}>
                Active
            </div>
            <ToggleSwitch
                value={filterActive}
                setValue={setFilterActive}
                color={stateColors.active.bg}
            />

            <div style={{ color: stateColors.new.bg }}>
                New
            </div>
            <ToggleSwitch
                value={filterNew}
                setValue={setFilterNew}
                color={stateColors.new.bg}
            />

            <div style={{ color: stateColors.renewal.bg }}>
                Renewal
            </div>
            <ToggleSwitch
                value={filterRenewal}
                setValue={setFilterRenewal}
                color={stateColors.renewal.bg}
            />

            <div style={{ color: stateColors.expired.bg }}>
                Expired
            </div>
            <ToggleSwitch
                value={filterExpired}
                setValue={setFilterExpired}
                color={stateColors.expired.bg}
            />
        </div>

        <div className={css.toggleAction}>
            <div>
                Insuffient token members
            </div>
            <ToggleSwitch
                value={onlyInsufficientMembers}
                setValue={setOnlyInsufficientMembers}
            />
        </div>

        {toShowMembers.map(user => {
            const { personalDetails, availableTokens, member_id, status, _id } = user;

            if (
                status.toLowerCase() == "active" && !filterActive ||
                status.toLowerCase() == "new" && !filterNew ||
                status.toLowerCase() == "renewal" && !filterRenewal ||
                status.toLowerCase() == "expired" && !filterExpired
            ) {
                return null;
            };

            return <div
                className={css.memberCard}
                key={user._id}
            >
                <div className={css.memberCardTop}>
                    <div className={css.memberCardItem}>
                        <span className={css.availableTokens}>Available Tokens: </span>
                        <span
                            className={css.availableTokens}
                            style={{ color: getTokenColor(availableTokens) }}
                        >
                            {availableTokens}
                        </span>
                        {availableTokens < 4 ? <div className={css.tooltip}>
                            <img src={warningCircle} />
                            <span className={css.tooltiptext}>
                                You are low on tokens, please renew membership!
                            </span>
                        </div> : null}
                    </div>
                    <div
                        className={css.memberCardStatus}
                        style={{
                            backgroundColor: stateColors[status?.toLowerCase()].bg,
                            color: stateColors[status?.toLowerCase()].clr
                        }}
                    >
                        {status.toUpperCase()}
                    </div>
                </div>
                <div className={css.memberCardContent}>
                    <div className={css.memberCardItem}>
                        <strong>Name: </strong>{personalDetails?.name}
                    </div>
                    <div className={css.memberCardItem}>
                        <strong>Member Id: </strong>{member_id}
                    </div>
                </div>

                <div className={css.memberCardActions}>
                    <Button color='primary' onClick={() => {
                        setCustomTokens({ user_id: _id });
                        setMaxDeduction(availableTokens);
                        setIsCustomTokensModalOpen(true);
                    }}>
                        Add new tokens
                    </Button>
                    <Button color='outline-primary' onClick={() => {
                        dispatch(addCustomTokens({
                            user_id: _id,
                            amount: 12,
                            comment: "Membership Renewal",
                        }));
                    }}>
                        Renew membership
                    </Button>
                    <Button color='outline-success' onClick={() => {
                        setUserToShow(user);
                    }}>
                        View history
                    </Button>
                </div>
            </div>
        })}
        <Modal
            isOpen={isCustomTokensModalOpen}
            toggle={() => setIsCustomTokensModalOpen(!isCustomTokensModalOpen)}
        >
            <ModalHeader
                toggle={() => setIsCustomTokensModalOpen(!isCustomTokensModalOpen)}
            >
                Add new Tokens
            </ModalHeader>
            <ModalBody>
                <Label>Number of tokens</Label>
                <Input
                    type='number'
                    name='amount'
                    placeholder='3'
                    value={customTokens.amount}
                    onChange={(e) => setCustomTokens({ ...customTokens, amount: e.target.value })}
                />
                <div style={{ fontSize: "12px", marginTop: "6px" }}>
                    Max token deduction is (-{maxDeduction})
                </div>
                <br />
                <Label>Comment</Label>
                <Input
                    type='text'
                    name='comment'
                    placeholder='Comment...'
                    value={customTokens.comment}
                    onChange={(e) => setCustomTokens({ ...customTokens, comment: e.target.value })}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    color='primary'
                    disabled={
                        !customTokens.amount ||
                        customTokens.amount == 0 ||
                        (customTokens.amount < 1 && customTokens.amount * -1 > maxDeduction) ||
                        customTokens.amount % 1 != 0 ||
                        !customTokens.comment
                    }
                    onClick={() => {
                        dispatch(addCustomTokens(customTokens));
                        setMaxDeduction(0);
                        setIsCustomTokensModalOpen(false);
                    }}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    </div>
};

export default TokensAdmin;