import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserList } from '../../redux/actions/userActions';
import TokensAdmin from './TokensAdmin/TokensAdmin';
import TokensUser from './TokensUser/TokensUser';

import css from "./Tokens.module.css";

const TokensMain = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.authReducer);

    const isAdmin = user && user.user?.permissionLevel == 'Admin';
    const [userToShow, setUserToShow] = useState();

    useEffect(() => {
        dispatch(getUserList());
    }, []);

    return <div className={css.mainTokensDiv}>
        {isAdmin && !userToShow ?
            <TokensAdmin setUserToShow={setUserToShow} />
            :
            <TokensUser
                currentUser={user}
                userToShow={userToShow}
                setUserToShow={setUserToShow}
            />
        }
    </div>
};

export default TokensMain;