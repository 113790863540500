import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = (props) => {
    const { value, setValue, color="#2196F3" } = props;

    return <label class="switch" onClick={(e) => {
        setValue(!value);
        e.preventDefault();
    }}>
        <input type="checkbox" checked={value} />
        <span style={{backgroundColor: value && color}} class="slider round"></span>
    </label>
};

export default ToggleSwitch;