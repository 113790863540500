
import React, { useState, Fragment, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateEventMembers } from '../../../../redux/actions/eventActions';
import './MemberManagement.css'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const MemberManagement = ({ memberModal, openMemberList, event, members, membersUpdated, groups }) => {
    const dispatch = useDispatch()

    const [nonEventMember, setNonEventMember] = useState([]);
    const [eventMember, setEventMember] = useState([]);
    const { users } = useSelector(state => state.userReducer)
    const { loading: eventLoading } = useSelector(state => state.eventReducer)

    const getNewMembers = (oldUsers = [], allUsers = []) => {
        const newUsers = [];
        const oldUsersIds = oldUsers.map(u => u._id);
        allUsers.map(u => {
            if (!oldUsersIds.includes(u._id)) {
                if (event.group && event.group !== "all") {
                    const currentGroup = groups.find(g => g._id == event.group);
                    if (currentGroup && currentGroup?.members.includes(u._id)) {
                        newUsers.push(u);
                    };
                } else {
                    newUsers.push(u);
                };
            };
        });
        return newUsers;
    };


    useEffect(() => {
        if (event.users && event.users.length > 0) {
            setEventMember(event.users.filter(u => u.RSVP));
            setNonEventMember(event.users.filter(u => !u.RSVP));
        };
    }, [event.users]);

    const selectAllNonEventMember = (state) => {
        setNonEventMember(nonEventMember.map(user => {
            return { ...user, checked: state }
        }))
    }

    const selectAllEventMember = (state) => {
        setEventMember(eventMember.map(user => {
            return { ...user, checked: state }
        }))
    }

    const updateSelectedStatus = (_id, memberType) => {
        if (memberType === 'non member') {
            setNonEventMember(nonEventMember.map(user => {
                if (user._id === _id) {
                    return { ...user, checked: !user.checked }
                } else {
                    return user
                }
            }))
        } else {
            setEventMember(eventMember.map(user => {
                if (user._id === _id) {
                    return { ...user, checked: !user.checked }
                } else {
                    return user
                }
            }))
        }
    }

    const addToEvent = (forcedList) => {
        const reqBody = event;
        let tempArr = forcedList && forcedList.length > 0 ? forcedList : [];
        if (!forcedList) {
            nonEventMember.map(e => {
                if (e.checked) {
                    const params = {
                        _id: e._id,
                        RSVP: e.RSVP ? e.RSVP : "No",
                        email: e.email,
                        name: e.name,
                        checked: false,
                        status: e.status
                    };
                    tempArr.push(params);
                }
            });
        };

        setEventMember([...eventMember, ...tempArr]);
        setNonEventMember(nonEventMember.filter(m => !m.checked));
        reqBody.users = [...eventMember, ...nonEventMember.filter(m => !m.checked), ...tempArr];
        dispatch(updateEventMembers(reqBody));
    }

    const removeFromEvent = (forcedList) => {
        const reqBody = event;
        let tempArr = forcedList && forcedList.length > 0 ? forcedList : [];

        if (!forcedList) {
            eventMember.map(e => {
                if (e.checked) {
                    const params = {
                        _id: e._id,
                        RSVP: null,
                        email: e.email,
                        name: e.name,
                        checked: false,
                        status: e.status
                    };
                    tempArr.push(params);
                }
            });
        };

        setNonEventMember([...nonEventMember, ...tempArr]);
        setEventMember(eventMember.filter(m => !m.checked));
        reqBody.users = [...eventMember.filter(m => !m.checked), ...nonEventMember, ...tempArr];
        dispatch(updateEventMembers(reqBody));
    }

    const getGroupName = (userId) => {
        const result = groups.find(g => g.members.includes(userId));
        if (result) {
            return result.name
        } else {
            return "No Group"
        }
    };

    const getUserValues = (userId) => {
        const user = users?.find(u => u._id === userId);
        if (user) {
            return user
        } else {
            return null
        }
    };

    const addNewActiveMembers = () => {
        const newUsers = getNewMembers(event.users, users);
        const activeNewUsers = newUsers.filter(u => u.status.toLowerCase() == "active");
        removeFromEvent(activeNewUsers);
    };
    const addNewNonActiveMembers = () => {
        const newUsers = getNewMembers(event.users, users);
        const nonActiveNewUsers = newUsers.filter(u => u.status.toLowerCase() !== "active");
        removeFromEvent(nonActiveNewUsers);
    };


    return (
        <div>
            <Modal isOpen={memberModal} toggle={openMemberList} className="modal-lg">
                <ModalHeader toggle={openMemberList}>Manage Members</ModalHeader>
                <div className='member-management-container'>
                    {eventLoading && <div className='member-management-overlay-loader'>
                        <Spinner color="primary" />
                    </div>}
                    <ModalBody>
                        <Form>
                            <div className="row">
                                <div className="col-md-6">
                                    <a href='#' className='aTag' onClick={() => selectAllNonEventMember(true)}>Select All</a> &emsp;
                                    <a href='#' className='aTag' onClick={() => selectAllNonEventMember(false)}>Unselect All</a>
                                    <a href='#' className='aTag' style={{ float: 'right' }} onClick={() => addToEvent()} >Add to event</a>
                                    <div className='titleFlex'>
                                        <p>Non participants</p>
                                    </div>
                                    <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                                        {nonEventMember && React.Children.toArray(nonEventMember.map(user => {
                                            const userValues = getUserValues(user._id)
                                            const status = userValues?.status ? userValues.status : user?.status
                                            return {
                                                ...user,
                                                status,
                                                userValues
                                            }
                                        }).sort((a, b) => a?.userValues?.personalDetails?.name > b?.userValues?.personalDetails?.name ? 1 : -1).map(user => {
                                            return <Fragment key={user._id}><FormGroup check inline>
                                                <div
                                                    onClick={() => updateSelectedStatus(user._id, 'non member')}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <FontAwesomeIcon icon={user.checked ? faCheckSquare : faSquare} color='rgba(0,0,0,.5)' style={{ marginRight: "0.25rem" }} />
                                                    <span style={{ color: user?.status?.toLowerCase() == "active" ? 'green' : "red" }}>
                                                        {user?.userValues?.personalDetails?.name ? user?.userValues?.personalDetails?.name : user.name} - {getGroupName(user._id)}{" "}
                                                        {user?.status == undefined ? "" : `(${user?.status})`}
                                                    </span>
                                                </div>
                                                {/* <Label check >
                                                    <Input
                                                        type="checkbox"
                                                        checked={user.checked}
                                                        onChange={() => updateSelectedStatus(user._id, 'non member')}
                                                    />
                                                </Label> */}

                                            </FormGroup><br></br></Fragment>
                                        }))}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div style={{ height: '100%', borderLeft: '2px solid #d7d7d7', float: 'left', paddingRight: 15 }}></div>
                                    <a href='#' className='aTag' onClick={() => selectAllEventMember(true)}>Select All</a> &emsp;
                                    <a href='#' className='aTag' onClick={() => selectAllEventMember(false)}>Unselect All</a>
                                    <a href='#' className='aTag' style={{ float: 'right' }} onClick={() => removeFromEvent()} >Remove from event</a>
                                    <div className='titleFlex'>
                                        <p>Participants</p>
                                    </div>
                                    <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                                        {eventMember && React.Children.toArray(eventMember.map(user => {
                                            const userValues = getUserValues(user._id)
                                            const status = userValues?.status ? userValues.status : user?.status
                                            return {
                                                ...user,
                                                status,
                                                userValues
                                            }
                                        }).sort((a, b) => a?.userValues?.personalDetails?.name > b?.userValues?.personalDetails?.name ? 1 : -1).map(user => {
                                            return <Fragment key={user._id}><FormGroup check inline>
                                                <div
                                                    onClick={() => updateSelectedStatus(user._id, null)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <FontAwesomeIcon icon={user.checked ? faCheckSquare : faSquare} color='rgba(0,0,0,.5)' style={{ marginRight: "0.25rem", marginBottom: 'auto' }} />
                                                    <span style={{ color: user?.status?.toLowerCase() == "active" ? 'green' : "red" }}>
                                                        {user?.userValues?.personalDetails?.name ? user?.userValues?.personalDetails?.name : user.name} - {getGroupName(user._id)}{" "}
                                                        {user?.status == undefined ? "" : `(${user?.status})`}
                                                    </span>
                                                </div>
                                            </FormGroup><br></br></Fragment>
                                        }))}
                                    </div>
                                </div>
                            </div>
                        </Form>

                    </ModalBody>

                </div>
                <ModalFooter>
                    <Button color="danger" onClick={() => addNewNonActiveMembers()} disabled={eventLoading}>Add latest Non participants</Button>
                    <Button color="success" onClick={() => addNewActiveMembers()} disabled={eventLoading}>Add latest Participants</Button>
                    <Button color="secondary" onClick={openMemberList}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default MemberManagement;