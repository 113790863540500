import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { getPastEventsAsAdmin } from '../../redux/actions/eventActions'
import { getUserList } from '../../redux/actions/userActions'

import profilePlaceholder from '../../Assets/placeholder.png';
import { baseURL } from '../../redux/helpers';
import axios from 'axios'

import Leaderboard from './Leaderboard';
import EventWinners from './EventWinners';
import EditPoints from './EditPoints';
import HoleInOne from './HoleInOne';
import './Awards.css'

function Awards(props) {
    const dispatch = useDispatch();
    const { users } = useSelector(state => state.userReducer);
    const { user } = useSelector(state => state.authReducer);
    const token = useSelector(state => state.authReducer.token);
    const { events } = useSelector(state => state.eventReducer);

    const isAdmin = user && user.user?.permissionLevel == 'Admin';

    useEffect(() => {
        dispatch(getPastEventsAsAdmin())
        dispatch(getUserList())
    }, []);

    const [tab, setTab] = useState(1);
    const [usersWithPhoto, setUsersWithPhoto] = useState();

    useEffect(async () => {
        if (users?.length) {
            setUsersWithPhoto(users)

            // Headers
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            //if there is a token then add it to the header
            if (token) {
                config.headers['x-auth-token'] = token;
            }
            const usersWithPhoto = []
            for (var i = 0; i < users.length; i++) {
                if (users[i].photo) {
                    const thePhotoType = users[i].photo.substring(0, 4);
                    if (thePhotoType == 'http' || thePhotoType == 'data') {
                        const originalPhoto = users[i].photo;
                        usersWithPhoto.push({ ...users[i], photoSRC: originalPhoto });
                    } else {
                        const { data } = await axios.post(`${baseURL}/users/getPhoto`, { photo: users[i].photo, _id: users[i]._id }, config)
                        let buf = Buffer.from(data.Body);
                        const fileType = '.blob'
                        let base64 = buf.toString('base64');
                        const photoSRC = `data:image/${fileType};base64,${base64}`
                        usersWithPhoto.push({ ...users[i], photoSRC })
                    }
                } else {
                    const photoSRC = profilePlaceholder
                    usersWithPhoto.push({ ...users[i], photoSRC })
                }
            }
            setUsersWithPhoto(usersWithPhoto)
        }
    }, [users])

    const tab1Classes = `tabButton ${tab == 1 ? 'activeTabButton' : 'inActiveTabButton'}`
    const tab2Classes = `tabMiddleButton ${tab == 2 ? 'activeTabButton' : 'inActiveTabButton'}`
    const tab3Classes = `tabRightButton ${tab == 3 ? 'activeTabButton' : 'inActiveTabButton'}`

    if (props.match.params.id) {
        return <EditPoints eventId={props.match.params.id} />
    }

    return (
        <Fragment>
            <div className='awardsFullContainer'>
                <div>
                    <div className='tabsButtonsContainer'>
                        <div className={tab1Classes} onClick={() => setTab(1)}>Leaderboard</div>
                        <div className={tab2Classes} onClick={() => setTab(2)}>Event Winners</div>
                        <div className={tab3Classes} onClick={() => setTab(3)}>Hole In One</div>
                    </div>
                    {tab == 1 && <Leaderboard users={usersWithPhoto}  />}
                    {tab == 2 && <EventWinners pastEvents={events} users={usersWithPhoto} />}
                    {tab == 3 && <HoleInOne users={usersWithPhoto} isAdmin={isAdmin} />}
                </div>
            </div>
        </Fragment>
    );
}

export default Awards;