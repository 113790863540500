import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import '../Profile.css';
import countryList from '../../Profile/countryList.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFax, faIndustry, faLink, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

import './VisitorView.css';
import { getScoresById } from '../../../redux/actions/scoreActions';
import goldAward from '../../../Assets/goldAward.svg';
import silverAward from '../../../Assets/silverAward.svg';
import bronzeAward from '../../../Assets/bronzeAward.svg';
import yearlyJacket from '../../../Assets/yearlyJacket.svg';
import yearlyLychee from '../../../Assets/yearlyLychee.svg';
import holeInOneAward from '../../../Assets/holeInOneAward.svg';
import profilePlaceholder from '../../../Assets/placeholder.png';

const VisitorView = (props) => {
    const { userDetails, imageSrc, setImageSrc, isVisitor, companyLogo, setCompanyLogo, isAdmin, onChange } = props
    const dispatch = useDispatch();
    const { scores } = useSelector(state => state.scoreReducer);
    const { user } = useSelector(state => state.userReducer);
    const [flag, setFlag] = useState();
    const [awards, setAwards] = useState({first: 0, second: 0, third: 0, jacket: 0, lychee: 0});

    useEffect(() => {
        if (user) {
            dispatch(getScoresById({user_id: user._id}));
        };
    }, [user]);
    
    useEffect(() => {
        setAwards({
            first: scores.filter(function(item){return item.award_type == 'monthly' && item.value == 1}).length,
            second: scores.filter(function(item){return item.award_type == 'monthly' && item.value == 2}).length,
            third: scores.filter(function(item){return item.award_type == 'monthly' && item.value == 3}).length,
            jacket: scores.filter(function(item){return item.award_type == 'yearly' && item.value == 1}).length,
            lychee: scores.filter(function(item){return item.award_type == 'yearly' && item.value == 2}).length,
            holeInOne: scores.filter(function (item) { return item.award_type == 'holeInOne' }).length
        })
    }, [scores]);

    useEffect(() => {
        var userNationality = userDetails && userDetails.personalDetails && userDetails.personalDetails.nationality? userDetails.personalDetails.nationality: null
        var nationality = countryList.find(country => country.nationality === userNationality)
        if (nationality) {
            setFlag(`https://purecatamphetamine.github.io/country-flag-icons/3x2/${nationality.alpha_2_code}.svg`);
        }
    }, [userDetails]);

    return user && <div className='visitorViewMain'>
        <div className='reviewDetail'>
                <div className="profileImg">
                    <img className='image' src={imageSrc??profilePlaceholder} />
                        {flag ? <img src={flag} style= {{ width: '50px', height: '50px', marginTop: '110px', marginLeft:'-50px'}} /> : null }
                </div>
                <div>
                    <div className='reviewDetailName'>
                            {user.personalDetails.title} {user.personalDetails.name}
                        </div>
                        <div style={{ fontSize: '16px' }}>
                            {user.companyDetails.jobTitle}
                    </div>
                    {user.contactNumber && <div style={{ paddingTop: '25px' }}>
                            <FontAwesomeIcon className="icons profileIcons" icon={faPhone} style={{ fontSize: '16px' }} /> &nbsp;
                        <a href={`callto:${user.contactNumber}`} style={{ textDecoration: 'none', color: 'inherit', fontSize: '16px', marginRight: '0.5rem' }}>{user.contactNumber} </a>
                        </div>}

                        {user.email && <div style={{ marginBottom: '1rem' }}>
                            <FontAwesomeIcon className="icons profileIcons" icon={faEnvelope} style={{ fontSize: '16px' }} /> &nbsp; 
                        <a href={`mailto:${user.email}`} style={{ textDecoration: 'none', color: 'inherit', fontSize: '16px', marginRight: '0.5rem' }}>{user.email}</a>
                        </div>}
                        {user.socialMedia && <div className='social'>
                            {user.socialMedia.linkedIn && <div style={{marginRight:'10px'}}>
                                <a
                                    href={`${user.socialMedia.linkedIn}`}
                                    target='_blank'
                                    style={{ textDecoration: 'none', color: 'inherit', fontSize: '16px', marginRight: '0.5rem' }}>
                                    <FontAwesomeIcon className="icons profileIcons" icon={faLinkedin} style={{ fontSize: '16px' }} /> &nbsp;LinkedIn
                                </a>
                            </div>}
                            {user.socialMedia.facebook && <div style={{marginRight:'10px'}}>
                                <a
                                    href={`${user.socialMedia.facebook}`}
                                    target='_blank'
                                    style={{ textDecoration: 'none', color: 'inherit', fontSize: '16px' }}>
                                    <FontAwesomeIcon className="icons profileIcons" icon={faFacebook} style={{ fontSize: '16px' }} /> &nbsp;Facebook
                                </a>
                            </div>}
                            {user.socialMedia.twitter && <div style={{marginRight:'10px'}}>
                                <a
                                    href={`${user.socialMedia.twitter}`}
                                    target='_blank'
                                    style={{ textDecoration: 'none', color: 'inherit', fontSize: '16px', marginRight: '0.5rem' }}>
                                    <FontAwesomeIcon className="icons profileIcons" icon={faTwitter} style={{ fontSize: '16px' }} /> &nbsp;Twitter
                                </a>
                            </div>}

                            {user.socialMedia.instagram && <div style={{marginRight:'10px'}}>
                                <a
                                    href={`${user.socialMedia.instagram}`}
                                    target='_blank'
                                    style={{ textDecoration: 'none', color: 'inherit', fontSize: '16px', marginRight: '0.5rem' }}>
                                    <FontAwesomeIcon className="icons profileIcons" icon={faInstagram} style={{ fontSize: '16px' }} />&nbsp;Instagram
                                </a>
                            </div>}
                        </div>}
                 <hr className='firstDivider'/>          
                </div>
        </div>
        {
            <div className='reviewDetailCompany'>
            {companyLogo &&  <div className='company-photo'>
                <img className='company-photo-img' src={companyLogo} />
            </div>}
           
            <div>
                <div style={{ fontWeight: 'bolder', fontSize: '24px' }}>
                    {user.companyDetails.name}
                </div>
                <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                    {user.companyDetails.industry}
                </div>
                <div style={{ fontSize: '16px' }}>
                    {user.companyDetails.description}
                </div>
                <Row style={{ marginTop: '1rem' }}>
                            {user.companyDetails.phone && <Col sm='12' md='6'>
                                <FontAwesomeIcon className="icons profileIcons" icon={faIndustry} style={{ fontSize: '16px' }} /> &nbsp;
                            <a href ={`callto:${user.companyDetails.phone}`} style={{ textDecoration: 'none', color: 'inherit', fontSize: '16px', marginRight: '0.5rem' }}>{user.companyDetails.phone} </a>
                            </Col>}
                            {user.companyDetails.website && <Col sm='12' md='6'>
                                <FontAwesomeIcon className="icons profileIcons" icon={faLink} style={{ fontSize: '16px' }} /> &nbsp;
                                <a 
                                    href={user.companyDetails.website.includes('http') ? user.companyDetails.website : `https://${user.companyDetails.website}`}
                                    target = '_blank'
                                    style={{ textDecoration: 'none', color: 'inherit', fontSize: '16px', marginRight: '0.5rem' }}> 
                                    {user.companyDetails.website} 
                                </a>         
                            </Col>}
                            {user.companyDetails.directLine && <Col sm='12' md='6'>
                                <FontAwesomeIcon className="icons profileIcons" icon={faPhone} style={{ fontSize: '16px' }} /> &nbsp;
                            {user.companyDetails.directLine}
                            </Col>}
                            {user.companyDetails.fax && <Col sm='12' md='6'>
                                <FontAwesomeIcon className="icons profileIcons" icon={faFax} style={{ fontSize: '16px' }} /> &nbsp;
                            {user.companyDetails.fax}
                            </Col>}
                        </Row>
                        <div style={{ fontSize: '16px', marginTop: '1rem' }}>
                            {user.personalDetails.line1 && <Fragment>{user.personalDetails.line1}<br /></Fragment>}
                            {user.personalDetails.line2 && <Fragment>{user.personalDetails.line2}<br /></Fragment>}
                            {user.personalDetails.postcode} {user.personalDetails.city} <br />
                            {user.personalDetails.country}
                        </div>
            </div>
                    
        </div>
        }
      
        <hr style={{margin:'40px 0px 20px 0px'}}/>
        <div>
            <div>
                <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                            Awards
                        </div>
                        <div style={{ fontSize: '16px' }}>
                            Monthly Events Award
                        </div>
            </div>
            <div className='award'>
                <div className='singleAward'>
                            <img src={goldAward}></img>
                            <span>{awards.first}x</span>
                        </div>
                        <div className='singleAward'>
                            <img src={silverAward}></img>
                            <span>{awards.second}x</span>
                        </div>
                        <div className='singleAward'>
                            <img src={bronzeAward}></img>
                            <span>{awards.third}x</span>
                        </div>
            </div>

        </div>
        <hr style={{margin:'40px 0px'}}/>
        <div className='award'>
            <div className='singleAward'>
                            <p>Yearly Green Jacket Award</p>
                            <img src={yearlyJacket}></img>
                            <span>{awards.jacket}x</span>
                        </div>
                        <div className='singleAward'>
                            <p>Yearly Lychee Cup Award</p>
                            <img src={yearlyLychee}></img>
                            <span>{awards.lychee}x</span>
                        </div>
                        <div className='singleAward'>
                            <p>Hole in One Award</p>
                            <img src={holeInOneAward}></img>
                            <span>{awards.holeInOne}x</span>
                        </div>
        </div>    
    </div>

};

export default VisitorView;