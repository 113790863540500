import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Input } from 'reactstrap';
import { adminCategories, userCategories } from './postsCategories';
import axios from 'axios';
import { baseURL } from '../../redux/helpers';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './posts.css';

const EditPostsModal = ({ isOpen, setIsOpen, post, setPost, onSubmit, isAdmin }) => {
    if (!post) { return null };

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rawMessage, setRawMessage] = useState('');
    const onEditorStateChange = (editorState2) => {
        setEditorState(editorState2);
        const convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const convertedDataWithLines = convertedData.replaceAll('<p></p>', '<p>&nbsp;</p>')
        setRawMessage(convertedDataWithLines);
        setPost({ ...post, content: convertedDataWithLines });
    };

    useEffect(() => {
        if (post.content) {
            const blocksFromHtml = htmlToDraft(post.content);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorStateFromHTML = EditorState.createWithContent(contentState);
            setEditorState(editorStateFromHTML);
        };
    }, [post.title]);

    function onChange(e) {
        const { name, value, files } = e.target;
        if (files) {
            const file = files[0];
            const data = new FormData();
            data.append('name', file.name);
            data.append('image', file);
            axios.post(`${baseURL}/posts/uploadfile`, data)
                .then(response => {
                    const imagefile = { name: file.name, location: response.data };
                    setPost({ ...post, ['image']: imagefile });
                })
                .catch(err => {
                    console.error(err);
                })
        } else {
            setPost({ ...post, [name]: value });
        }
    }
    const correctCategories = isAdmin ? adminCategories : userCategories;

    const wrapperStyle = {
        border: '1px solid #969696',
    };
    const editorStyle = {
        height: '10rem',
        padding: '1rem'
    };

    return (
        <div>
            <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <ModalHeader toggle={() => setIsOpen(!isOpen)}>
                    {post.userId ? 'Update post' : 'Add a new post'}
                </ModalHeader>
                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        <div>
                            <Label className='generalPostInput'>Title*
                                <Input
                                    type={'text'}
                                    value={post.title}
                                    name={'title'}
                                    id={'title'}
                                    placeholder={'Post title'}
                                    required={true}
                                    onChange={(e) => onChange(e)}
                                />
                            </Label>

                            <Label className='generalPostInput'>Content*
                                <div className='contentEditorContainer'>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        editorState={editorState}
                                        wrapperClassName="wrapper-class"
                                        wrapperStyle={wrapperStyle}
                                        editorStyle={editorStyle}
                                        editorClassName="demo-editor"
                                        onEditorStateChange={onEditorStateChange}
                                        toolbar={{
                                            inline: {
                                                options: ['italic', 'bold'],
                                                bold: { className: 'demo-option-custom' },
                                                italic: { className: 'demo-option-custom' },
                                                underline: { className: 'demo-option-custom' },
                                                strikethrough: { className: 'demo-option-custom' },
                                                monospace: { className: 'demo-option-custom' },
                                                superscript: { className: 'demo-option-custom' },
                                                subscript: { className: 'demo-option-custom' }
                                            },
                                            blockType: {
                                                className: 'demo-option-custom-wide',
                                                dropdownClassName: 'demo-dropdown-custom'
                                            },
                                            fontSize: { className: 'demo-option-custom-medium' }
                                        }}
                                    />
                                </div>
                            </Label>

                            <Label className='generalPostInput'>Category*
                                <Input
                                    type={'select'}
                                    value={post.category}
                                    name={'category'}
                                    id={'category'}
                                    required={true}
                                    onChange={(e) => onChange(e)}
                                >
                                    <option value=''>Select</option>
                                    {correctCategories.map(item => {
                                        return <option key={item} value={item}>{item}</option>
                                    })}
                                </Input>
                            </Label>

                            <Label className='generalPostInput'>Image (optional)
                                {post.image && post.image.name ?
                                    <p>
                                        {post.image.name}
                                        <span style={{ marginLeft: '15px', cursor: 'pointer' }} className='text-red' onClick={() => { setPost({ ...post, ['image']: null }) }}>x</span>
                                    </p> :
                                    <Input
                                        type={'file'}
                                        accept={"image/*"}
                                        value={null}
                                        name={'image'}
                                        id={'image'}
                                        required={false}
                                        onChange={(e) => onChange(e)}
                                    />}
                            </Label>

                            <Label className='generalPostInput'>External Link (optional)
                                <Input
                                    type={'url'}
                                    value={post.externalLink}
                                    name={'externalLink'}
                                    id={'externalLink'}
                                    placeholder={'Additional link'}
                                    required={false}
                                    onChange={(e) => onChange(e)}
                                />
                            </Label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ width: '100%' }} color="primary" type='submit'>
                            Save post
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div >
    );
}

export default EditPostsModal;