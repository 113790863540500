import React from 'react'

import PropTypes from 'prop-types'

import './row.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons'

const Row = (props) => {
  return (
    <div className={`content ${props.index % 2 == 1 ? 'alt-row-container' : 'row-container'}`}>
      <div className="contentBold row-container01">
        <span className="row-text">{props.text}</span>
        <span className="row-text01">{props.text1}</span>
        <span className="row-text02">{props.text2}</span>
        <span className="row-text03">{props.text3}</span>
      </div>
      <div className="contentBold row-container02">
        <span className="row-text04">{`F-${props.index < 9 ? `0${props.index + 1}` : props.index + 1}`}</span>
      </div>
      <div className="contentBold row-container03">
        <span className="row-text05"></span>
        <span className="row-text06"></span>
      </div>
      <div className="row-container04" >
        {props.flight.map(e => <span key={e.personalDetails.name} style={{ textAlign: 'left', width: '100%', paddingLeft: '4px' }}>{e.personalDetails.name}</span>)}
      </div>
      {/* <div className="row-container05">
        {props.flight.map(e => <span>{e.member_id}</span>)}
      </div> */}
      <div className="row-container06">
        {props.flight.map(e => <span key={e._id}>{(new Date(e.personalDetails.birthdate).getMonth() + 1) == props.eventMonth ? <span><FontAwesomeIcon icon={faBirthdayCake} /></span> : <span>&nbsp;</span>}</span>)}
      </div>
      <div className="row-container06">
        {props.flight.map(e => <span key={e.personalDetails.handicap}>{e.personalDetails.handicap}</span>)}
      </div>
      {/* <div className="row-container07"></div> */}
      <div className="row-container08">
        <div className="row-container09"></div>
        <div className="row-container10"></div>
      </div>
    </div>
  )
}

Row.defaultProps = {
  text6: '13:00 PM',
  text9: 'Cooper Kenter',
  text10: 'Ruben Workman',
  text5: 'HOLE 1',
  text16: '40',
  text: '1',
  text11: 'MY-A9140 IN',
  text8: 'Jaylon Dias',
  text12: 'MY-M9133 IN',
  text7: 'Gustavo Schleifer',
  text4: 'F-01',
  text3: '4',
  text1: '2',
  text18: '40',
  text13: 'MY-A9128 IN\t',
  text17: '40',
  text14: 'MY-O9115 IN',
  text2: '3',
  text15: '40',
}

Row.propTypes = {
  text6: PropTypes.string,
  text9: PropTypes.string,
  text10: PropTypes.string,
  text5: PropTypes.string,
  text16: PropTypes.string,
  text: PropTypes.string,
  text11: PropTypes.string,
  text8: PropTypes.string,
  text12: PropTypes.string,
  text7: PropTypes.string,
  text4: PropTypes.string,
  text3: PropTypes.string,
  text1: PropTypes.string,
  text18: PropTypes.string,
  text13: PropTypes.string,
  text17: PropTypes.string,
  text14: PropTypes.string,
  text2: PropTypes.string,
  text15: PropTypes.string,
}

export default Row
