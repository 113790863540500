import React, { useEffect, Fragment, useState } from 'react';
import { loadUser } from '../../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import NavBar from '../NavBar/Navbar';
import Landing from '../../pages/Landing/Landing';
import { emailInvoice, updateInvoice, uploadDrive, addInvoice, noRenewalEmail } from '../../redux/actions/invoiceActions';
import { updateUser } from '../../redux/actions/userActions';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { loadTokenTab} from '../../redux/actions/tabActions';

const requireAuth = (settings) => {
	const { AuthComponent, requireAuth, admin, hidden, flightArranger, renewalAccess } = settings;

	const Authenticate = (props) => {
		const dispatch = useDispatch();
		const auth = useSelector((state) => state.authReducer);
		const { invoices } = useSelector(state => state.invoiceReducer)
		const { isAuthenticated, isLoading, user } = auth;
		const permissionLevel = user?.user.permissionLevel;
		const isAdmin = permissionLevel == 'Admin';
		const userStatus = user?.user.status;
		const isRenewal = userStatus?.toLowerCase() == "renewal";
		const isExpired = userStatus?.toLowerCase() == "expired";
		const [showReminder, setShowReminder] = useState(false);
		const [date, setDate] = useState('')
		const [secondModal, setSecondModal] = useState(false)
		const [showMenu, setShowMenu] = useState(false);

		useEffect(() => {
			dispatch(loadUser());
			dispatch(loadTokenTab());
		}, []);

		useEffect(() => {
			if (
				user &&
				user.user &&
				user.user.availableTokens < 4 &&
				!isAdmin &&
				!isReminderIgnored(user.user.lowTokensReminder)
			) {
				setShowReminder(true);
			}
			return () => {
				setShowReminder(false); // Reset the showReminder state when unmounted
			};
		}, [user]);


		const handleIgnoreClick = (action) => {
			const reminderDate = new Date(new Date().setHours(new Date().getHours() + 12));
			const ignoreValue = action ? "full ignore" : reminderDate;

			dispatch(updateUser({
				_id: user?.user?._id,
				lowTokensReminder: ignoreValue,
			}));
			const requestBody = {
				email: user?.user?.email,
				name: user?.user?.personalDetails?.name,
				_id: user?.user?._id
			};
			dispatch(noRenewalEmail(requestBody))

			setShowReminder(false);
		};

		const handleReminder = (action) => {
			const reminderDate = new Date(new Date().setHours(new Date().getHours() + 12));
			const ignoreValue = action ? "full ignore" : reminderDate;

			dispatch(updateUser({
				_id: user?.user?._id,
				lowTokensReminder: ignoreValue,
			}));
			const requestBody = {
				email: user?.user?.email,
				name: user?.user?.personalDetails?.name,
				_id: user?.user?._id
			};
			setShowReminder(false);
		};


		const yesButtonClicked = (action) => {
			const reminderDate = new Date(new Date().setHours(new Date().getHours() + 168));
			const ignoreValue = action ? "full ignore" : reminderDate;

			dispatch(updateUser({
				_id: user?.user?._id,
				lowTokensReminder: ignoreValue,
			}));
			setShowReminder(false);
		};

		const isReminderIgnored = (value) => {
			if (!value) {
				return false;
			} else if (value == "full ignore") {
				return true;
			} else {
				let ignoreDate = new Date(value);
				if (ignoreDate > new Date()) {
					return true;
				} else {
					return false;
				};
			};
		};


		const infoModal = () => {
			setSecondModal(!secondModal)
		}


		const formatDate = (date) => {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			return `${year}/${month}/${day}`;
		}
		const fee = user?.user?.membershipDetails?.yearlyFee;

		const currentDate = new Date();
		const formattedDate = formatDate(currentDate);

		const invoiceNo = `${formattedDate}-${user?.user?.member_id}`


		const handleInvoiceSubmission = () => {
			const requestBody = {
				email: user?.user?.email,
				name: user?.user?.personalDetails?.name,
				invoiceNo: invoiceNo,
			};
			dispatch(emailInvoice(requestBody));
			infoModal()
		};

		const createInvoice = () => {
			const invoiceDate = formattedDate;
			const fee = user?.user?.membershipDetails?.yearlyFee;
			const invoiceNo = `${invoiceDate}-${user?.user?.member_id}`
			dispatch(addInvoice({
				user_id: user?.user?._id,
				date: invoiceDate,
				invoiceNo,
				fee,
				sst: true,
				sstPercentage:0.08,
			}))
		}

		if (isRenewal && !renewalAccess && !isAdmin) {
			return (
				<Fragment>
					<NavBar showMenu={showMenu} setShowMenu={setShowMenu}/>
					<br />
					<div
						style={{ textAlign: 'center', marginTop: '20px' }}
					>
						<h1>Your account status is renewal.</h1>
						<div>You can only manage your profile.</div>
						<div>Please renew your membership to get full access.</div>
					</div>
				</Fragment>
			);
		};

		if (isExpired && !isAdmin) {
			return (
				<Fragment>
					<NavBar hidden={hidden} showMenu={showMenu} setShowMenu={setShowMenu}/>
					<br />
					<div
						style={{ textAlign: 'center', marginTop: '20px' }}
					>
						<h1>Your account has expired.</h1>
						<div>Please renew your membership to access this site.</div>
					</div>
				</Fragment>
			);
		};

		if (admin) {
			if (flightArranger) {
				if (user && !isAdmin && permissionLevel !== 'Flight Arranger') {
					return <Redirect to='/profile'></Redirect>;
				}
			} else {
				if (user && !isAdmin) {
					return <Redirect to='/posts'></Redirect>;
				}
			}
		}

		return (
			<Fragment>
				<NavBar hidden={hidden} showMenu={showMenu} setShowMenu={setShowMenu}/>
				{!showMenu &&
				<div>
				<Modal
					isOpen={showReminder}
					toggle={() => setShowReminder(!showReminder)}
				>
					<ModalHeader
						// toggle={() => setShowReminder(!showReminder)}
						toggle={() => handleReminder()}
					>
						Reminder
					</ModalHeader>
					<ModalBody>
						<div
						>
							<p>Hi {user?.user?.personalDetails?.name},<br></br> Please note that your membership will expire in another 2 months time.</p>
							<p>It is normal for members to renew their membership within 3 months before expiry or earlier, to continue preserve their industry category. If renewal payment fees is made later than 3 months before expiry, we may admit additional similar industry category to join.</p>
							<p>Would you like to renew? Your annual renewable fees is RM{fee}.
								If you would like to renew, please choose the YES button and an invoice will be sent to you via this email address.
							</p>
							<p>If you do not wish to renew, please choose NO.</p>
							{/* You are low on tokens, you only have {user?.user?.availableTokens} left! Would you like to add tokens? */}
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color='outline-primary' type='submit' onClick={() => {
							createInvoice()
							handleInvoiceSubmission()
							yesButtonClicked()
							setShowReminder(!showReminder)
						}}>Yes</Button>
						<Button className="btn btn-danger text-white" type='submit' onClick={() => handleIgnoreClick('full')}>No</Button>
						<Button onClick={() => handleReminder()}>Remind me later</Button>
					</ModalFooter>
				</Modal>
				<Modal toggle={() => infoModal(!secondModal)} isOpen={secondModal}>
					<ModalHeader toggle={() => infoModal(!secondModal)}>Invoice Created</ModalHeader>
					<ModalBody>

						Your invoice has been successfully created. You will receive an email shortly with further details regarding your invoice.
					</ModalBody>
					<ModalFooter></ModalFooter>
				</Modal>

				{isAuthenticated || !requireAuth ? (
					<AuthComponent {...props} />
				) : (
					!isLoading && <Landing />
				)}
				</div>
			}	
			</Fragment>
		);
	};
	return Authenticate;
};

export default requireAuth;
