import React from 'react';
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const data = [
  {
    name: 'John',
    age: 28,
    email: 'john@example.com'
  },
  {
    name: 'Jane',
    age: 32,
    email: 'jane@example.com'
  },
  {
    name: 'Bob',
    age: 45,
    email: 'bob@example.com'
  }
];

const Generate = () => {
  const dataSet = [
    {
      columns: [
        { title: "Name", width: { wpx: 200 } },
        { title: "Age", width: { wpx: 100 } },
        { title: "Email", width: { wpx: 250 } },
      ],
      data: data.map(item => [
        { value: item.name, style: {font: {sz: "14"}} },
        { value: item.age  , style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid", fgColor: {rgb: "3461eb"}}}},
        { value: item.email , style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid", fgColor: {rgb: "35bdb4"}}}}
      ])
    }
  ];

  return (
    <div>
      <ExcelFile element={<button>Download CSV</button>} filename="people">
        <ExcelSheet dataSet={dataSet} name="People" />
      </ExcelFile>
    </div>
  );
};

export default Generate;
