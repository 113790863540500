import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEvent, updatePayment } from '../../redux/actions/eventActions'
import { Table, Button, Input} from 'reactstrap'
import './Update.css'

//Import Components


const Update = ({ viewInvoice}) => {
    const dispatch = useDispatch()
    const { users } = useSelector(state => state.userReducer)
    const { event } = useSelector(state => state.eventReducer)
    const { id } = useSelector(state => state.authReducer.user.user)
    const [windowHeight, setWindowHeight] = useState(`${window.innerHeight - 155}px`)
    const [rsvpModal, setRsvpModal] = useState(false)
    const [userList, setUserList] = useState([])

    const [userPaid, setUserPaid] = useState('')
    const [userDetails, setUserDetails] = useState(null)
    const { invoices } = useSelector(state => state.invoiceReducer)

    useEffect(() => {
        dispatch(getEvent('60522baeeb674b39c892a714'))
        // dispatch(getEvent(props.match.params.id))
    }, [])

    //Used to adjust view based on screen size
    useEffect(() => {
        function handleResize() {
            setWindowHeight(`${window.innerHeight - 155}px`)
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (event) {
            let yesList = []
            let noList = []
            let noResponseList = []

            event.users.forEach(user => {
                if (user._id === id) { setUserPaid(user.paid) }
                switch (user.paid) {
                    case 'Paid':
                        yesList.push(user)
                        break
                    case 'Cancelled':
                        noList.push(user)
                        break
                    default:
                        noResponseList.push(user)
                        break
                }
            });

        }
    }, [event])
    

    useEffect(() => {
        if (users.length) {
            var object = {}
            users.forEach(user => {
                object[user._id] = user
            });
        }
        setUserDetails(object)
    }, [users])

    const openRsvpList = (userList = []) => {
        setRsvpModal(!rsvpModal)
        setUserList(userList)
    }
    const updateUserPaid = (value, userID) => {
        const user = event.users.find(user => user._id == userID)
        // user.RSVP = value
        user.status = value
        const requestBody = {
            _id: event._id,
            user
        }
        dispatch(updatePayment(requestBody))
    }

    return (
        <Fragment>
            <div className='event-details-wrapper'>
                <div className='event-details-container'>
                    <ul className='list-unstyled' id='userList' size="sm">
                        <li className='table-view table-header'>
                            <span ></span>
                            <span >Name</span>
                            <span> Company Name </span>
                            <span > Industry </span>
                            <span > Website </span>
                            <span > Job title</span>


                        </li>
                        {event && event.users.filter(e => e.RSVP === "Yes").map(e => {
                            if (!userDetails) return null
                            const user = userDetails[e._id]
                            if (!user) return null
                            var personalDetailsFlag = user.personalDetails ? true : false
                            var companyDetailsFlag = user.companyDetails ? true : false
                            user.membershipDetails && user.membershipDetails.expiryDate && console.log()
                            return <li className="table-item" key={user._id} >
                                <div className="list-photo">
                                    <div className='placeholder' />
                                    {user.photoSRC && <img className='image' style={{ width: '60px', height: '60px' }} src={user.photoSRC} />}
                                </div>
                                <div>
                                    <span className="list-title"> 
                                        {personalDetailsFlag && user.personalDetails.name}
                                    </span>
                                    <span className="show-web" > 
                                        {companyDetailsFlag && user.companyDetails.jobTitle}
                                    </span>
                                    
                                </div>
                                <div>
                                    <span className="list-detail">
                                        {companyDetailsFlag && user.companyDetails.name} 
                                    </span> <br/>
                                    <span>
                                    {companyDetailsFlag && user.companyDetails.industry}
                                    </span> 
                                    
                                </div>
                                <div>
                                    <select
                                        value={e.status}
                                        type='select'
                                        onChange={e => updateUserPaid(e.target.value, user._id)}
                                        className='event-details-paid-input'
                                    >
                                    <option value=''>Pending</option>
                                    <option value='Yes' >&#10003; Paid </option>
                                    <option value='No'>&#10005; Cancelled </option>
                                    </select>
                                </div>
                     

                            </li>

                        })}
                    </ul>

                </div>
            </div>
        </Fragment >
    );
}

export default Update;