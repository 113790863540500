import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Alert, Label, Input, FormGroup } from 'reactstrap';
// import members from './MemberList csv.json'
import { addInvoice } from '../../../../redux/actions/invoiceActions';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const CreateInvoice = ({ invoiceState, toggleInvoiceState, user }) => {
    const dispatch = useDispatch()
    const [date, setDate] = useState('')
    const [invoiceNo, setInvoiceNo] = useState('')
    const [fee, setFee] = useState('')
    const [template, setTemplate] = useState();
    const [error, setError] = useState('');

    const formatDate = (date) => {
        var month = date.getMonth() + 1
        if (month < 10) {
            month = `0${month}`
        }
        return `${date.getFullYear()}-${month}-${date.getDate()}`
    }

    useEffect(() => {
        if (user) {
            var date = new Date()
            var month = date.getMonth() + 1
            if (month < 10) {
                month = `0${month}`
            }
            var day = date.getDate()
            if (day < 10) {
                day = `0${day}`
            }
            var year = `${date.getFullYear()}`
            setDate(formatDate(date))
            setInvoiceNo(`${day}${month}/${year.substr(-2)}/${user.member_id}`)
            setFee('')
        }
    }, [user])

    const handleDropdownSelect = (value) => {
        setTemplate(value);
        setError('');
    }

    const onSubmit = e => {
        e.preventDefault()
        if (!template) {
            setError('Please select an invoice template.');
        } else {
            dispatch(addInvoice({
                user_id: user._id,
                date,
                invoiceNo,
                fee,
                template,
                sst: true,
                sstPercentage: 0.08,
            }))
            toggleInvoiceState();
        }


    }

    if (!user) return null
    return (
        <Fragment>
            <Modal isOpen={invoiceState} toggle={() => toggleInvoiceState()}>
                <ModalHeader toggle={() => toggleInvoiceState()}>Invoices</ModalHeader>
                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label>Invoice Number</Label>
                            <Input
                                type='text'
                                value={invoiceNo}
                                onChange={e => setInvoiceNo(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Date</Label>
                            <Input
                                type='date'
                                value={date}
                                onChange={e => setDate(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Fee</Label>
                            <Input
                                type='number'
                                value={fee}
                                onChange={e => {
                                    setFee(e.target.value);
                                    setError('');
                                }}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <DropdownButton
                                id="dropdown-basic-button"
                                title={template || 'Select an invoice'}
                                onSelect={handleDropdownSelect}
                                variant="secondary"
                            >
                                <Dropdown.Item eventKey="Proforma Invoice">Proforma Invoice</Dropdown.Item>
                                <Dropdown.Item eventKey="Invoice" >Invoice</Dropdown.Item>
                            </DropdownButton>
                        </FormGroup>
                        {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" type='submit'>Create</Button>{' '}
                        <Button color="danger" onClick={() => toggleInvoiceState()}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    );
}

export default CreateInvoice;