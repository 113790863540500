import React, { useEffect, useState } from 'react'
import { Label } from 'reactstrap'
import imageCompression from 'browser-image-compression'
import UploadPNG from '../../Assets/upload.png'

const Compress = props => {

  const compressImage = async (event, useWebWorker) => {
    const file = event.target.files[0]
    console.log(
      'ExifOrientation',
      await imageCompression.getExifOrientation(file)
    )

    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker,
      onProgress: p => onProgress(p, useWebWorker)
    }
    const output = await imageCompression(file, options)
    props.setProfileDisplay(URL.createObjectURL(output))
    props.setTempFile(output)
    props.setUploadButton(true)
  }


  const onProgress = (p) => {
    console.log(p)
  }




  const { isAdmin, id, isVisitor, imageSrc, profileDisplay } = props

  useEffect(() => {
    props.setProfileDisplay(imageSrc)
  }, [imageSrc])
  return (
    <div>
      {<Label for={id} className={profileDisplay ? 'imageContainer' : 'emptyImage'} >
        <img className={profileDisplay && 'image'} src={profileDisplay ?? UploadPNG} />
        {!profileDisplay && <div className='mt-2'>
          Upload Image
        </div>}
      </Label>}


      <input
        id={id}
        type="file"
        accept="image/*"
        onChange={e => compressImage(e, false)}
        disabled={isVisitor ? !isAdmin : false}
        hidden
      />

    </div>
  )
}
export default Compress