export const adminCategories = [
    'New Event',
    'Past Event',
    'New Member',
    'General Info',
];

export const userCategories = [
    'Biz Opportunity',
    'Hiring',
    'Golf Event (Own)',
    'Company Promo',
];

export const allCategories = [
    'New Event',
    'Past Event',
    'New Member',
    'General Info',
    'Biz Opportunity',
    'Hiring',
    'Golf Event (Own)',
    'Company Promo',
];