import { faCalendar, faCheck, faEdit, faExternalLinkAlt, faGolfBall, faLocationArrow, faTimes, faTrashAlt, faTrophy, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import facebook from '../../Assets/FB.svg';
import youtube from '../../Assets/YT.svg';
import { deleteEvent, updateSingleEvent } from '../../redux/actions/eventActions';
import './EventDetails.css';

function EventCard({ isTable, event, openEventModal, setEventModal, setEditModal, setCurrentEvent, openRsvpList, hover, setHover }) {
    const dispatch = useDispatch()


    const user = useSelector(state => state.authReducer.user.user);
    const { id, permissionLevel, status } = user || {};
    const { users } = useSelector(state => state.userReducer)
    const error = useSelector(state => state.errorReducer)

    const [yesList, setYesList] = useState([]);
    const [noList, setNoList] = useState([]);
    const [userRSVP, setUserRSVP] = useState('');
    const [isSelected, setSelected] = useState(false);

    useEffect(() => {
        if (event && event.users && users?.length > 0) {

            let yesList = []
            let noList = []
            let noResponseList = []
            event.users.forEach(user => {
                if (user._id === id) {
                    if(user.RSVP && user.RSVP !==false && user.RSVP!==true){
                        setUserRSVP(user.RSVP.toLowerCase() !== 'pending' ? user.RSVP : 'No')
                    }
                }
                const userValues = users.find(e => e._id === user._id)
                if (userValues?.personalDetails?.name) {
                    user = {
                        ...user,
                        name: userValues.personalDetails?.name
                    }
                }
                if (user.RSVP) {
                    switch (user.RSVP) {
                        case 'Yes':
                            yesList.push(user)
                            break
                        case 'No':
                            noList.push(user)
                            break
                        default:
                            // noResponseList.push(user);
                            noList.push(user)
                            break
                    }
                }
            });

            setNoList(noList.sort((a, b) => (a.name > b.name) ? 1 : -1))
            setYesList(yesList.sort((a, b) => (a.name > b.name) ? 1 : -1))
            // setNoResponseList(noResponseList.sort((a, b) => (a.name > b.name) ? 1 : -1))

        }
    }, [event, users]);

    useEffect(() => {
        if (error && error.id === 'EVENT_FAIL') {
            //remve all Error: with regex
            const clearMessage = error.message.replace(/Error: /g, '')

            // on ok, refrech the page
            if (window.confirm(clearMessage)) {
                window.location.reload()
            }
        }
    }, [error])

    // responsive to get width remove desktop selected for non role as admin
    useEffect(() => {
        function handleResize() {

            if (window.innerWidth < 779 && permissionLevel !== 'Admin') setSelected(false);
        }

        window.addEventListener('resize', handleResize);

    }, []);

    const updateRSVP = e => {
        let _user = event.users.find(usr => usr._id === id)
        if (!_user) {
            if (user) {
                _user = {
                    _id: user?._id || user?.id,
                    email: user.email,
                    name: user.personalDetails?.name,
                    status: user.status,
                    checked: false,
                    group: user.membershipDetails?.group
                }
            }
            else {
                throw new Error('User not found')
            }

        }
        _user = { ..._user, RSVP: e.target.value }
        const requestBody = {
            _id: event._id,
            user: _user,
            fetchTimestamp: event.fetchTimestamp // for validation for concurrent updates by multiple users
        }

        dispatch(updateSingleEvent(requestBody))
    };

    const onEdit = (event) => {
        openEventModal(event)
        setEventModal(true)
    };

    const onDelete = (id) => {
        var confirm = window.confirm('Delete Event?')
        if (confirm) {
            dispatch(deleteEvent(id))
        }
    };

    const eventClosed = event?.status === "Closed";
    if (!event) return <div></div>
    const { name, description, date, location, additionalDetails, locationURL } = event;
    const pastEvent = new Date() > new Date(date) ? true : false;
    const isGolfEvent = event.bocEvent && event.eventType == "Golf Event";
    const allowedToSignup = status.toLowerCase() == 'active' || (!isGolfEvent && status.toLowerCase() == 'excempted');
    const currentDate = new Date()
    const eventDate = new Date(event.date)
    const signUpEnabled = allowedToSignup && event.users.find(user => user._id == id)?.RSVP && (eventDate > currentDate);

    return (
        <Fragment>
            <div className='event-details-container'>
               
                <br></br>

                <div className={classnames(isTable && !isSelected ? 'event-details-header-unselected' : 'event-details-header',
                    { 'boc-event-red': event?.bocEvent }, { 'non-boc-event-blue': !event?.bocEvent })}
                    onClick={(e) => {
                        const selectedEvent = {
                            ...event,
                            eventClosed,
                            users: event.users
                        };
                        setCurrentEvent(selectedEvent)
                        if (window.innerWidth > 779 || permissionLevel === 'Admin') setSelected(!isSelected);

                        else {
                            setEditModal(true)
                        };
                    }}
                >
                    <span className={'event-details-status-modified'}>
                        <span className={classnames('event-status-var',
                            { 'black-status': event?.status === "Closed" },
                            { 'yellow-status': event?.status === "Inactive" },
                            { 'green-status': event?.status === "Active" }
                        )}>{event?.status}</span>
                        {event?.bocEvent && <span className='boc-event-tag'>BOC Event</span>}
                    </span>
                    <span className={'event-social-links'}>
                        {event?.facebookURL && <a href={event?.facebookURL} target='_blank'><img style={{ width: '35px', marginRight: '8px' }} src={facebook} ></img></a>}
                        {event?.youtubeURL && <a href={event?.youtubeURL} target='_blank'><img style={{ width: '35px' }} src={youtube} ></img></a>}
                    </span>
                    <div className='event-details-summary'>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h3 style={{ fontSize: '26px', fontWeight: '700', }}>{name}</h3>
                            <span>
                                <FontAwesomeIcon icon={faLocationArrow} /> &nbsp;
                                <span style={{ whiteSpace: "pre-wrap" }}>
                                    <a style={{ color: 'inherit', fontWeight: '500', fontSize: '18px', textDecoration: 'none' }} target='blank' href={locationURL}>{location}</a>
                                </span>
                            </span>
                            <span>
                                <FontAwesomeIcon icon={faCalendar} /> &nbsp;
                                <span style={{ whiteSpace: "pre-wrap", fontSize: '18px' }}>{date}</span>
                            </span>
                        </div>
                    </div>
                    {isTable && !isSelected ? (
                        <div className="event-details-response">
                            <div>
                                <div>
                                    <FontAwesomeIcon icon={faUser} /> <strong>{yesList.length}</strong> GOING
                                </div>
                                {/* {(!userRSVP || userRSVP === "Pending")
                                    && <div className='event-details-response-pending'>
                                        <FontAwesomeIcon icon={faExclamationCircle} /> <strong>Pending</strong>
                                    </div>} */}

                                {userRSVP === "Yes" &&
                                    <div className='event-details-response-going'>
                                        <FontAwesomeIcon icon={faCheck} /> <strong>Going</strong>
                                    </div>}
                                {/* Prevent previous record as Pending */}
                                {(!userRSVP || userRSVP === "No" || userRSVP?.toLowerCase() === "pending") && <div className='event-details-response-notgoing'>
                                    <FontAwesomeIcon icon={faTimes} /> <strong>Not Going</strong>
                                </div>}

                            </div>
                        </div>
                    ) : (
                        <div className='event-details-rsvp'>
                            {signUpEnabled
                                && event.users.find(user => user._id == id)?.RSVP
                                ?
                                <select
                                    value={userRSVP}
                                    type='select'
                                    onChange={e => updateRSVP(e)}
                                    onClick={e => e.stopPropagation()}
                                    className={classnames('event-details-rsvp-input',
                                        { 'boc-event-red': event?.bocEvent }, { 'non-boc-event-blue': !event?.bocEvent })}
                                    disabled={eventClosed}
                                >
                                    {/* <option value='Pending'>Pending</option> */}
                                    <option value='Yes'>&#10003; Going </option>
                                    <option value='No'>&#10005; Not Going</option>
                                </select>
                                :
                                <div>N/A</div>
                            }
                        </div>
                    )}
                </div>

                {isTable && !isSelected
                    ? <div></div>
                    : <Fragment>
                        <div className='event-details-schedule'>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>Schedule</span><br></br>
                                        <span style={{ whiteSpace: "pre-wrap" }}>{description}</span>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>Additional Details</span><br></br>
                                        <span style={{ whiteSpace: "pre-wrap" }}>{additionalDetails}</span>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <div className="event-details-rsvplist-container">
                            <strong>
                                Event Participants
                            </strong>
                        </div>
                        <div className='event-details-rsvplist-container'>
                            <div
                                className='column-rsvp'
                                onClick={() => openRsvpList(yesList)}
                                onMouseOver={() => setHover(1)}
                                onMouseLeave={() => setHover(null)}
                                style={{ backgroundColor: hover === 1 && '#EEEEEE' }}
                            >
                                <span className='column-rsvp-count'>{yesList.length}</span>
                                <span className='column-rsvp-label'>GOING </span>
                            </div>
                            <div
                                className='column-rsvp'
                                onClick={() => openRsvpList(noList)}
                                onMouseOver={() => setHover(2)}
                                onMouseLeave={() => setHover(null)}
                                style={{ backgroundColor: hover === 2 && '#EEEEEE' }}
                            >
                                <span className='column-rsvp-count'>{noList.length}</span>
                                <span className='column-rsvp-label'>NOT GOING </span>
                            </div>
                            {/* <div
                                className='column-rsvp'
                                onClick={() => openRsvpList(noResponseList)}
                                onMouseOver={() => setHover(3)}
                                onMouseLeave={() => setHover(null)}
                                style={{ backgroundColor: hover === 3 && '#EEEEEE' }}
                            >
                                <span className='column-rsvp-count'>{noResponseList.length}</span>
                                <span className='column-rsvp-label'>PENDING</span>
                            </div> */}
                        </div>
                        <br />
                        {permissionLevel === "Admin"
                            ? (
                                <div className="event-edit-container">
                                    <strong>Admin Actions</strong>
                                    <div className="event-edit">
                                        {openEventModal && setEventModal && <div
                                            className="btn admin-button"
                                            onClick={() => onEdit(event)}
                                        ><FontAwesomeIcon icon={faEdit} /> Quick Edit</div>}
                                        <div
                                            className="btn admin-button"
                                            onClick={() => window.open(`/event/${event._id}`)}
                                        ><FontAwesomeIcon icon={faExternalLinkAlt} /> View Event</div>
                                        {pastEvent && event.bocEvent && event.eventType == 'Golf Event' ? <div
                                            className="btn admin-button"
                                            onClick={() => window.open(`/awards/eventPoints/${event._id}`)}
                                        ><FontAwesomeIcon icon={faTrophy} /> Edit Points</div> :
                                         event.status==='Closed' ?
                                            <div
                                                className="btn admin-button"
                                                onClick={() => window.open(`/flight/${event._id}`)}
                                            ><FontAwesomeIcon icon={faGolfBall} /> Flight Arranger</div>
                                            :null
                                            }
                                        <div
                                            className="btn admin-button-delete"
                                            onClick={() => onDelete(event._id)}
                                        ><FontAwesomeIcon icon={faTrashAlt} /> Delete Event</div>
                                    </div>
                                </div>
                            )
                            : permissionLevel === "Flight Arranger" && (<>
                                {pastEvent && event.bocEvent && event.eventType == 'Golf Event' ? <div
                                    className="btn admin-button"
                                    onClick={() => window.open(`/awards/eventPoints/${event._id}`)}
                                >
                                    <FontAwesomeIcon icon={faTrophy} /> Edit Points
                                </div> : <div
                                    className="btn admin-button"
                                    onClick={() => window.open(`/flight/${event._id}`)}
                                ><FontAwesomeIcon icon={faGolfBall} /> Flight Arranger</div>}
                            </>)
                        }
                    </Fragment>}
            </div>
            <div className="custom-hr"></div>
            <br></br>

        </Fragment>
    );
}

export default EventCard;