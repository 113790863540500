
import React, { useState, Fragment, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';

const ModalExample = ({ emailModal, openInviteList, event, emailInvite, membersUpdated }) => {
    const [emailList, setEmailList] = useState([])

    useEffect(() => {
        if (event && event.users && event.users.length > 0) {
            setEmailList(event.users.map(user => {
                return {
                    ...user,
                    sendMail: false,
                }
            }))
        }
    }, [event, membersUpdated])

    const selectAll = (state) => {
        setEmailList(event.users.map(user => {
            return {
                ...user,
                sendMail: state,
            }
        }))
    }

    const updateEmailStatus = (_id) => {
        setEmailList(emailList.map(user => {
            if (user._id === _id) {
                return {
                    ...user,
                    sendMail: !user.sendMail
                }
            } else {
                return user
            }
        }))
    }

    return (
        <div>
            <Modal isOpen={emailModal} toggle={openInviteList} >
                <ModalHeader toggle={openInviteList}>Email List</ModalHeader>
                <ModalBody>
                    <a href='#' onClick={() => selectAll(true)}>Select All</a>
                    <a href='#' onClick={() => selectAll(false)} style={{ float: 'right' }}>Remove All</a>
                    <br></br>
                    <br></br>
                    <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                        <Form>
                            {React.Children.toArray(emailList.sort((a, b) => a.name > b.name ? 1 : -1).map(user => <Fragment key={user._id}><FormGroup check inline>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={user.sendMail}
                                        onChange={() => updateEmailStatus(user._id)}
                                    />{user.name}
                                </Label>
                            </FormGroup><br></br></Fragment>))}
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { emailInvite(emailList); openInviteList() }}>Send Emails</Button>{' '}
                    <Button color="secondary" onClick={openInviteList}>Cancel</Button>
                </ModalFooter>
            </Modal >
        </div >
    );
}

export default ModalExample;