import { ADD_USER, DELETE_USER, USER_FAIL, GET_USER, GET_USERS, SET_USER_UPDATED, UPDATE_USER, SET_USER, SET_PHOTO, SET_COMPANY_PHOTO, UPDATE_USER_MOBILE, DOWNLOAD_USER_LIST } from '../types'
import axios from 'axios'
import { tokenConfig } from './authActions'
import { returnErrors, clearErrors } from './errorActions'
import { baseURL } from '../helpers'

export const getUsers = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/users`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_USERS,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
        })
    dispatch(clearErrors())
    dispatch({
        type: GET_USERS
    })
}

export const getUserList = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/users/userlist`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_USERS,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
        })
}

export const downloadUserList = () => async (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/users/download/csv`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: DOWNLOAD_USER_LIST,
            })
            const href = URL.createObjectURL(response.data);
            const link = window.document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            window.document.body.appendChild(link);
            link.click();
        
            window.document.body.removeChild(link);
            URL.revokeObjectURL(href);
            return true
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
            return true
        })
}

export const getUser = (id) => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/users/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_USER,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
        })
}

export const addUser = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/users`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: ADD_USER,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
        })
}

export const deleteUser = id => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.delete(`${baseURL}/users/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: DELETE_USER,
                payload: id
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
        })
}

export const updateUser = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.patch(`${baseURL}/users`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_USER,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
        })
}

// for useredit (mobile) - membersModal
export const updateUserDetails = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.patch(`${baseURL}/users`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_USER_MOBILE,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
        })
}


export const uploadFile = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/users/uploadfile`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_USER,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
        })
}

export const uploadCompanyFile = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/users/uploadCompanyPhoto`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_USER,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
        })
}

export const getPhoto = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/users/getPhoto`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: SET_PHOTO,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, USER_FAIL));
            dispatch({
                type: USER_FAIL,
            })
        })
}


export const setUser = user => (dispatch) => {
    dispatch({
        type: SET_USER,
        payload: user
    })
}

export const setUpdated = state => (dispatch) => {
    dispatch({
        type: SET_USER_UPDATED,
        payload: state,
    })
}