import React, { Fragment, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf'


import Row from './row'
import './home.css'
import * as XLSX from 'xlsx';
import ExcelRow from '../../../components/ExcelRow/ExcelRow';

const FlightPDF = (props) => {

  const [modal, setModal] = useState(false);

  let excelData = [];

  //Convert to excel data
  props.flights.map((flight, index) => {
    flight?.map((f) => excelData.push({
      team: index,
      ...f
    }));

  });

  const downloadPDF = () => {
    var body = document.getElementById('pdf');
    var fileName = `FlightDraw.pdf`
    html2canvas(body).then(function (canvas) {
      var image = canvas.toDataURL('image/png');
      let doc
      if (canvas.width > canvas.height) {
        doc = new jsPDF('l', 'mm', [(canvas.width / 4) + 20, (canvas.height / 4) + 20]);
      }
      else {
        doc = new jsPDF('p', 'mm', [(canvas.height / 4) + 20, (canvas.width / 4) + 20]);
      }
      doc.addImage(image, 'PNG', 10, 10, canvas.width / 4, canvas.height / 4, '', 'FAST');
      doc.save(fileName);
    });
  }

  const exportToExcel = () => {
    // Get the table element by its ID
    const table = document.getElementById('excel');
    // Initialize a new workbook
    const wb = XLSX.utils.book_new();
    // Extract data from the table
    const ws = XLSX.utils.table_to_sheet(table);
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // Generate a file and initiate download
    XLSX.writeFile(wb, 'FlightDraw.xlsx');
  };


  const toggle = () => {
    setModal(!modal);
    props.saveFlight();
  }

  return (
    <Fragment>
      <Button onClick={toggle}>Open Table</Button>
      <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: 'fit-content' }}>
        <ModalHeader toggle={() => toggle()}>Flight Draw </ModalHeader>
        <ModalBody style={{ maxWidth: 'fit-content', overflowY: 'scroll' }}>
          <div className="home-container" id='pdf'>
            <div className="home-container01">
              <div className="contentBold home-container02">
                <div className="home-container03">
                  <span>No</span>
                </div>
                <div className="home-container04">
                  <span className="home-text1">Flight</span>
                </div>
                <div className="home-container05">
                  <span>Tee Box</span>
                </div>
                <div className="home-container06">
                  <span>Player</span>
                </div>
                <div className="home-container08">
                  <span>BD</span>
                </div>
                <div className="home-container08">
                  <span>H&apos;cap</span>
                </div>
                <div className="home-container10">
                  <span>Buggy</span>
                </div>
              </div>
              {props.flights.map((flight, i) => {
                return <Row key={'flight-pdf-' + i} index={i} flight={flight} eventMonth={props.eventMonth}></Row>
              })}

            </div>
          </div>

          <div id="excel" style={{ display: 'none' }}>
            <table>
              <thead>
                <tr>
                  <th>Flight</th>
                  <th>Tee Box</th>
                  <th>Player</th>
                  <th>BD</th>
                  <th>H&apos;cap</th>
                  <th>Buggy</th>
                </tr>
              </thead>
              <tbody>
                {excelData.map((flight, index) => {
                  return <ExcelRow key={'flight-excel' + index} index={index} flight={flight} eventMonth={props.eventMonth}></ExcelRow>
                })}


              </tbody>
            </table>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 10px', alignItems: 'center', textAlign: 'center' }}>
            <Button className='deleteButton' onClick={() => downloadPDF()} style={{ display: 'flex', backgroundColor: 'red', marginRight: '20px', alignItems: 'center', textAlign: 'center' }}>
              <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '10px' }} /> Export as PDF
            </Button>
            <Button style={{ backgroundColor: 'green' }} onClick={exportToExcel}>
              <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '10px' }} /> Export as Excel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default FlightPDF
