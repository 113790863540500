import { ADD_EVENT, DELETE_EVENT, EVENT_FAIL, GET_EVENT, GET_EVENTS, SET_EVENT_UPDATED, UPDATE_EVENT, SET_EVENT, SEND_EMAIL, UPDATE_RSVP, UPDATE_PAYMENT, UPDATE_EVENT_MEMBERS, GET_EVENTS_HISTORY, UPDATE_EVENT_MEMBERS_LOADING } from '../types'
import axios from 'axios'
import { tokenConfig } from './authActions'
import { returnErrors, clearErrors } from './errorActions'
import { baseURL } from '../helpers'

export const getEvents = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/events`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_EVENTS,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const getPastEvents = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/events/history`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_EVENTS_HISTORY,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const getPastEventsAsAdmin = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/events/history/asAdmin`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_EVENTS_HISTORY,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const getEventList = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/events/eventlist`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_EVENTS,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const sendEventInvite = (reqBody) => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/events/email`, reqBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: SEND_EMAIL,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const getEvent = (id) => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/events/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_EVENT,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const addEvent = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/events`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: ADD_EVENT,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const deleteEvent = id => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.delete(`${baseURL}/events/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: DELETE_EVENT,
                payload: id
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const updateEvent = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.patch(`${baseURL}/events`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_EVENT,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const updateEventMembers = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    dispatch({
        type: UPDATE_EVENT_MEMBERS_LOADING
    })
    axios.patch(`${baseURL}/events`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_EVENT_MEMBERS,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const updateSingleEvent = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.patch(`${baseURL}/events/rsvp`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_RSVP,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

// payment update
export const updatePayment = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.patch(`${baseURL}/events/paymentUpdate/`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_PAYMENT,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}

export const setEvent = user => (dispatch) => {
    dispatch({
        type: SET_EVENT,
        payload: user
    })
}

export const setUpdated = state => (dispatch) => {
    dispatch({
        type: SET_EVENT_UPDATED,
        payload: state,
    })
}


export const getEventListWithLast3Month = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/events/eventlist3months`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_EVENTS,
                payload: response.data
            })
        })
        .catch(err => {
           console.error(err);
dispatch(returnErrors(err?.response?.data, err?.response?.status, EVENT_FAIL));
            dispatch({
                type: EVENT_FAIL,
            })
        })
}