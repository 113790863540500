import React, { useState, useEffect } from 'react';
import ReactExport from 'react-data-export';
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { getHistoryForCSV } from '../Tokens.redux';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const JSONToExcel = () => {
    const dispatch = useDispatch();
    const { csvData } = useSelector(state => state.tokenManagementReducer);

    const [modalOpen, setModalOpen] = useState(false);
    const [dates, setDates] = useState({});
    const [fullData, setFullData] = useState([]);

    const getBgColor = (v) => {
        if (v == 0) {
            return 'ff0000'
        } else if (v == 1) {
            return 'ffae42'
        } else {
            return 'ffffff'
        };
    };

    const getYearOptions = (after) => {
        const startYear = 2023;
        const endYear = new Date().getFullYear() + 1;

        let options = [];
        for (let y = startYear; y <= endYear; y++) {
            for (let m = 1; m <= 12; m++) {
                var fM = ("0" + m).slice(-2);
                options.push(`${y}-${fM}`);
            };
        };

        if (after == 'no') {
            return options;
        } else if (!after) {
            return [];
        } else {
            return options.map(option => {
                var optionNum = Number(option.replace('-', ''));
                var afterNum = Number(after.replace('-', ''));
                if (optionNum > afterNum) {
                    return option;
                }
            }).filter(v => v);
        };
    };

    useEffect(() => {
        if (csvData.length > 0) {
            setFullData([{
                columns: Object.keys(csvData[0]).map((c, i) => {
                    return { title: c, width: { wpx: i > 1 ? 70 : 120 } }
                }),
                data: csvData.map(item => {
                    return Object.keys(item).map(i => {
                        return {
                            value: item[i], style: {
                                fill: { patternType: "solid", fgColor: { rgb: getBgColor(item[i]) } },
                                alignment: { horizontal: "center" },
                                border: {
                                    left: { style: 'thin', color: { rgb: 'd8d8d8' } },
                                    right: { style: 'thin', color: { rgb: 'd8d8d8' } },
                                    top: { style: 'thin', color: { rgb: 'd8d8d8' } },
                                    bottom: { style: 'thin', color: { rgb: 'd8d8d8' } }
                                }
                            }
                        }
                    })
                })
            }])
        };
    }, [csvData]);

    return <>
        <div>
            {fullData.length > 0 ?
                <ExcelFile
                    element={<Button color='primary'>Download Excel</Button>}
                    filename="Data"
                    hideElement={false}
                >
                    <ExcelSheet dataSet={fullData} name="Data" />
                </ExcelFile>
                :
                <Button
                    color='warning'
                    onClick={() => {
                        setModalOpen(true);
                    }}
                >
                    Export Excel
                </Button>
            }
        </div>

        <Modal
            isOpen={modalOpen}
            toggle={() => setModalOpen(!modalOpen)}
        >
            <ModalHeader
                toggle={() => setModalOpen(!modalOpen)}
            >
                Select date range
            </ModalHeader>
            <ModalBody>
                <Label>Start date</Label>
                <Input
                    type='select'
                    name='start'
                    value={dates.start}
                    onChange={(e) => setDates({start: e.target.value })}
                >
                    <option value=''>Please select...</option>
                    {getYearOptions('no').map((d) => <option key={d} value={d}>{d}</option>)}
                </Input>

                <Label>End date</Label>
                <Input
                    type='select'
                    name='end'
                    value={dates.end}
                    onChange={(e) => setDates({ ...dates, end: e.target.value })}
                >
                    <option value=''>Please select...</option>
                    {getYearOptions(dates.start).map((d) => <option key={d} value={d}>{d}</option>)}
                </Input>
            </ModalBody>
            <ModalFooter>
                <Button
                    color='primary'
                    disabled={!dates.start || !dates.end}
                    onClick={() => {
                        setModalOpen(false);
                        dispatch(getHistoryForCSV({ dates }));
                    }}
                >
                    Generate
                </Button>
            </ModalFooter>
        </Modal>
    </>;
};

export default JSONToExcel;