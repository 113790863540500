import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Alert, Label, Input, FormGroup } from 'reactstrap';
import { updateUser, setUpdated, addUser } from '../../../redux/actions/userActions'
import { getGroupList, updateGroup } from '../../../redux/actions/groupActions'
import UserEdit from './UserEdit';

const UserUpdate = ({ openUserModal, userModal, user, onChange, addMode, initialUser }) => {
  const dispatch = useDispatch()

  const { message, id } = useSelector(state => state.errorReducer);
  const { updated } = useSelector(state => state.userReducer);
  const authReducer = useSelector(state => state.authReducer);
  const { groups } = useSelector(state => state.groupReducer)

  const [userGroup, setUserGroup] = useState();

  useEffect(() => {
    if (updated) {
      dispatch(setUpdated(false))
      openUserModal()
    }
  }, [updated])

  useEffect(() => {
    dispatch(getGroupList())
  }, [])

  function findUserGroup() {
    return groups.find((group) => {
      return group.members.includes(user._id);
    })
  }

  const onSubmit = e => {
    e.preventDefault()
    if (addMode) {
      dispatch(addUser(user))
    } else {
      var confirm = true
      if (authReducer.user.user.id === user._id && user.status !== 'active') {
        confirm = window.confirm('Changing your status to inactive will prevent access. Proceed?')
      }
      if (confirm) {
        dispatch(updateUser(user))
        var aGroup = findUserGroup();
        if (userGroup !== aGroup) {
          console.log('update group')
          if (aGroup) {
            var newMembers = aGroup.members.filter(function (mem) {
              return mem !== user._id
            })
            aGroup.members = newMembers
            dispatch(updateGroup(aGroup))
          }
          if (userGroup.members.includes(user._id)) {
            return
          } else {
            var holder = userGroup
            holder.members.push(user._id)
            dispatch(updateGroup(holder))
          }
        }
      }
    }
  }

  if (!user) return null
  return (
    <div>
      <Modal isOpen={userModal} toggle={() => openUserModal()}>
        <ModalHeader toggle={() => openUserModal()}>{addMode ? 'Create New Member' : 'Update Member'}</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <UserEdit openUserModal={openUserModal} user={user} initialUser={initialUser} onChange={onChange} addMode={addMode} userGroup={userGroup} setUserGroup={setUserGroup} groups={groups} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type='submit'>{addMode ? 'Create Member' : 'Save'}</Button>{' '}
            <Button color="secondary" onClick={() => openUserModal()}>Close</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div >
  );
}

export default UserUpdate;