import '@fortawesome/fontawesome-svg-core';
import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Label, Input, FormGroup, Form, Alert } from 'reactstrap';
import '../Profile.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from '@fortawesome/free-brands-svg-icons';

const Details = ({ userDetails, onChange, isAdmin, fields, isVisitor, tab = null, groups, shouldShowGroup, isFlightArranger }) => {
    const { message, id } = useSelector(state => state.errorReducer)
    const { updated } = useSelector(state => state.userReducer)
    const isMobileSize = window.innerWidth <= 576 ? true : false

    let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

    if (isMobileSize && tab === 'company') {
        if (fields) {
            fields.sort(function (a, b) {
                return a.mobileSequence - b.mobileSequence
            })
        }
    }

    const OpenSocials = (url) => {
        console.log(url)
        if (isVisitor) {
            window.open(
                url, '_blank'
            )
        }

    }

    if (fields) {
        fields.map((field, i) => {
            if (field.visibleToAdmin) {
                if (!isAdmin) {
                    delete fields[i]
                }
            }
        })
    }

    function renderContent(sectionType = null) {
        return <React.Fragment>
            {/*hide this to avoid multiple alert */}
            {updated && < Alert color='success'>Changes Saved</Alert>}
            {id === 'USER_FAIL' && < Alert color='danger'>{typeof message === 'object' ? message.message : message}</Alert>}
            <Row className='row'>
                {fields.map(({ title, fieldType = null, type, name, id = null, required = false, options = [], disabled = false, min, max, step, pattern, hidden, icon, visibleToAdmin = true,defaultValue }) => {
                    if (hidden && isVisitor && !isAdmin && name !== 'handicap' && isFlightArranger) { return null; }
                    if (name === 'gender' || name === 'name' || name === 'industry') { return null }

                    if (tab === 'social') {
                        if (sectionType === 'personal') {
                            if (fieldType === 'personal') {
                                return <Col md='6' xs="12" sm="6" className='social-media-form' key={title}>
                                    <FormGroup className='form-group' onClick={e => OpenSocials(userDetails[id][name])}>
                                        {icon && <Fragment><FontAwesomeIcon className="icons" icon={icon} />{" "}</Fragment>}
                                        <Label> {title} </Label>
                                        <Input

                                            className={`${name === 'status' ? userDetails[name] : ''}`}
                                            type={type}
                                            value={id && userDetails[id] ? userDetails[id][name] : userDetails[name]}
                                            min={min}
                                            max={max}
                                            name={name}
                                            pattern={pattern}
                                            step={step}
                                            id={id}
                                            required={required}
                                            onChange={onChange}
                                            disabled={isVisitor ? !isAdmin : disabled}
                                        >
                                            {options.map((e, i) => <option key={e.value + i} value={e.value}>{e.name}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            }

                        }

                        if (sectionType === 'company') {
                            if (fieldType === 'company') {
                                return <Col md='6' xs="12" sm="6" className='social-media-form' key={title}>
                                    <FormGroup>
                                        {icon && <Fragment><FontAwesomeIcon className="icons" icon={icon} />{" "}</Fragment>}
                                        <Label> {title}</Label>
                                        <Input
                                            className={`${name === 'status' ? userDetails[name] : ''}`}
                                            icon={icon}
                                            type={type}
                                            value={id && userDetails[id] ? userDetails[id][name] : userDetails[name]}
                                            min={min}
                                            max={max}
                                            name={name}
                                            pattern={pattern}
                                            step={step}
                                            id={id}
                                            required={required}
                                            onChange={onChange}
                                            disabled={isVisitor ? !isAdmin : disabled}
                                        >
                                            {options.map((e, i) => <option key={e.value + i} value={e.value}>{e.name}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            }

                        }
                    }
                    else {
                        if(title ==='Nationality'){
                            return <Col md='4' xs="12" sm="6" key={title}>
                            <FormGroup>
                                <Label>{icon} {title}</Label>
                                <Input
                                    className={`${name === 'status' ? userDetails[name] : ''}`}
                                    type={type}
                                    value={id && userDetails[id] ? userDetails[id][name]!=='' ?userDetails[id][name]: defaultValue : userDetails[name]}
                                    min={min}
                                    max={max}
                                    name={name}
                                    pattern={pattern}
                                    step={step}
                                    id={id}
                                    required={required}
                                    onChange={onChange}
                                    disabled={isVisitor ? (title === 'Golf Handicap' && isFlightArranger) ? !isFlightArranger : !isAdmin : disabled}
                                >
                                    {options.map((e, i) => <option key={e.value + i} value={e.value}>{e.name}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                        }
                        else{
                            return <Col md='4' xs="12" sm="6" key={title}>
                            <FormGroup>
                                <Label>{icon} {title}</Label>
                                <Input
                                    className={`${name === 'status' ? userDetails[name] : ''}`}
                                    type={type}
                                    value={id && userDetails[id] ? userDetails[id][name] : userDetails[name]}
                                    min={min}
                                    max={max}
                                    name={name}
                                    pattern={pattern}
                                    step={step}
                                    id={id}
                                    required={required}
                                    onChange={onChange}
                                    disabled={isVisitor ? (title === 'Golf Handicap' && isFlightArranger) ? !isFlightArranger : !isAdmin : disabled}
                                >
                                    {options.map((e, i) => <option key={e.value + i} value={e.value}>{e.name}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                        }
                    }
                })}
                {shouldShowGroup ?
                    <Col md='4' xs="12" sm="6">
                        <FormGroup>
                            <Label>Group</Label>
                            <Input
                                type='select'
                                name='group_id'
                                value={userDetails?.group_id}
                                disabled={!isAdmin}
                                onChange={onChange}
                            >
                                <><option key='' value=''>
                                    Select Group
                                </option>
                                    {groups.map((e) => <option key={e._id} value={e._id}>
                                        {e.name} ({regionNames.of(e.country)})
                                    </option>)}</>
                            </Input>
                        </FormGroup>
                    </Col>
                    : null}
            </Row>
        </React.Fragment>
    }

    return <Row className={`${tab === 'account' ? 'account-tab' : ''}`}>

        {tab && tab === 'social' ? (
            <React.Fragment>
                {/* <Col sm="12">
                    {updated && < Alert color='success'>Changes Saved</Alert>}
                </Col> */}
                <Col sm="12">
                    <Row>
                        <Col md="2"><div className="form-group-title"> Personal </div></Col>
                        <Col md="10">{renderContent('personal')}</Col>
                    </Row>
                </Col>
                <Col sm="12">
                    <Row>
                        <Col md="2"><div className="form-group-title"> Company </div></Col>
                        <Col md="10">{renderContent('company')}</Col>
                    </Row>
                </Col>
            </React.Fragment>
        ) : (
            <Col sm="12">
                {renderContent()}
            </Col>
        )}
    </Row>
}

export default Details;