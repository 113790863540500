import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Label, Input, FormGroup } from 'reactstrap';
import { setUpdated } from '../../../redux/actions/userActions'
import { modalInputs } from '../../variables'

function UserEdit({ user, initialUser, onChange, addMode, userGroup, setUserGroup, groups }) {
  const dispatch = useDispatch()

  const { message, id } = useSelector(state => state.errorReducer)
  const { updated } = useSelector(state => state.userReducer)

  useEffect(() => {
    if (updated) {
      dispatch(setUpdated(false))
    }
  }, [updated])
  
  useEffect(() => {
    if (initialUser) {
      var aGroup = findUserGroup();
      if (aGroup) {
        setUserGroup(aGroup)
      } else {
        setUserGroup(null)
      }
    }
  }, [initialUser])

  function findUserGroup() {
    return groups.find((group) => {
      return group.members.includes(user._id);
    })
  }

  function findGroupById(targetId) {
    return groups.find((group) => {
      return group._id == targetId;
    })
  }

  let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  if (!user) return null
  return (
    <Fragment>
      {id === 'USER_FAIL' && < Alert color='danger'>{typeof message === 'object' ? message.message : message}</Alert>}
      {modalInputs?.map(({ title, type, name, id = null, required = false, options = [] }) => {
        if (addMode && !required) return null
        return <FormGroup>
          <Label>{title}</Label>
          <Input
            type={type}
            value={id && user[id] ? user[id][name] : user[name]}
            name={name}
            id={id}
            required={required}
            onChange={onChange}
          >
            {options.map((e, i) => <option key={e.value + i} value={e.value}>{e.name}</option>)}
          </Input>
        </FormGroup>
      })}
      {!addMode && <FormGroup>
        <Label>Group</Label>
        <Input
          type='select'
          value='groupId'
          name='groupId'
          id='groupId'
          required={true}
          onChange={(e) => setUserGroup(findGroupById(e.target.value))}
        >
          {userGroup ? <><option key={userGroup._id} value={userGroup._id}>
            {userGroup.name} ({regionNames.of(userGroup.country)})
          </option>
            {groups?.map((e) => e._id == userGroup._id ? null : <option key={e._id} value={e._id}>
              {e.name} ({regionNames.of(e.country)})
            </option>)}</>
            :
            <><option key='' value=''>
              Select Group
            </option>
              {groups?.map((e) => <option key={e._id} value={e._id}>
                {e.name} ({regionNames.of(e.country)})
              </option>)}</>}
        </Input>
      </FormGroup>}
    </Fragment >
  );
}

export default UserEdit;