import { ADD_SCORE, DELETE_SCORE, SCORE_FAIL, GET_SCORE, GET_SCORES, SET_SCORE_UPDATED, UPDATE_SCORE, SET_SCORE, GET_HOLE_IN_ONE_SCORES, SCORES_LOADING } from '../types'
import axios from 'axios'
import { tokenConfig } from './authActions'
import { returnErrors, clearErrors } from './errorActions'
import { baseURL } from '../helpers'

export const getScores = (params) => (dispatch, getState) => {
    dispatch(clearErrors())
    dispatch({
        type:SCORES_LOADING
    })
    axios.post(`${baseURL}/scores/getScores`, params, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_SCORES,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, SCORE_FAIL));
            dispatch({
                type: SCORE_FAIL,
            })
        })
}

export const getHoleInOneScores = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/scores/holeInOne/all`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_HOLE_IN_ONE_SCORES,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, SCORE_FAIL));
            dispatch({
                type: SCORE_FAIL,
            })
        })
}

export const getScoresById = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/scores/user_id`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_SCORES,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, SCORE_FAIL));
            dispatch({
                type: SCORE_FAIL,
            })
        })
}

export const getScoresByEventId = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/scores/event_id`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_SCORES,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, SCORE_FAIL));
            dispatch({
                type: SCORE_FAIL,
            })
        })
}

export const getScore = (id) => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/scores/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_SCORE,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, SCORE_FAIL));
            dispatch({
                type: SCORE_FAIL,
            })
        })
}

export const addScore = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/scores`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: ADD_SCORE,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, SCORE_FAIL));
            dispatch({
                type: SCORE_FAIL,
            })
        })
}

export const deleteScore = id => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.delete(`${baseURL}/scores/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: DELETE_SCORE,
                payload: id
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, SCORE_FAIL));
            dispatch({
                type: SCORE_FAIL,
            })
        })
}

export const updateScore = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.patch(`${baseURL}/scores`, requestBody, tokenConfig(getState))
        .then(response => {
            //check if status is paid -> show score preview in modal box
            //generate pdf -> store in gdrive
            dispatch({
                type: UPDATE_SCORE,
                payload: response.data
            })


        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, SCORE_FAIL));
            dispatch({
                type: SCORE_FAIL,
            })
        })
}

export const setScore = score => (dispatch) => {
    dispatch({
        type: SET_SCORE,
        payload: score
    })
}

export const setUpdated = state => (dispatch) => {
    dispatch({
        type: SET_SCORE_UPDATED,
        payload: state,
    })
}