import React, { useState } from 'react';
import { faQrcode, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QR from './QR';
import './QR.css'


const FloatingButton = () => {
    const [isQRVisible, setisQRVisible] = useState(false);
    function actionToggle() { 
        setisQRVisible (!isQRVisible)
    }

    return (
        <div className='floating-button' onClick={() => actionToggle()}>
                            <span> 
                                <FontAwesomeIcon className="icons" icon={faQrcode} color="#ffff" style={{size: '50px'}}/>
                            </span>
                            {
                                isQRVisible ? (
                                    <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 99 }}>
                                    
                                    <div className="mobile-menu text-center" style={{color:'white', fontWeight:'bold'}}>
                                        <ul>
                                            <div onClick={() => actionToggle()} style={{display:'flex', justifyContent:'flex-end', alignItems:'flex-end', paddingTop:'20px', paddingRight: '20px'}}>
                                                <FontAwesomeIcon className="icons" icon={faTimes} style={{ fontSize: 28 }} color="#ffff" />
                                            </div>
                                            <QR/> 
                                        </ul>
                                    </div>
                                  </div>
                    
                                ) : null 
                            }
                               
                        </div>
)}

export default FloatingButton; 