import React from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import backArrow from '../../Assets/backArrow.svg';
import './posts.css';

const PostCard = ({
    post, user, users, isAdmin, postsGeneralState, onDelete, setIsOpen, setPost, specificPost
}) => {
    if (!post) { return null };
    if (specificPost) { postsGeneralState = 'edit' }
    const postUser = users.find(u => u._id == post.userId);
    const postUserName = postUser ? postUser.personalDetails?.name : 'Admin';
    const postProfileLink = postUser ? `/profile/${postUser._id}` : null;
    const shouldShowControls = postsGeneralState == 'edit' && (isAdmin || post.userId == user.user?.id);

    return (
        <div className={specificPost ? 'postCardDiv noBackground' : 'postCardDiv'}>
            {specificPost && <div className='postBackButton' onClick={() => { window.location.assign(`/posts/`) }}>
                <img src={backArrow}></img>
                <div className='postBackText'>Back</div>
            </div>}
            {shouldShowControls && specificPost ? <div className='postActions'>
                <div className='postsActionButtonsContainer'>
                    <Button className="editPostButton" color='primary' onClick={() => { setIsOpen(true); setPost(post) }}>
                        Edit post
                    </Button>
                    <Button className="deletePostButton" color='danger' onClick={() => onDelete(post._id)}>
                        Delete post
                    </Button>
                </div>
            </div> : <div style={{ height: '22px' }}></div>}

            {shouldShowControls && !specificPost ? <div className='postActions'>
                <span className='editPostSpan' onClick={() => { setPost(post); setIsOpen(true) }}>
                    <FontAwesomeIcon icon={faEdit} />
                </span>
                <span className="deletePostSpan" onClick={() => onDelete(post._id)}>
                    <FontAwesomeIcon icon={faTrash} />
                </span>
            </div> : <div style={{ height: '22px' }}></div>}
            <div className='postCategory'>{post.category}</div>
            <div className='postTitle'>{post.title}</div>
            <div className='postDate'>
                BY <a href={postProfileLink} target='_blank'>{postUserName.toUpperCase()}</a> | {moment(post.createdAt).format('DD/MM/YYYY')}
            </div>

            <div className='postContent'>
                {post.content ?
                    <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
                    :
                    <div>No content</div>
                }
            </div>

            {post.image && post.image.location && <div>
                <img
                    className={specificPost ? 'postImage' : 'smallPostImage'}
                    src={post.image.location}>
                </img>
            </div>}

            {specificPost ?
                post.externalLink && <a href={post.externalLink} target='_blank'>See more</a>
                :
                <div className='postAction'>
                    <Button
                        className='viewPostBtn'
                        onClick={() => { window.location.assign(`/posts/${post._id}`) }}
                        type='button'
                    >
                        View post
                    </Button>
                </div>}
        </div >
    );
}

export default PostCard;