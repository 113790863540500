import { tokenConfig } from '../../redux/actions/authActions';
import { baseURL } from '../../redux/helpers';
import axios from 'axios';

const GET_USERS_WITH_TOKENS = "TokenTypes/GET_USERS_WITH_TOKENS";
const ADD_CUSTOM_TOKENS = "TokenTypes/ADD_CUSTOM_TOKENS";
const GET_TOKENS_HISTORY = "TokenTypes/GET_TOKENS_HISTORY";
const GET_HISTORY_FOR_CSV = "TokenTypes/GET_HISTORY_FOR_CSV";
const SET_ERROR = "TokenTypes/SET_ERROR";
const SET_LOADING = "TokenTypes/SET_LOADING";

function initialState() {
    return {
        usersWithTokens: [],
        tokensHistory: [],
        csvData: [],
        customMessage: '',
        error: null,
        loading: false,
    };
};

const tokensReducer = function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case GET_USERS_WITH_TOKENS:
            return {
                ...state,
                usersWithTokens: payload,
                error: null,
                loading: false,
            }
        case ADD_CUSTOM_TOKENS:
            return {
                ...state,
                customMessage: payload,
                error: null,
                loading: false,
            }
        case GET_TOKENS_HISTORY:
            return {
                ...state,
                tokensHistory: payload,
                error: null,
                loading: false,
            }
        case GET_HISTORY_FOR_CSV:
            return {
                ...state,
                csvData: payload,
                error: null,
                loading: false,
            }
        case SET_ERROR:
            return {
                ...state,
                customMessage: '',
                error: payload,
                loading: false,
            }
        case SET_LOADING:
            return {
                ...state,
                customMessage: '',
                error: null,
                loading: true,
            }
        default:
            return {
                ...state,
                customMessage: '',
                error: null,
                loading: false,
            }
    };
};

export const getUsersWithTokens = () => (dispatch, getState) => {
    dispatch({ type: SET_LOADING });
    axios.get(`${baseURL}/tokensManagement/getUsersWithTokens`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_USERS_WITH_TOKENS,
                payload: response.data,
            })
        })
        .catch(err => {
            console.error(err);
            dispatch({
                type: SET_ERROR,
                payload: err?.response?.data,
            })
        });
};

export const addCustomTokens = requestBody => (dispatch, getState) => {
    dispatch({ type: SET_LOADING });
    axios.post(`${baseURL}/tokensManagement/addCustomTokens`, requestBody, tokenConfig(getState))
        .then(response => {
            const { amount, userName } = response.data;
            dispatch({
                type: ADD_CUSTOM_TOKENS,
                payload: `${userName} tokens modified by ${amount}`,
            })
        })
        .then(a => {
            dispatch(getUsersWithTokens());
        })
        .catch(err => {
            console.error(err);
            dispatch({
                type: SET_ERROR,
                payload: err?.response?.data,
            })
        });
};

export const getTokensHistory = requestBody => (dispatch, getState) => {
    dispatch({ type: SET_LOADING });
    axios.post(`${baseURL}/tokensManagement/getTokensHistory`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_TOKENS_HISTORY,
                payload: response.data,
            })
        })
        .catch(err => {
            console.error(err);
            dispatch({
                type: SET_ERROR,
                payload: err?.response?.data,
            })
        });
};

export const getHistoryForCSV = requestBody => (dispatch, getState) => {
    dispatch({ type: SET_LOADING });
    axios.post(`${baseURL}/tokensManagement/getHistoryForCSV`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_HISTORY_FOR_CSV,
                payload: response.data,
            })
        })
        .catch(err => {
            console.error(err);
            dispatch({
                type: SET_ERROR,
                payload: err?.response?.data,
            })
        });
};

export default tokensReducer;
