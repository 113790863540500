import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Input } from 'reactstrap';
import './Awards.css';

const HoleInOneModal = ({ isOpen, setIsOpen, score, setScore, onSubmit, users }) => {
    if (!score) { return null }
    function onChange(e) {
        const { name, value } = e.target;
        if (name == 'value') {
            setScore({ ...score, [name]: Number(value) });
        } else {
            setScore({ ...score, [name]: value });
        }
    }

    const currentId = score.user_id || score.name;
    const showUserInSelect = currentId ? users?.find(u => u._id == currentId) ? true : false : true;

    return (
        <div>
            <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <ModalHeader toggle={() => setIsOpen(!isOpen)}>
                    {score.user_id ? 'Update Hole in One Winner' : 'Add New Hole in One Winner'}
                </ModalHeader>
                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        <div>
                            <Label className='holeInOneModalInput'>Event Date*
                                <Input
                                    type={'date'}
                                    value={score.event_date}
                                    name={'event_date'}
                                    id={'event_date'}
                                    placeholder={'Event Date'}
                                    required={true}
                                    onChange={(e) => onChange(e)}
                                />
                            </Label>

                            <Label className='holeInOneModalInput'>Name*
                                <Input
                                    type={showUserInSelect ? 'select' :'text'}
                                    value={score.user_id || score.name}
                                    name={'name'}
                                    id={'name'}
                                    placeholder={"Winner's Name"}
                                    required={true}
                                    onChange={(e) => onChange(e)}
                                >
                                    <option value=''>Winner's Name</option>
                                    <option value='.'>Type manually ...</option>
                                    {users?.map(user => {
                                        return <option key={user._id} value={user._id}>{user.personalDetails.name}</option>
                                    })}
                                </Input>
                            </Label>

                            <Label className='holeInOneModalInput'>Event / Course Title*
                                <Input
                                    type={'textarea'}
                                    value={score.event_id}
                                    name={'event_id'}
                                    id={'event_id'}
                                    rows='4'
                                    placeholder={'Event title'}
                                    required={true}
                                    onChange={(e) => onChange(e)}
                                />
                            </Label>

                            <Label className='holeInOneModalInput'>Hole#*
                                <Input
                                    type={'number'}
                                    value={score.value}
                                    name={'value'}
                                    id={'value'}
                                    placeholder={'16'}
                                    required={true}
                                    onChange={(e) => onChange(e)}
                                />
                            </Label>
                        </div>
                        <p>The award will also appear on this person’s profile</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ width: '100%' }} color="primary" type='submit'>
                            Save Winner
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div >
    );
}

export default HoleInOneModal;