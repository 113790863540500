import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import './Update.css'

import { getUsers } from '../../redux/actions/userActions'

//Import Variables
import { defaultUser } from '../variables'
import Update from './Update';
import QRscanner from '../QR/QRScanner';


function PaymentUpdate() {
    const dispatch = useDispatch()
    const [user, setUser] = useState(defaultUser)
    const [modal, setModal] = useState(false)

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const toggle = () => {
        setModal(!modal)
    }


    return (
        <Fragment>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center'}}>
                <div> 
                    <QRscanner/> 
                    {/* <button 
                    className='button' 
                    type='submit' 
                    onClick={() => window.open(`/qrscanner`)}> 
                        Scan QR 
                    </button>  */}               
                </div>
                
            </div> 
            <br></br>

            <Update
                // openUserModal={openUserModal}
                // openInvoiceModal={openInvoiceModal}
            />
        </Fragment>
    );
}

export default PaymentUpdate;