import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Alert, Label, Input, FormGroup } from 'reactstrap';
import { updateGroup, setUpdated, addGroup, deleteGroup } from '../../../redux/actions/groupActions'
import { modalInputs } from '../../variables'
import GroupEdit from './GroupEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import './GroupModal.css';

const GroupUpdate = ({ openGroupModal, openEditMembersModal, groupModal, group, onChange, addMode }) => {
  const dispatch = useDispatch()

  const { message, id } = useSelector(state => state.errorReducer)
  const { updated } = useSelector(state => state.groupReducer)
  const authReducer = useSelector(state => state.authReducer)

  useEffect(() => {
    if (updated) {
      dispatch(setUpdated(false))
    }
  }, [updated])

  const onDelete = (id) => {
    var confirm = window.confirm('Permanently delete this Group?')
    if (confirm) dispatch(deleteGroup(id))
  }

  const onSubmit = e => {
    e.preventDefault()
    if (addMode) {
      dispatch(addGroup(group))
    } else {
      dispatch(updateGroup(group))
    }
    openGroupModal(group)
  }

  // if (!group) return null
  return (
    <div>
      <Modal isOpen={groupModal} toggle={() => openGroupModal()}>
        <ModalHeader toggle={() => openGroupModal()}>{addMode ? 'Create New Group' : 'Update Group'}</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <GroupEdit  openGroupModal = {openGroupModal}  group = {group} onChange = {onChange} addMode = {addMode} /> 
            {!addMode && <div>
              Current Members : {group.members?.length}
              <span className="action-btn" onClick={() => {openEditMembersModal(group); openGroupModal(group)}}>
                  <FontAwesomeIcon icon={faUserPlus } color="#3C3C3C" /> Edit Members
              </span>
              <span className="action-btn text-red" onClick={() => onDelete(group._id)}>
                <FontAwesomeIcon icon={faTrash} /> Delete Group
              </span>
            </div>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type='submit'>{addMode ? 'Create Group' : 'Save Group'}</Button>{' '}
            <Button color="secondary" onClick={() => openGroupModal()}>Close</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div >
  );
}

export default GroupUpdate;