import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import profilePlaceholder from '../../Assets/placeholder.png';
import { baseURL } from '../../redux/helpers';
import axios from "axios";
import './Awards.css';

function EventCardWithScore(props) {
    const { event, users } = props;
    const [selected, setSelected] = useState(false);
    const [thisEventScores, setThisEventScores] = useState([]);

    useEffect(() => {
        if (selected && thisEventScores.length < 1) {
            axios.post(`${baseURL}/scores/event_id`, { event_id: event._id })
                .then(response => {
                    if (Array.isArray(response.data)) {
                        setThisEventScores(response.data.filter(function (item) {
                            return item.award_type == 'normal'
                        }));
                    };
                });
        };
    }, [selected]);

    function getUserProfile(id) {
        var user = users?.find(u => u._id == id);
        if (user) {
            return user
        }
        return 'N/A'
    };

    function imageClasses(rank) {
        if (rank == 1) {
            return 'profile-avatar mr-3 goldBorder'
        } else if (rank == 2) {
            return 'profile-avatar mr-3 silverBorder'
        } else {
            return 'profile-avatar mr-3 bronzeBorder'
        }
    }

    const UserRankCard = (p) => {
        var points = p.p.points;
        var rank = p.p.rank;
        var user = p.p.user;
        if (points == 0) { return null };

        return <><div className='userContainerPc'>
            <div>
                <img className={imageClasses(rank)} src={user.photoSRC ? user.photoSRC : profilePlaceholder}></img>
            </div>
            <div className='nameContainer' style={{ fontSize: '16px' }}>
                <strong>{user.personalDetails.name}</strong><br />{user.companyDetails.jobTitle}
            </div>
            <div className='companyContainer' style={{ fontSize: '16px' }}>
                <strong>{user.companyDetails.name}</strong><br />{user.companyDetails.industry}
            </div>
            <div>
                <span className={rank == 1 ? 'pointsContainerFirst' : 'pointsContainer'}>{points}</span> pts.
            </div>
        </div>
            <div className='userContainerMobile'>
                <div className='mobileRow'>
                    <img className={imageClasses(rank)} src={user.photoSRC ? user.photoSRC : profilePlaceholder}></img>
                    <div style={{ marginTop: '-8px' }}>
                        <strong>{user.personalDetails.name}</strong><br />{user.companyDetails.jobTitle}
                    </div>
                    <div style={{ margin: '-10px 0 0 25px' }}>
                        <span className={rank == 1 ? 'pointsContainerFirst' : 'pointsContainer'}>{points}</span> pts.
                    </div>
                </div>
                <div className='companyContainer'>
                    <strong>{user.companyDetails.name}</strong><br />{user.companyDetails.industry}
                </div>
                <hr />
            </div></>
    }

    return (
        <Fragment>
            <div className='eventScoreCard' onClick={() => setSelected(!selected)}>
                <div className='plusOrMinus'>{selected ? '-' : '+'}</div>
                {event.bocEvent && <div className='isBocEvent'>BOC Event</div>}
                <div className='theName'>{event.name}</div>
                <span>
                    <FontAwesomeIcon icon={faLocationArrow} /> &nbsp;
                    <span style={{ whiteSpace: "pre-wrap" }}>
                        <a style={{ color: 'inherit', fontWeight: '500', fontSize: '18px', textDecoration: 'none' }} target='blank' href={event.locationURL}>{event.location}</a>
                    </span>
                </span>
                <br />
                <span>
                    <FontAwesomeIcon icon={faCalendar} /> &nbsp;
                    <span style={{ whiteSpace: "pre-wrap", fontSize: '18px' }}>{event.date}</span>
                </span>
                {selected && <div style={{ marginTop: '20px' }}>
                    {thisEventScores?.length ?
                        thisEventScores.sort((a, b) => {
                            if (b.value !== a.value) {
                                return b.value - a.value;
                            };
                            return a.priority - b.priority;
                        }).map((s, i) => {
                            if (i > 2) return null;
                            var u = getUserProfile(s.user_id);
                            return <p>
                                <UserRankCard p={{ user: u, rank: i + 1, points: s.value }} />
                            </p>
                        })
                        : "No Ranks"}
                </div>}
            </div>
        </Fragment>
    );
}

export default EventCardWithScore;