import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import './Awards.css'
import profilePlaceholder from '../../Assets/placeholder.png';
import { getScores } from '../../redux/actions/scoreActions';
import { Spinner } from 'reactstrap';

function Leaderboard(props) {
    const dispatch = useDispatch();
    const { scores, loading } = useSelector(state => state.scoreReducer);

    const { users } = props;
    const [filterYear, setFilterYear] = useState();

    useEffect(() => {
        var today = new Date();
        var year = today.getFullYear();
        setFilterYear(year);
    }, [])

    useEffect(() => {
        if (filterYear) {
            dispatch(getScores({ event_year: filterYear, award_type: 'normal' }))
        }
    }, [filterYear])

    function calcUserPoints(user_id) {
        if (Array.isArray(scores)) {
            var num = 0;
            // sum up user's 6 max scores within filterYear and award_type == 'normal'
            const top6Scores = scores.filter(function (item) {
                return item.user_id == user_id
            }
            ).sort(function (a, b) { return b.value - a.value }).slice(0, 6)

            top6Scores.map(s => {
                num += s.value
            })

            return { score: num || 0, noOfGames: top6Scores.length || 0 }
        } else {
            return 0
        }
    }

    const FirstUserCard = (userr) => {
        var user = userr.user;
        const userPoints = calcUserPoints(user._id)?.score
        const userNoOfGames = calcUserPoints(user._id)?.noOfGames
        return <div style={{marginTop:'10px'}}>
            <div className='userContainerPc'>
            <div className='rankNum' style={{ fontSize: '28px' }}>1.</div>
            <div>
                <img className="profile-avatar mr-3" src={user.photoSRC ? user.photoSRC : profilePlaceholder}></img>
            </div>
            <div className='nameContainer' style={{ fontSize: '16px' }}>
                <strong>{user.personalDetails.name}</strong><br />{user.companyDetails.jobTitle}
            </div>
            <div className='companyContainer' style={{ fontSize: '16px' }}>
                <strong>{user.companyDetails.name}</strong><br />{user.companyDetails.industry}
            </div>
            <div>
                {loading ? <Spinner color='primary' /> : <span><span className='pointsContainerFirst'>{userPoints}</span> pts. {userNoOfGames < 6 && `(${userNoOfGames})`}</span>}
            </div>
            </div>
            <div className='userContainerMobile' style={{width:'90vw'}}>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={{display:'flex'}}>
                    <img className="profile-avatar mr-3" src={user.photoSRC ? user.photoSRC : profilePlaceholder} style={{margin:'0px'}}></img>
                    <div>
                        <strong>{user.personalDetails.name}</strong>
                        <div>
                        {user.companyDetails.jobTitle}
                        </div>
                        <div style={{marginTop:'10px'}}>
                            <strong>{user.companyDetails.name}</strong><br />{user.companyDetails.industry}
                            </div>
                    </div>
                    </div>
                 
                    <div style={{}}>

                        {loading ? <Spinner color='primary' /> : <div style={{display:'flex'}}><span className='pointsContainerFirst' >{userPoints}</span> &nbsp; pts. &nbsp;<div>{userNoOfGames < 6 && `(${userNoOfGames})`}</div></div>}

                    </div>
                </div>
                <br />
                <hr />
            </div>
        </div>
    }

    const UserCards = () => {
        var allUsers = users?.sort(
            function (a, b) { return calcUserPoints(b._id)?.score - calcUserPoints(a._id)?.score }
        ).map((user, i) => {
            if (i == 0) { return <FirstUserCard user={user} /> }
            const userPoints = calcUserPoints(user._id)?.score
            const userNoOfGames = calcUserPoints(user._id)?.noOfGames
            return <><div className='userContainerPc'>
                <div className='rankNum'>{i + 1}.</div>
                <div>
                    <img className="profile-avatar mr-3" src={user.photoSRC ? user.photoSRC : profilePlaceholder}></img>
                </div>
                <div className='nameContainer' style={{ fontSize: '14px' }}>
                    <strong>{user.personalDetails.name}</strong><br />{user.companyDetails.jobTitle}
                </div>
                <div className='companyContainer' style={{ fontSize: '14px' }}>
                    <strong>{user.companyDetails.name}</strong><br />{user.companyDetails.industry}
                </div>
                <div className='pointsContainer'>
                    {loading ? <Spinner color='primary' /> : <span> <strong>{userPoints}</strong> pts. {userNoOfGames < 6 && `(${userNoOfGames})`}</span>}
                </div>
            </div>
            <div className='userContainerMobile' style={{width:'90vw'}}>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={{display:'flex'}}>
                    <img className="profile-avatar mr-3" src={user.photoSRC ? user.photoSRC : profilePlaceholder} style={{margin:'0px'}}></img>
                    <div>
                        <strong>{user.personalDetails.name}</strong>
                        <div>
                        {user.companyDetails.jobTitle}
                        </div>
                            <div style={{marginTop:'10px'}}>
                            <strong>{user.companyDetails.name}</strong><br />{user.companyDetails.industry}
                            </div>
                    </div>
                    </div>
                 
                    <div style={{}}>

                        {loading ? <Spinner color='primary' /> : <div style={{display:'flex'}}><span className='pointsContainerFirst' >{userPoints}</span> &nbsp;  pts. &nbsp;<div>{userNoOfGames < 6 && `(${userNoOfGames})`}</div></div>}

                    </div>
                </div>
                <br />
                <hr />
            </div>
            </>
        })
        if (allUsers && scores) {
            return allUsers
        }
        if (Array.isArray(allUsers)) {
            return <p>No users</p>
        }
        return <p>Loading users ...</p>
    }

    const DateDropdown = () => {
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var baseYear = 2022;
        var yearDifference = currentYear - baseYear
        var years = [];
        for (let i = 0; i <= yearDifference; i++) {
            years.push(baseYear + i);
        }
        return <select className='yearFilter' name='years' id='years' value={filterYear} onChange={(e) => { setFilterYear(e.target.value) }}>
            {years.reverse().map(y => {
                return <option value={y}>{y}</option>
            })}
        </select>
    }

    return (
        <Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'center',marginBottom:'20px' }}>
                    <DateDropdown />
                </div>
                <div className='containerWithScroll'>
                    {/* <img src={firstPlaceCrown} className='crownImage'></img> */}
                    <UserCards />
                </div>
                {/* <div className='pointsCalcTip'>
                    The points was calculated based on each points gathered every events.
                </div> */}
            </div>
        </Fragment>
    );
}

export default Leaderboard;