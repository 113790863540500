import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import HoleInOneModal from './HoleInOneModal';
import { addScore, updateScore, deleteScore, getHoleInOneScores } from '../../redux/actions/scoreActions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import './Awards.css';

const initialScore = {
    event_date: "",
    name: "",
    user_id: "",
    event_id: "",
    value: "",
    award_type: "holeInOne"
};

function HoleInOne(props) {
    const dispatch = useDispatch();
    const { users, isAdmin } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [currentScore, setCurrentScore] = useState(initialScore);
    const [currentWidth, setCurrentWidth] = useState();

    const { holeInOneScores } = useSelector(state => state.scoreReducer);

    console.log(holeInOneScores)

    const updateDimension = () => {
        setCurrentWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimension);
        dispatch(getHoleInOneScores())
        return () => window.removeEventListener("resize", updateDimension);
    }, []);

    const ScoreTable = () => {
        if (!users || !holeInOneScores) { return <p>Loading scores ...</p> }
        if (Array.isArray(holeInOneScores) && holeInOneScores.length == 0) {
            return <p style={{ width: '800px' }}>No scores</p>
        }

        return <div className='holeInOneScoresContainer'>
            {currentWidth <= 620 ?
                <div>
                    {holeInOneScores.sort((a, b) => {
                        if (a.event_date > b.event_date) return -1
                        if (a.event_date < b.event_date) return 1
                        return 0
                    }).map((score, i) => {
                        const currentUser = users?.find(u => u._id == score.user_id);
                        const totalEntries = holeInOneScores.length;
                        return <div className='holeInOneMobileCard'>
                            <div><strong>No: </strong>{totalEntries - i}</div>
                            <div><strong>Date: </strong>{score.event_date}</div>
                            <div><strong>Name: </strong>{currentUser ? <a className='scoreUserProfileLink' href={`/profile/${currentUser._id}`} target='_blank'>{currentUser.personalDetails.name}</a> : score.user_id}</div>
                            <div><strong>Event - Course: </strong>{score.event_id}</div>
                            <div><strong>Hole #: </strong>{score.value}</div>
                            {isAdmin && <div className='postActions'>
                                <span className='editPostSpan' onClick={() => {
                                    setCurrentScore(score);
                                    setIsOpen(true)
                                }}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </span>
                                <span className="deletePostSpan" onClick={() => onDelete(score._id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </span>
                            </div>}
                        </div>
                    })}
                </div>
                :
                <table>
                    <tr>
                        <th>No.</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Event - Course</th>
                        <th>Hole #</th>
                        {isAdmin && <th>Actions</th>}
                    </tr>
                    {holeInOneScores.sort((a, b) => {
                        if (a.event_date > b.event_date) return -1
                        if (a.event_date < b.event_date) return 1
                        return 0
                    }).map((score, i) => {
                        const currentUser = users?.find(u => u._id == score.user_id);
                        const totalEntries = holeInOneScores.length;
                        return <tr>
                            <td>{totalEntries - i}.</td>
                            <td>{score.event_date}</td>
                            <td>{currentUser ? <a className='scoreUserProfileLink' href={`/profile/${currentUser._id}`} target='_blank'>{currentUser.personalDetails.name}</a> : score.user_id}</td>
                            <td>{score.event_id}</td>
                            <td>{score.value}</td>
                            {isAdmin && <td className='postActions'>
                                <span className='editPostSpan' onClick={() => {
                                    setCurrentScore(score);
                                    setIsOpen(true)
                                }}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </span>
                                <span className="deletePostSpan" onClick={() => onDelete(score._id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </span>
                            </td>}
                        </tr>
                    })}
                </table>
            }
        </div>
    };

    const onSubmit = e => {
        e.preventDefault();
        if (currentScore.user_id) {
            dispatch(updateScore(currentScore));
        } else {
            if (currentScore && currentScore.value && currentScore.name) {
                currentScore.user_id = currentScore.name;
                currentScore.name = null;
                dispatch(addScore(currentScore));
            }
        }
        setCurrentScore(initialScore);
        setIsOpen(false);
    };

    const onDelete = (id) => {
        var confirm = window.confirm('Permanently delete this Score?');
        if (confirm) dispatch(deleteScore(id));
    }

    return (
        <Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {isAdmin && <div className='addNewHoleInOneButtonContainer'>
                    <Button onClick={() => {
                        setCurrentScore(initialScore);
                        setIsOpen(true);
                    }} color='primary'>Add New</Button>
                </div>}
                <div className='containerWithScroll'>
                    <ScoreTable />
                </div>
            </div>
            <HoleInOneModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                score={currentScore}
                setScore={setCurrentScore}
                onSubmit={onSubmit}
                users={users}
            />
        </Fragment>
    );
}

export default HoleInOne;