import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import '../Profile.css'

import { getInvoicesById } from '../../../redux/actions/invoiceActions'

//Import Components
import InvoiceView from '../../UserList/InvoiceModal/InvoiceView/InvoiceView'

function InvoiceList() {
    const dispatch = useDispatch()
    const { invoices } = useSelector(state => state.invoiceReducer)
    const { user } = useSelector(state => state.userReducer)

    useEffect(() => {
        dispatch(getInvoicesById())
    }, [])

    const [viewState, setViewState] = useState(false)
    const [invoice, setInvoice] = useState(null)

    const viewInvoice = invoice => {
        setInvoice(invoice)
        toggleViewState()
    }
    
    const toggleViewState = () => {
        setViewState(!viewState)
    }
    return (
        <Fragment>
            <InvoiceView
                viewState={viewState}
                toggleViewState={toggleViewState}
                user={user}
                invoice={invoice}
            />
               <div> 
                <ul className='list-unstyled' id='userList' size="sm">
                    <li className='table-view '>
                        <span >Invoice No.</span> <span/> <span/>
                        <span > Amount </span> 
                        <span> Date  </span>
                        
                        
                    </li>
                    <li>
                        
                            {invoices.map(invoice => 
                        
                            <li className='items'>

                                <span className='invoice-name'>{invoice.invoiceNo}</span>
                                <span/> <span/><span className='invoice-fee'> MYR {invoice.fee}</span>
                                <span className='invoice-date'>{invoice.date}</span> 
                               
                                <span className= 'invoice-info'>
                                    <span className='infoButton'
                                        onClick={() => { viewInvoice(invoice) }}
                                    >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </span>
                                </span>
                            </li> )}
                           
                    </li>
                </ul> 
            </div>
           
        </Fragment>
    );
}

export default InvoiceList;