import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Input, Row, Table } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { getEvent, updateSingleEvent } from '../../redux/actions/eventActions'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Import Components
import RSVPModal from '../../components/EventCard/RSVPModal/RSVPModal'
import { faCalendar, faLocationArrow } from '@fortawesome/free-solid-svg-icons';

import EventCard from '../../components/EventCard/EventCard'


function EventDetails(props) {
    const dispatch = useDispatch()
    const { event } = useSelector(state => state.eventReducer)
    
    
    useEffect(() => {
        dispatch(getEvent(props.match.params.id))
    }, [])

    return (
        <Fragment>
            <EventCard event ={event}/>
        </Fragment>
    );
}

export default EventDetails;