import {
    GET_TOKEN_TAB,
    UPDATE_TOKEN_TAB
} from '../types';

function initialState() {
    return {
        accessTokenTab: false,
        userName: '',
        updatedAt:'',
    };
}

const accessReducer = function (state = initialState(), action) {
    const { type, payload } = action
    switch (type) {
        case GET_TOKEN_TAB:
            return {
                ...state,
                accessTokenTab: payload.token_tab,
                userName:payload.username,
                updatedAt:payload.updatedAt
            }
        case UPDATE_TOKEN_TAB:
            return {
                ...state,
                accessTokenTab: payload.token_tab,
                userName:payload.username,
                updatedAt:payload.updatedAt
            };

        default:
            return state;
    }
};

export default accessReducer;
