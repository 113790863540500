import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import QRCode from 'qrcode.react';
import './QR.css';
import { loadUser } from '../../redux/actions/authActions';
import Generate from './GenerateInvoice';

const QR = (props) => {
    const dispatch = useDispatch()

    const auth = useSelector((state) => state.authReducer);
    const { isAuthenticated, isLoading, user } = auth;

    useEffect(() => {
        dispatch(loadUser());
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-init">
            <Generate/>

                {/* can't seem to change the qr code directly with css */}
             {user ? <QRCode size={200} value={`/profile/${user._id}`}/>: null}
                <br /><br />
                <p className="text">Keep this unique generated QR to yourself as this will be used for registration at the event</p>

                
            </div>
        </div>
    );
}

export default QR;