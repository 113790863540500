import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  Modal,
} from "reactstrap";
import {
  getEvent,
  getEventListWithLast3Month,
  updateEvent,
} from "../../redux/actions/eventActions";
import { getUsers } from "../../redux/actions/userActions";
import {
  faTrashAlt,
  faUser,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";

import "./FlightArranger.css";
import FlightPDF from "./FlightPDF/home";
import ParticipantModal from "./ParticipantModal/ParticipantModal";
import moment from "moment";

//Import Components

function FlightArranger(props) {
  const dispatch = useDispatch();
  const { event, events } = useSelector((state) => state.eventReducer);
  const { users } = useSelector((state) => state.userReducer);
  const [dragValue, setDragValue] = useState([]);
  const [dragValue2, setDragValue2] = useState([]);
  const [guestList, setGuestList] = useState([]);
  const [displayList, setDesplayList] = useState([]);
  const [flights, setFlights] = useState([]);
  const [flight, setFlight] = useState(null);
  const [reset, setReset] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [participantsModal, setParticipantsModal] = useState(false);
  const [dataFlight, setDataFlight] = useState([]);
  const [arrangeDisabled, setArrangeDisabled] = useState(true);
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getEvent(props.match.params.id));
    dispatch(getEventListWithLast3Month());
  }, []);

  // Helper function to track groupings over the last 3 months
  function getPreviousGroups(months) {
    const playerGroups = {};
    months.forEach((month) => {
      month.forEach((group) => {
        group.forEach((player) => {
          if (!playerGroups[player._id]) {
            playerGroups[player._id] = new Set();
          }
          group.forEach((otherPlayer) => {
            if (player._id !== otherPlayer._id) {
              playerGroups[player._id].add(otherPlayer._id);
            }
          });
        });
      });
    });
    return playerGroups;
  }

  // Generate a new group
  function generateNewGroups(players, previousGroups) {
    const newMonthGroup = [];
    const allPlayers = [...players];

    while (allPlayers.length > 0) {
      const newGroup = [];
      let i = 0;

      // Try to form a group of 4 players
      while (newGroup.length < 4 && i < allPlayers.length) {
        const player = allPlayers[i];

        // Check if the player can join the group
        const canJoin = newGroup.every(
          (groupMember) => !previousGroups[player._id]?.has(groupMember._id)
        );

        if (canJoin) {
          newGroup.push(player);
          allPlayers.splice(i, 1); // Remove player from list
        } else {
          i++;
        }
      }

      // If not enough players for a full group, just add them to the group
      if (newGroup.length > 0) {
        newMonthGroup.push(newGroup);
      }
    }

    // After forming the groups, calculate the total handicap for each group
    const groupsWithHandicapTotals = newMonthGroup.map((group) => {
      const handicapTotal = group.reduce(
        (total, player) => total + player.personalDetails.handicap,
        0
      );
      return { group, handicapTotal };
    });

    // Sort groups based on handicap totals (lower totals first)
    groupsWithHandicapTotals.sort((a, b) => a.handicapTotal - b.handicapTotal);

    // Return only the sorted groups, without the handicap totals
    return groupsWithHandicapTotals.map((groupObj) => groupObj.group);
  }

  const error = useSelector((state) => state.errorReducer);
  useEffect(() => {
    if (error && error.id === "EVENT_FAIL") {
      //remve all Error: with regex
      const clearMessage = error.message.replace(/Error: /g, "");

      // on ok, refrech the page
      if (window.confirm(clearMessage)) {
        window.location.reload();
      }
    }
  }, [error]);

  useEffect(() => {
    const threeMonthGroupMember = [];
    let haveFutureEventFlight = false;
    if (events && Array.isArray(events)) {
      events.map((eventGroup) => {
        threeMonthGroupMember.push(eventGroup.flights);
      });

      for (const eventGroup of events) {
        // The eventGroupDate is greater than event date
        if (moment(eventGroup.date).isAfter(moment(event.date))) {
          haveFutureEventFlight = true;
          break;
        }
      }
    }
    setDataFlight(threeMonthGroupMember);

    if (event && users?.length) {
      if (event.flightStatus === "Submitted") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }

      // Get Selected Yes users
      const userIDs = event.users
        .filter(({ RSVP }) => RSVP === "Yes")
        .map(({ _id }) => _id);
      var userList = users
        .filter(({ _id }) => userIDs.includes(_id))
        .sort((a, b) => {
          return a.personalDetails?.name > b.personalDetails?.name ? 1 : -1;
        });
      let userListWithHandicap = [];
      let userListWithoutHandicap = [];

      userList.forEach((e) => {
        if (e.personalDetails?.handicap) {
          userListWithHandicap.push(e);
        } else {
          userListWithoutHandicap.push(e);
        }
      });

      // userList = [...userListWithHandicap, ...userListWithoutHandicap]
      const arr = [...userListWithHandicap, ...userListWithoutHandicap];
      setGuestList(userList);
      setDesplayList(userList);

      if (!event.flights.length) {
        // Get the previous groups data
        const previousGroups = getPreviousGroups(threeMonthGroupMember);
        // // Generate new groups considering the new players
        const newGroup = generateNewGroups(userList, previousGroups);

        setFlights(newGroup);
      } else {
        if (haveFutureEventFlight) {
          // this will auto regroup the flight without follow saved flight to prevent member grouped together
          const previousGroups = getPreviousGroups(threeMonthGroupMember);
          const newGroup = generateNewGroups(userList, previousGroups);
          setFlights(newGroup);
        } else {
          setFlights(event.flights);
        }
      }
    }
  }, [event, users, events]);

  useEffect(() => {
    const flatFlights = flights.flat();
    setDesplayList(
      guestList.filter((guest) => {
        if (flatFlights.length > 0) {
          const exists = flatFlights.find((e) => e._id == guest._id);
          return exists ? false : true;
        }
        return true;
      })
    );
  }, [flights]);

  const switchPosition = () => {
    if (dragValue2[0] === dragValue[0]) return;
    const person1 = isNaN(dragValue[0])
      ? guestList.find((guest) => guest._id === dragValue[0])
      : flights[dragValue[0]][dragValue[1]];
    const person2 = isNaN(dragValue2[0])
      ? guestList.find((guest) => guest._id === dragValue2[0])
      : flights[dragValue2[0]][dragValue2[1]];
    if (
      flights[dragValue2[0]]?.length < 4 ||
      isNaN(dragValue2[0]) ||
      isNaN(dragValue[0])
    ) {
      setArrangeDisabled(false);
      setFlights(
        flights.map((flight, flightIndex) => {
          if (flightIndex === dragValue[0]) {
            return flight.filter(
              (user, userIndex) => userIndex !== dragValue[1]
            );
          }
          if (flightIndex === dragValue2[0]) {
            return [...flight, person1];
          }
          return flight;
        })
      );
    } else {
      setArrangeDisabled(false);
      setFlights(
        flights.map((flight, flightIndex) => {
          if (flightIndex === dragValue[0]) {
            return flight.map((user, userIndex) => {
              if (userIndex === dragValue[1]) {
                return person2;
              } else {
                return user;
              }
            });
          }
          if (flightIndex === dragValue2[0]) {
            return flight.map((user, userIndex) => {
              if (userIndex === dragValue2[1]) {
                return person1;
              } else {
                return user;
              }
            });
          }
          return flight;
        })
      );
    }
  };

  const addFlight = () => {
    setFlights([...flights, []]);
  };

  const deleteFlight = (i) => {
    setFlights(flights.filter((e, index) => index !== i));
  };

  const openParticipantsModal = (i) => {
    setParticipantsModal(true);
    setFlight(i);
  };

  const addParticipant = (guest) => {
    setFlights(
      flights.map((flightDetails, flightIndex) => {
        if (flightIndex == flight) {
          return [...flightDetails, guest];
        }
        return flightDetails;
      })
    );
  };

  const removeParticipant = (guest, flight) => {
    setFlights(
      flights.map((flightDetails, flightIndex) => {
        if (flightIndex == flight) {
          return flightDetails.filter((e) => e._id !== guest._id);
        }
        return flightDetails;
      })
    );
  };

  const saveFlight = () => {
    const requestBody = {
      _id: event._id,
      flights,
      fetchTimestamp: event.fetchTimestamp,
    };

    dispatch(updateEvent(requestBody));
  };

  const resetFlight = () => {
    var confirm = window.confirm(
      "You will lose all your updates for this flight"
    );
    if (confirm) {
      const requestBody = {
        _id: event._id,
        flights: [],
        fetchTimestamp: event.fetchTimestamp,
      };
      dispatch(updateEvent(requestBody));
      setReset(reset + 1);
    }
  };

  const sendFlights = () => {
    var confirm = window.confirm(
      "Once you have submitted there can be no more changes"
    );
    if (confirm) {
      const requestBody = {
        _id: event._id,
        flights,
        flightStatus: "Sent",
        fetchTimestamp: event.fetchTimestamp,
      };
      dispatch(updateEvent(requestBody));
    }
  };

  const submitFlight = () => {
    var confirm = window.confirm(
      "Once you have submitted there can be no more changes"
    );
    if (confirm) {
      const requestBody = {
        _id: event._id,
        flights,
        flightStatus: "Submitted",
        fetchTimestamp: event.fetchTimestamp,
      };
      dispatch(updateEvent(requestBody));
      setDisabled(true);
    }
  };

  const rearrangeFlight = () => {
    // After forming the groups, calculate the total handicap for each group
    const groupsWithHandicapTotals = flights.map((group) => {
      const handicapTotal = group.reduce(
        (total, player) => total + player.personalDetails.handicap,
        0
      );
      return { group, handicapTotal };
    });

    // Sort groups based on handicap totals (lower totals first)
    groupsWithHandicapTotals.sort((a, b) => a.handicapTotal - b.handicapTotal);
    const newArrangeFlights = groupsWithHandicapTotals.map(
      (groupObj) => groupObj.group
    );

    setFlights(newArrangeFlights);
  };

  if (!event) return <div></div>;

  const eventMonth = new Date(event.date).getMonth() + 1;

  return (
    <Fragment>
      <ParticipantModal
        setParticipantsModal={setParticipantsModal}
        participantsModal={participantsModal}
        displayList={displayList}
        addParticipant={addParticipant}
        removeParticipant={removeParticipant}
      />

      <div className="flightWrapper">
        <div style={{ width: "300px" }}>
          <div className="guestListWrapper">
            <Card>
              <CardHeader>Participants</CardHeader>
              <CardBody>
                <ListGroup
                  className="guestList"
                  onDragOver={(e) => setDragValue2(["N/A"])}
                  onDragEnd={(e) => switchPosition()}
                >
                  {displayList.map((guest, j) => (
                    <ListGroupItem
                      key={`guestlist-${guest._id}`}
                      onDragStart={(e) => setDragValue([guest._id, j])}
                      onDragOver={(e) => setDragValue2(["N/A", j])}
                      onDragEnd={(e) => switchPosition()}
                      style={{
                        color:
                          guest?.status?.toLowerCase() == "active"
                            ? "green"
                            : "red",
                      }}
                      draggable
                    >
                      {guest.personalDetails?.name}
                    </ListGroupItem>
                  ))}
                </ListGroup>
                <Button
                  style={{ marginTop: "8px" }}
                  block
                  onClick={() => addFlight()}
                >
                  Add Flight
                </Button>
              </CardBody>
            </Card>
          </div>
        </div>
        <div>
          <div style={{ marginLeft: "16px", marginBottom: "16px" }}>
            <div><strong>* Important Note: </strong></div> 
            <div>If you manually move player to another
            flight, click 'flight order' to help you arrange the flight and please remember to 'save' it.</div>
          </div>
          <div className="flightArrangerWrapper">
            {flights.map((flight, i) => {
              let handicapTotal = 0;
              flight.map((u) => {
                handicapTotal += u.personalDetails.handicap;
              });

              return (
                <Col key={"flight" + i}>
                  <Card
                    style={{
                      width: "300px",
                      display: "inline-block",
                      marginBottom: "2rem",
                    }}
                  >
                    <CardHeader>
                      <span className="flightTitle">
                        Flight {i + 1} ({handicapTotal})
                        <span
                          className="deleteFlight"
                          onClick={() => deleteFlight(i)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                      </span>
                    </CardHeader>
                    <CardBody>
                      <ListGroup>
                        {flight.map((user, j) => {
                          return (
                            <ListGroupItem
                              onDragStart={(e) => setDragValue([i, j])}
                              onDragOver={(e) => setDragValue2([i, j])}
                              onDragEnd={(e) => switchPosition()}
                              draggable
                              key={`flightList-${user?._id}`}
                            >
                              <div className="flightUser">
                                <div>
                                  <strong
                                    style={{
                                      color:
                                        user?.status?.toLowerCase() == "active"
                                          ? "green"
                                          : "red",
                                    }}
                                  >
                                    {user?.personalDetails?.name}{" "}
                                  </strong>
                                  {user?.personalDetails?.handicap &&
                                    `(${user?.personalDetails?.handicap})`}
                                  <br></br>
                                  {user?.companyDetails &&
                                    user?.companyDetails.industry}
                                </div>
                                <span
                                  style={{ cursor: "pointer", color: "grey" }}
                                  onClick={() => removeParticipant(user, i)}
                                >
                                  <FontAwesomeIcon icon={faMinusCircle} />
                                </span>
                              </div>
                            </ListGroupItem>
                          );
                        })}
                        {flight.length < 4 && (
                          <ListGroupItem
                            style={{ textAlign: "center", cursor: "pointer" }}
                            onDragOver={(e) => setDragValue2([i, "new"])}
                            onDragEnd={(e) => switchPosition()}
                            onClick={() => {
                              openParticipantsModal(i);
                            }}
                          >
                            <FontAwesomeIcon icon={faUser} />
                          </ListGroupItem>
                        )}
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </div>
        </div>
      </div>
      <div className="saveFooter">
        <div className="footerStyle">
          <Button
            disabled={arrangeDisabled}
            color="warning"
            onClick={() => rearrangeFlight()}
          >
            Flight Order
          </Button>
          <Button
            disabled={disabled}
            style={{ width: "100px" }}
            color="danger"
            onClick={() => resetFlight()}
          >
            Reset
          </Button>{" "}
          <Button
            disabled={disabled}
            style={{ width: "100px" }}
            color="info"
            onClick={() => saveFlight()}
          >
            Save
          </Button>{" "}
          {event.flightStatus === "" && (
            <Button
              disabled={disabled}
              style={{ width: "100px" }}
              color="primary"
              onClick={() => sendFlights()}
            >
              Confirm
            </Button>
          )}
          {event.flightStatus === "Sent" && (
            <Button
              disabled={disabled}
              style={{ width: "100px" }}
              color="primary"
              onClick={() => submitFlight()}
            >
              Submit
            </Button>
          )}
          {event.flightStatus !== "" && (
            <Fragment>
              <FlightPDF
                flights={flights}
                eventMonth={eventMonth}
                saveFlight={saveFlight}
              />
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default FlightArranger;
