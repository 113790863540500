import { combineReducers } from 'redux';

//Import Reducers
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import userReducer from './userReducer'
import groupReducer from './groupReducer'
import invoiceReducer from './invoiceReducer'
import scoreReducer from './scoreReducer'
import eventReducer from './eventReducer'
import postReducer from './postReducer'
import tokenManagementReducer from '../../pages/TokenManagement/Tokens.redux'
import tabReducer from './tabReducer'
import notificationEmailReducer from './notificationEmailReducer';

const reducers = combineReducers({
    authReducer,
    errorReducer,
    userReducer,
    groupReducer,
    invoiceReducer,
    scoreReducer,
    eventReducer,
    postReducer,
    tokenManagementReducer,
    tabReducer,
    notificationEmailReducer,
});

export default reducers;
