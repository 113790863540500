import { SCORE_FAIL, GET_SCORES, ADD_SCORE, DELETE_SCORE, UPDATE_SCORE, SET_SCORE_UPDATED, GET_SCORE, SET_SCORE, GET_HOLE_IN_ONE_SCORES, SCORES_LOADING } from '../types';

function initialState() {
    return {
        message: null,
        score: null,
        scores: [],
        holeInOneScores: [],
        updated: false,
        success: null,
        loading: false,
    };
}

const scoreReducer = function (state = initialState(), action) {
    const { type, payload } = action
    switch (type) {
        case GET_SCORES:
            return {
                ...state,
                scores: payload,
                success: null,
                loading: false,
            }
        case SET_SCORE:
            return {
                ...state,
                score: payload,
                success: null,
            }
        case GET_HOLE_IN_ONE_SCORES:
            return {
                ...state,
                holeInOneScores: payload,
                success: null,
            }
        case GET_SCORE:
            return {
                ...state,
                score: payload,
                success: null,
            }
        case ADD_SCORE:
            return {
                ...state,
                scores: [...state.scores, payload],
                updated: true,
                success: null,
            }
        case DELETE_SCORE:
            return {
                ...state,
                scores: state.scores.filter(({ _id }) => _id !== payload),
                success: null,
            }
        case UPDATE_SCORE:
            return {
                ...state,
                scores: state.scores.map(score => {
                    if (score._id === payload._id) {
                        return payload
                    } else {
                        return score
                    }
                }),
                updated: true,
                success: null,
            }
        case SET_SCORE_UPDATED:
            return {
                ...state,
                updated: payload,
                success: null,
            }
        case SCORES_LOADING: {
            return {
                ...state,
                loading: true,
            }
        }
        case SCORE_FAIL:
            return {
                ...state,
                success: false,
                loading: false,
            }

        default:
            return {
                ...state,
                success: null,
            };
    }
};

export default scoreReducer;
