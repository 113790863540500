import { EVENT_FAIL, GET_EVENTS, ADD_EVENT, DELETE_EVENT, UPDATE_EVENT, SET_EVENT_UPDATED, GET_EVENT, SET_EVENT, SEND_EMAIL, UPDATE_RSVP, UPDATE_PAYMENT, UPDATE_EVENT_MEMBERS, SET_EVENT_MEMBERS_UPDATED, GET_EVENTS_HISTORY, UPDATE_EVENT_MEMBERS_LOADING } from '../types';

function initialState() {
    return {
        events: [],
        event: null,
        updated: false,
        success: null,
        membersUpdated: false,
        loading: false,
    };
}

const eventReducer = function (state = initialState(), action) {
    const { type, payload } = action
    switch (type) {
        case GET_EVENTS:
            return {
                ...state,
                events: payload,
            }
        case GET_EVENTS_HISTORY:
            return {
                ...state,
                events: payload,
            }
        case SET_EVENT:
            return {
                ...state,
                event: payload
            }
        case GET_EVENT:
            return {
                ...state,
                event: payload,
            }
        case ADD_EVENT:
            return {
                ...state,
                events: [...state.events, payload],
                updated: true,
            }
        case DELETE_EVENT:
            return {
                ...state,
                events: state.events.filter(({ _id }) => _id !== payload),
            }
        case UPDATE_RSVP: {
            return {
                ...state,
                event: payload,
                events: state.events.map(event => {
                    if (event._id === payload._id) {
                        return payload
                    } else {
                        return event
                    }
                }),
            }
        }

        case UPDATE_PAYMENT:
            return {
                ...state,
                event: payload,
            }

        case UPDATE_EVENT:
            return {
                ...state,
                event: payload,
                events: state.events.map(event => {
                    if (event._id === payload._id) {
                        return payload
                    } else {
                        return event
                    }
                }),
                updated: true,
            }
        case UPDATE_EVENT_MEMBERS:
            return {
                ...state,
                event: payload,
                events: state.events.map(event => {
                    if (event._id === payload._id) {
                        return payload
                    } else {
                        return event
                    }
                }),
                membersUpdated: true,
                loading: false
            }
        case UPDATE_EVENT_MEMBERS_LOADING:
            return {
                ...state,
                loading: true
            }
        case SEND_EMAIL:
            return {
                ...state,
                success: true
            }
        case SET_EVENT_UPDATED:
            return {
                ...state,
                updated: payload
            }
        case SET_EVENT_MEMBERS_UPDATED:
            return {
                ...state,
                membersUpdated: payload
            }
        case EVENT_FAIL:
        default:
            return {
                ...state,
                success: null,
                loading: false
            }
    }
};

export default eventReducer;
