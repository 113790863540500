import React, { Fragment, useState, useEffect, useRef } from 'react'
import axios from 'axios'
 
import { Modal, ModalHeader, ModalBody, Button, Alert } from 'reactstrap'
 
import QrReader from "react-qr-reader";
// import { tokenConfig } from '../../utils/apiConfig'; 

const QRscanner = props => {
    const [scannedData, setScannedData] = useState('') 
    const [modal, setModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    // const [counter, setCounter] = useState(0)
    const qrRef = useRef(null); 
    
    useEffect(() => {
        if(scannedData){
            // setErrorMessage("")
            setSuccessMessage("yey!", )
            // setCounter(counter+1)
            axios.patch('/api/registrations/qrscan',{_id: scannedData})
            // axios.patch('/api/registrations/qrscan',{_id: scannedData}, tokenConfig())
            .then(res => {
            props.updateStatus(scannedData)
            setSuccessMessage(res.data)
        })
        .catch(err => {
        setErrorMessage("error!!")})
        }
    
    },[scannedData])

  
 
    const toggle = () => {
        setModal(!modal)
    }


    return (
        <Fragment>
        <Button color='primary' onClick={toggle}>Scan Qr</Button>
        <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Qr Scanner</ModalHeader>
        <ModalBody>
            {errorMessage&&<Alert color='danger'>{errorMessage}</Alert>}
            {successMessage&&<Alert color='success'>{successMessage}</Alert>}
        <QrReader
            ref = {qrRef}
            onError={err => console.log(err)}
            onScan={data => setScannedData(data)}
            style={{ width: "100%" }}
        />

        </ModalBody>
        </Modal>
        
        </Fragment>
    )
}
 
export default QRscanner


// import React, { Component } from 'react'
// import QrReader from 'react-qr-reader'

// class QRscanner extends Component {
//   constructor(props){
//     super(props)
//     this.state = {
//       result: '',
//     }

//     this.handleScan = this.handleScan.bind(this)
//   }
//   handleScan(result){
//     // if(result){
//     //   this.setState({ result })
//     // }
//     this.setState({
//         result
//     })
//   }

//   handleError(err){
//     console.error(err)
//   }
//   render(){
//     const previewStyle = {
//       height: 240,
//       width: 320,
//     }

//     return(
//       <div>
//         <div>
//          <QrReader
//           delay={this.state.delay}
//           style={previewStyle}
//           onError={this.handleError}
//           onScan={this.handleScan}
//           />
//         </div>   
//         <br/> <br/> 
//         <br/> <br/>
//         <div> 
//           <p>{this.state.result}</p>  
//         </div>
        
//       </div>
//     )
//   }
// }

// export default QRscanner