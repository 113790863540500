import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import './GroupList.css'
import { getGroups } from '../../redux/actions/groupActions'
import { defaultGroup } from '../variables'
import GroupTable from './GroupTable/GroupTable'
import GroupModal from './GroupModal/GroupModal'
import EditMembersModal from './GroupModal/EditMembersModal';
import FloatingButton from '../QR/FloatingButton';
import filterGroups from '../../Assets/filterGroups.svg'

function GroupList() {
    const dispatch = useDispatch()
    const [group, setGroup] = useState(defaultGroup);
    const [addMode, setAddMode] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        dispatch(getGroups())
    }, []);

    // For managing Group Modal
    const [groupModal, setGroupModal] = useState(false);
    const openGroupModal = (group = defaultGroup, addMode = false) => {
        setGroupModal(!groupModal)
        setGroup(group)
        setAddMode(addMode)
    };

    const [editContent, setEditContent] = useState(true);
    const [editMembersModal, setEditMembersModal] = useState(false);
    const openEditMembersModal = (group = defaultGroup, addMode = false) => {
        setEditContent(true);
        setGroup(group);
        setAddMode(addMode);
        setEditMembersModal(!editMembersModal);
    };

    const onChange = e => {
        var object = { ...group }
        const { id, value, name } = e.target
        if (id) {
            // To create an object if it doesn't exist yet
            if (!object[id]) object[id] = {}
            object[id] = { ...object[id], [name]: value }
        } else {
            object[name] = value
        }
        setGroup(object)
    };

    return (
        <Fragment>
        <div className='totalContainer'>
            <div className='topGroupActionsContainer'>
                <Button className='addGroupButton' color= 'primary' onClick={() => openGroupModal(defaultGroup, true)}>Add Group</Button>
                <label className='filterLabel'><img src={filterGroups}></img>
                    <input
                        className='filterInput'
                        placeholder='Filter Group'
                        value={filter}
                        onChange={e => setFilter(e.target.value)}>
                    </input>
                </label>
            </div> 
            <GroupModal
                openGroupModal={openGroupModal}
                openEditMembersModal={openEditMembersModal}
                groupModal={groupModal}
                group={group}
                onChange={onChange}
                addMode={addMode}
            />
            <EditMembersModal
                openEditMembersModal ={openEditMembersModal}
                editMembersModal={editMembersModal}
                editContent={editContent}
                setEditContent={setEditContent}
                group={group}
                addMode={addMode}
                openGroupModal={openGroupModal}
            />
            <GroupTable
                openGroupModal={openGroupModal}
                openEditMembersModal={openEditMembersModal}
                filter={filter}
                setFilter={setFilter}
            />
            <FloatingButton/>
        </div>
        </Fragment>
    );
}

export default GroupList;