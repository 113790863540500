import axios from 'axios';

import {
    AUTH_ERROR,
    GET_TOKEN_TAB,
    UPDATE_TOKEN_TAB
} from '../types';
import { tokenConfig } from './authActions';

import { baseURL } from '../helpers';
import { returnErrors } from './errorActions';


// Validate token and load user
export const loadTokenTab = () => (dispatch, getState) => {
    axios
        .get(`${baseURL}/tabs/`, tokenConfig(getState))
        .then((res) =>
            dispatch({
                type: GET_TOKEN_TAB,
                payload: res.data,
            })
        )
        .catch((err) => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, AUTH_ERROR));
            dispatch({
                type: AUTH_ERROR,
            });
        });
};

export const updateTokenTab = requestBody => (dispatch, getState) => {
    axios
        .post(`${baseURL}/tabs/updateTokenTab`, requestBody, tokenConfig(getState))
        .then((res) =>
            dispatch({
                type: UPDATE_TOKEN_TAB,
                payload: res.data,
            })
        )
        .catch((err) => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, AUTH_ERROR));
            dispatch({
                type: AUTH_ERROR,
            });
        });
};

