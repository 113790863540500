import { INVOICE_FAIL, GET_INVOICES, ADD_INVOICE, DELETE_INVOICE, UPDATE_INVOICE, SET_INVOICE_UPDATED, GET_INVOICE, SET_INVOICE, EMAIL_INVOICE } from '../types';

function initialState() {
    return {
        message: null,
        invoice: null,
        invoices: [],
        updated: false,
        success: null,
       
    };
}

const invoiceReducer = function (state = initialState(), action) {
    const { type, payload } = action
    switch (type) {
        case GET_INVOICES:
            return {
                ...state,
                invoices: payload,
                success: null,
            }
        case SET_INVOICE:
            return {
                ...state,
                invoice: payload,
                success: null,
            }
        case GET_INVOICE:
            return {
                ...state,
                invoice: payload,
                success: null,
            }
        case ADD_INVOICE:
            return {
                ...state,
                invoices: [...state.invoices, payload],
                updated: true,
                success: null,
            }
        case DELETE_INVOICE:
            return {
                ...state,
                invoices: state.invoices.filter(({ _id }) => _id !== payload),
                success: null,
            }
        case UPDATE_INVOICE:
            return {
                ...state,
                invoices: state.invoices.map(invoice => {
                    if (invoice._id === payload._id) {
                        return payload
                    } else {
                        return invoice
                    }
                }),
                updated: true,
                success: null,
            }
        case SET_INVOICE_UPDATED:
            return {
                ...state,
                updated: payload,
                success: null,
            }
        case EMAIL_INVOICE: {
            return {
                ...state,
                success: payload.success,
                invoices: state.invoices.map(invoice => {
                    if (invoice?._id === payload.invoice?._id) {
                        return payload.invoice
                    } else {
                        return invoice
                    }
                }),
            }
        }
        case INVOICE_FAIL:
            return {
                ...state,
                success: false
            }

        default:
            return {
                ...state,
                success: null,
            };
    }
};

export default invoiceReducer;
