import React, { Fragment, useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux'
import { defaultUser } from '../variables'
import { personalDetailsInputs, addressInputs, companyDetailsInput, socialMediaInputs, accountInputs } from '../variables'
import '../Profile/Profile.css'
// Get redux actions
import { getUser, updateUser, setUpdated, uploadFile, uploadCompanyFile } from '../../redux/actions/userActions'
import { getGroupList } from '../../redux/actions/groupActions'
// Import components
import InputDetails from './InputDetails/InputDetails'
import ProfilePhoto from './ProfilePhoto/ProfilePhoto'
import InvoiceList from './InvoiceList/InvoiceList'
import VisitorView from './VisitorView/VisitorView'
import CompanyLogoPlaceholder from '../../Assets/CompanyLogoPlaceholder.svg'
import FloatingButton from '../QR/FloatingButton';
import profilePlaceholder from '../../Assets/placeholder.png';
import InvoiceListNew from './InvoiceList/InvoiceListNew';

const Profile = (props) => {
    const defaultTab = new URLSearchParams(props.location.search).get("tab")
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(defaultTab ? defaultTab : '6');
    const authReducer = useSelector(state => state.authReducer)
    const { user, updated } = useSelector(state => state.userReducer)
    const { groups } = useSelector(state => state.groupReducer)
    const isAdmin = authReducer.user && authReducer.user.user.permissionLevel && authReducer.user.user.permissionLevel === 'Admin'
    const [userDetails, setuserDetails] = useState(defaultUser)
    const [isVisitor, setIsVisitor] = useState(false)
    const [imageSrc, setImageSrc] = useState(null)
    const [companyLogo, setCompanyLogo] = useState(CompanyLogoPlaceholder)
    const isFlightArranger =
        authReducer.user &&
        authReducer.user.user.permissionLevel &&
        authReducer.user.user.permissionLevel === "Flight Arranger";

    // To remove updated Alert after 3 seconds
    useEffect(() => {
        if (updated) {
            setTimeout(() => {
                dispatch(setUpdated(false))
            }, 3000)
        }
    }, [updated])

    // To get Employee details once authReducer.user loaded
    useEffect(() => {
        if (authReducer.user && authReducer.user.user.id) {
            // Get Employee using authReducer.user_id
            if (props.match.params.id) {
                dispatch(getUser(props.match.params.id))
                setIsVisitor(true)
            } else {
                dispatch(getUser(authReducer.user.user.id))
            }
        }
    }, [authReducer.user])

    // To update state with user details
    useEffect(() => {
        if (user) {
            setuserDetails(user);
            if (user.photo) {
                const thePhotoType = user.photo.substring(0, 4);
                if (thePhotoType == 'http' || thePhotoType == 'data') {
                    setImageSrc(user.photo);
                }
            };
            if (user.companyDetails && user.companyDetails.photo) {
                setCompanyLogo(user.companyDetails.photo)
            };
        }
    }, [user]);

    useEffect(() => {
        dispatch(getGroupList())
    }, [])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const onChange = e => {
        var object = { ...userDetails }
        const { id, value, name } = e.target
        if (id) {
            // To create an object if it doesn't exist yet
            if (!object[id]) object[id] = {}
            if (!object[id][name]) object[id][name] = ''
            object[id] = { ...object[id], [name]: value }
        } else {
            object[name] = value
        }
        setuserDetails(object)
    }

    const onSubmit = e => {
        e.preventDefault()
        var requestBody = userDetails
        console.log(requestBody)
        dispatch(updateUser(requestBody))
    }

    if (isVisitor && !isAdmin && !isFlightArranger) return <VisitorView
        uploadFile={uploadFile}
        userDetails={userDetails}
        imageSrc={imageSrc}
        setImageSrc={setImageSrc}
        isVisitor={isVisitor}
        companyLogo={companyLogo}
        setCompanyLogo={setCompanyLogo}
        isAdmin={isAdmin}
        onChange={onChange}
    />

    return (
        <div>
            <Nav tabs fill>
                {/* visitor does not have access to tab 1 - account */}
                {(!isVisitor || isAdmin) && <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        Account
                    </NavLink>
                </NavItem>}
                {(!isVisitor || isAdmin || isFlightArranger) && <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        Personal
                    </NavLink>
                </NavItem>}
                {(!isVisitor || isAdmin) && <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { toggle('3'); }}
                    >
                        Company
                    </NavLink>
                </NavItem>}
                {(!isVisitor || isAdmin) && <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '4' })}
                        onClick={() => { toggle('4'); }}
                    >
                        Social Media
                    </NavLink>
                </NavItem>}
                {/* visitor does not have access to tab 5 - invoices */}
                {(!isVisitor || isAdmin) && <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '5' })}
                        onClick={() => { toggle('5'); }}
                    >
                        Invoices
                    </NavLink>
                </NavItem>}

                {<NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '6' })}
                        onClick={() => { toggle('6'); }}
                    >
                        Profile Preview
                    </NavLink>

                </NavItem>}
            </Nav>

            <Form onSubmit={onSubmit}>
                <TabContent activeTab={activeTab}>
                    {(!isVisitor || isAdmin) && < TabPane tabId="1">
                        <InputDetails
                            userDetails={userDetails}
                            onChange={onChange}
                            isAdmin={isAdmin}
                            isVisitor={isVisitor}
                            fields={accountInputs}
                            tab="account"
                            groups={groups}
                            shouldShowGroup={true}
                        />
                    </TabPane>}
                    {/*'Personal' tab */}

                    <TabPane tabId="2">
                    <div className='mobileTitle'>Profile Picture</div>
                        <ProfilePhoto
                            uploadFile={uploadFile}
                            userDetails={userDetails}
                            imageSrc={imageSrc}
                            setImageSrc={setImageSrc}
                            isVisitor={isVisitor}
                            isAdmin={isAdmin}
                            type='profilePhoto'
                            fields={personalDetailsInputs}
                            onChange={onChange}
                            tab="personal"
                        />
                        <InputDetails
                            userDetails={userDetails}
                            onChange={onChange}
                            isAdmin={isAdmin}
                            isFlightArranger={isFlightArranger}
                            isVisitor={isVisitor}
                            fields={personalDetailsInputs}
                            tab="personal"
                        />
                        <br ></br>
                        <br ></br>
                    </TabPane>
                    <TabPane tabId="3" > {/* Company tab*/}
                        <div className='mobileTitle'>Company Information</div>
                        <ProfilePhoto
                            uploadFile={uploadCompanyFile}
                            userDetails={userDetails}
                            imageSrc={companyLogo}
                            setImageSrc={setCompanyLogo}
                            isVisitor={isVisitor}
                            isAdmin={isAdmin}
                            type='companyLogo'
                            fields={companyDetailsInput}
                            onChange={onChange}
                        />
                        <InputDetails
                            userDetails={userDetails}
                            onChange={onChange}
                            isAdmin={isAdmin}
                            isVisitor={isVisitor}
                            fields={companyDetailsInput}
                            tab="company"
                        />
                        <br ></br>
                        <h4 className='horizontal-border'><b>Company Address</b></h4>
                        <InputDetails
                            userDetails={userDetails}
                            onChange={onChange}
                            isAdmin={isAdmin}
                            isVisitor={isVisitor}
                            fields={addressInputs}
                        />
                        <br ></br>
                        <br ></br>
                    </TabPane>
                    {/* Social media tab */}
                    <TabPane tabId="4">
                        <InputDetails
                            userDetails={userDetails}
                            onChange={onChange}
                            isAdmin={isAdmin}
                            isVisitor={isVisitor}
                            fields={socialMediaInputs}
                            tab="social"
                        />
                        <br ></br>
                        <br ></br>
                    </TabPane>

                    {/*invoices tab*/}
                    {(!isVisitor || isAdmin) && <TabPane tabId="5">
                        <InvoiceListNew user={user}/>
                        <br ></br>
                        <br ></br>
                    </TabPane>}

                    {/* profile preview tab */}
                    <TabPane tabId="6" >
                        <VisitorView
                            user={user}
                            uploadFile={uploadFile}
                            userDetails={userDetails}
                            imageSrc={imageSrc}
                            setImageSrc={setImageSrc}
                            isVisitor={isVisitor}
                            companyLogo={companyLogo}
                            setCompanyLogo={setCompanyLogo}
                            isAdmin={isAdmin}
                            onChange={onChange}
                        />
                    </TabPane>

                </TabContent>
                <FloatingButton />

                <br></br>
                {(!isVisitor || isAdmin || isFlightArranger) &&activeTab!=='6' &&  <div className="form-btn">

                    <Button className='saveButton' type='submit' color='primary'>
                        Save
                    </Button>

                    <Button className='mobileSaveButton' type='submit' color='primary'>
                        Save
                    </Button>
                </div>}
            </Form>
        </div >
    );
}

export default Profile;