import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGroup, updateGroup, getGroupList } from '../../../redux/actions/groupActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit, faInfoCircle, faKey, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import './GroupTable.css'
import MembersModal from './MembersModal';
import { Button } from 'reactstrap';
import profilePlaceholder from '../../../Assets/placeholder.png';
import calendar from '../../../Assets/calendar.svg';

function GroupTable({ openGroupModal, openEditMembersModal, filter, setFilter }) {
    const dispatch = useDispatch()
    const { groups } = useSelector(state => state.groupReducer)
    const [windowHeight, setWindowHeight] = useState(`${window.innerHeight - 155}px`)
    // const isMobile = window.innerWidth < 426 ? true : false
    const [groupDetails, setGroupDetails] = useState()
    const [filteredGroups, setFilteredGroups] = useState([])
    const [showStatuesDropdown, setShowStatuesDropdown] = useState(false)
    const [visableGroupAction, setVisableGroupAction] = useState()
    const [otherStatus, setOtherStatus] = useState()

    //Used to adjust view based on screen size
    useEffect(() => {
        function handleResize() {
            setWindowHeight(`${window.innerHeight - 155}px`)
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onDelete = (id) => {
        var confirm = window.confirm('Permanently delete this Group?')
        if (confirm) dispatch(deleteGroup(id))
    }

    useEffect(() => {
        if (groups) {
            if (filter !== '') {
                const array = groups.filter(g => g.name.toLowerCase().includes(filter.toLowerCase()));
                setFilteredGroups(array.sort((a, b) => a.status > b.status ? 1 : -1));
            } else {
                const array = groups.sort((a, b) => a.status > b.status ? 1 : -1);
                setFilteredGroups(array);
            }
        } else {
            dispatch(getGroupList())
        }
    }, [filter, groups])

    const [MembersModalVisible, setMembersModalVisible] = useState(false);
    function openMemberModal(group) {
        if (window.innerWidth <= 425) {
            setMembersModalVisible(!MembersModalVisible)
            setGroupDetails(group)
        }
    }

    function aDateString(d) {
        var correctDate = new Date(d);
        var day = ("0" + (correctDate.getDate())).slice(-2);
        var month = ("0" + (correctDate.getMonth())).slice(-2);
        var year = correctDate.getFullYear();
        var str = `${year}-${month}-${day}`
        return str
    }

    function handleStatusDropdown(group) {
        setShowStatuesDropdown(!showStatuesDropdown)
        setVisableGroupAction(group._id)
        if (group.status == 'Active') {
            setOtherStatus('Inactive')
        } else {
            setOtherStatus('Active')
        }
    }

    function changeGroupStatus(group) {
        setShowStatuesDropdown(false)
        var updatedGroup = group
        updatedGroup.status = otherStatus
        dispatch(updateGroup(updatedGroup))
    }

    function renderActionBtn(group) {
        return (
            <Fragment>
                <span style={{ position: 'relative', cursor: 'pointer' }} className={`label visableOnPc membershipStatus ${group.status}`} onClick={() => handleStatusDropdown(group)}>
                    {group.status}&#160; &#9660;
                    {showStatuesDropdown && visableGroupAction == group._id ?
                        <span className='statusDropdown' onClick={() => changeGroupStatus(group)}>{otherStatus}</span>
                        : null}
                </span>
                <span className='label visableOnPc membershipStatus'>{aDateString(group.createdAt)}
                    <img style={{ margin: '-5px 0 0 5px' }} src={calendar}></img>
                </span>
                <div className="web-action-button">
                    {/* on web view this only shows when hover  */}
                    <React.Fragment>
                        <span className="buttons-divider" />
                        <span className="action-btn" onClick={() => openGroupModal(group)}>
                            <FontAwesomeIcon icon={faEdit} color="#3C3C3C" /> Edit Group
                        </span>
                        <span className="action-btn" onClick={() => openEditMembersModal(group)}>
                            <FontAwesomeIcon icon={faUserPlus} color="#3C3C3C" /> Edit Members
                        </span>
                        <span className="buttons-divider" />
                        <span className="action-btn text-red" onClick={() => onDelete(group._id)}>
                            <FontAwesomeIcon icon={faTrash} /> Delete Group
                        </span>
                    </React.Fragment>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div id="groupList">
                {!filteredGroups || filteredGroups.length < 1 ? <p>No Groups</p> :
                    filteredGroups.map(group => {
                        return (
                            <div className="row-wrapper" key={group.id}>
                                <div className="row">
                                    <div className="col" style={{ maxWidth: 800 }}>
                                        <div className='media-row'>
                                            <img className="profile-avatar mr-3" src={group.photo ? group.photo : profilePlaceholder} />
                                            <div className="media">
                                                <div className="media-body">
                                                    <div style={{ fontWeight: 'bold' }} >{group.name}</div>
                                                </div>
                                                <div className="media-body">
                                                    <img style={{ width: '30px' }} src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${group.country}.svg`}></img>
                                                    <span className='members-container'>● {group.members?.length} Members</span>
                                                </div>
                                                <div className="mobile-only-body">
                                                    <span className={`label visableOnMobile membershipStatus ${group.status}`}>{group.status}</span>
                                                    <span className='label visableOnMobile membershipStatus'>{aDateString(group.createdAt)}
                                                        <img style={{ margin: '-5px 0 0 5px' }} src={calendar}></img>
                                                    </span>
                                                </div>
                                                <div className='group-desc visableOnPc'>{group.description}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col action-web">
                                        {renderActionBtn(group)}
                                    </div>
                                    <div className="visableOnMobile">
                                        <div className='group-desc'>{group.description}</div>
                                        <br />
                                        <Button onClick={() => openGroupModal(group)}>Edit Group</Button>{' '}
                                        <Button onClick={() => openEditMembersModal(group)}>Edit Members</Button>
                                    </div>
                                </div>
                                <div className="row action-tablet">
                                    {renderActionBtn(group)}
                                </div>
                                <hr />
                            </div>
                        )
                    })}
            </div>
            {MembersModalVisible ? <MembersModal setMembersModalVisible={setMembersModalVisible} MembersModalVisible={MembersModalVisible} groupDetails={groupDetails} /> : null}
        </Fragment >
    );
}

export default GroupTable;