import { GROUP_FAIL, GET_GROUPS, ADD_GROUP, DELETE_GROUP, UPDATE_GROUP, SET_GROUP_UPDATED, GET_GROUP, SET_GROUP, SET_GROUP_PHOTO } from '../types';

function initialState() {
    return {
        groups: [],
        group: null,
        updated: false,
        photo: null,
    };
}

const groupReducer = function (state = initialState(), action) {
    const { type, payload } = action
    switch (type) {
        case GET_GROUPS:
            return {
                ...state,
                groups: payload,
            }
        case SET_GROUP_PHOTO:
            return {
                ...state,
                photo: payload,
            }
        case SET_GROUP:
            return {
                ...state,
                group: payload
            }
        case GET_GROUP:
            return {
                ...state,
                group: payload,
            }
        case ADD_GROUP:
            return {
                ...state,
                groups: [...state.groups, payload],
                updated: true,
            }
        case DELETE_GROUP:
            return {
                ...state,
                groups: state.groups.filter(({ _id }) => _id !== payload),
            }
        case UPDATE_GROUP:
            return {
                ...state,
                group: payload,
                groups: state.groups.map(group => {
                    if (group._id === payload._id) {
                        return payload
                    } else {
                        return group
                    }
                }),
                updated: true,
            }
        case SET_GROUP_UPDATED:
            return {
                ...state,
                updated: payload
            }
        case GROUP_FAIL:
        default:
            return {
                ...state,
            }
    }
};

export default groupReducer;
