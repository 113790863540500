import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Label, Input, FormGroup, Button } from 'reactstrap';
import { updateGroup, setUpdated, addGroup, uploadFile, getPhoto } from '../../../redux/actions/groupActions'
import { groupInputs } from '../../variables'
import profilePlaceholder from '../../../Assets/placeholder.png';
import Compress from '../../ImageCompression/imageCompression';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

function GroupEdit({ group, onChange, addMode }) {
  const dispatch = useDispatch()

  const { message, id } = useSelector(state => state.errorReducer)
  const { updated, photo } = useSelector(state => state.groupReducer)
  const authReducer = useSelector(state => state.authReducer)
  const [imageSrc, setImageSrc] = useState(profilePlaceholder);
  const [tempFile, setTempFile] = useState();
  const [uploadButton, setUploadButton] = useState(true);
  const [profileDisplay, setProfileDisplay] = useState();
  const [uploadButtonText, setUploadButtonText] = useState('Set Group Photo');

  useEffect(() => {
    if (updated) {
      dispatch(setUpdated(false))
    }
  }, [updated])

  useEffect(() => {
    if (group) {
      if (group.photo) {
        setImageSrc(group.photo)
      }
    }
  }, [group])

  const uploadAnswer = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', group.name);
    data.append('_id', group._id);
    data.append('image', tempFile);
    dispatch(uploadFile(data))
    setUploadButton(false)
    setImageSrc(profileDisplay)
    setUploadButtonText('Photo Set Successfully')
  };

  // console.log('group', group, 'temp', tempFile)
  // if (!group) return null
  return (
    <Fragment>
      {!addMode && <>
      <div style={{marginBottom: '25px'}}>
        <Label>Group Photo</Label>
        <Compress
          imageSrc={imageSrc}
          id={'photo'}
          setTempFile={setTempFile}
          setUploadButton={setUploadButton}
          profileDisplay={profileDisplay}
          setProfileDisplay={setProfileDisplay}
        />
        <FontAwesomeIcon style={{position: 'absolute', left: '180px', top: '125px'}} icon={faPencilAlt} color="#3B3B3B" />
        {tempFile && <Button color='primary' onClick={e => uploadAnswer(e)}>{uploadButtonText}</Button>}
      </div></>}
      {id === 'GROUP_FAIL' && < Alert color='danger'>{typeof message === 'object' ? message.message : message}</Alert>}
      {groupInputs.map(({ title, type, name, id = null, required = false, options = [] }) => {
        if (addMode && !required) return null
        return <FormGroup>
          <Label>{title}</Label>
          <Input
            type={type}
            value={id && group[id] ? group[id][name] : group[name]}
            name={name}
            id={id}
            required={required}
            onChange={onChange}
          >
            {options.map((e, i) => <option key={e.value + i} value={e.value}>{e.name}</option>)}
          </Input>
        </FormGroup>
      })}
    </Fragment >
  );
}

export default GroupEdit;