import React from 'react';
import './row.css';

const ExcelRow = (props) => {

    return (
        <tr>
            <td className="contentBold">
                <span className="row-text04">{`F-${props.flight.team < 9 ? `0${props.flight.team + 1}` : props.flight.team + 1}`}</span>
            </td>
            <td className="contentBold "></td>
            <td>{props.flight.personalDetails.name}</td>
            <td>
                {
                    (new Date(props.flight.personalDetails.birthdate).getMonth() + 1) == props.eventMonth ?
                        <span>Birthday</span>
                        : <span>&nbsp;</span>
                }
            </td>
            <td> {props.flight.personalDetails.handicap}</td>
            <td></td>
        </tr>
    );
};

ExcelRow.defaultProps = {}

ExcelRow.propTypes = {}

export default ExcelRow;
