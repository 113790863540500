// Get list of Nationalities
import countryList from '../pages/Profile/countryList.json'
import {
    faLinkedin,
    faFacebook,
    faTwitter,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";



export const defaultUser = {
    email: '',
    contactNumber: '',
    permissionLevel: 'User',
    status: 'Active',
    membershipDetails: {
        joinDate: '',
        renewalDate: '',
        expiryDate: '',
        yearlyFee: '',
        country: 'Malaysia',
        group: '',
    },
    personalDetails: {
        name: '',
        displayName: '',
        title: '',
        gender: '',
        ic_passport: '',
        nationality: '',
        birthdate: '',
        handicap: '',
        line1: '',
        line2: '',
        postcode: '',
        city: '',
        state: '',
        country: '',

    },
    companyDetails: {
        name: '',
        designation: '',
        industry: '',
        website: '',
        description: '',
        jobTitle: '',
        phone: '',
        directLine: '',
        fax: '',
    },
    socialMedia: {
        facebook: '',
        instagram: '',
        twitter: '',
        linkedIn: '',
        companyLinkedIn: '',
    }
}

export const modalInputs = [
    {
        title: 'Member ID',
        type: 'text',
        name: 'member_id',
        required: true,
    },
    {
        title: 'Full Name',
        type: 'text',
        name: 'name',
        id: 'personalDetails',
        required: true,
    },
    {
        title: 'Title',
        type: 'text',
        name: 'title',
        id: 'personalDetails',
    },
    {
        title: 'Company Name',
        type: 'text',
        name: 'name',
        id: 'companyDetails',
    },
    {
        title: 'Email',
        type: 'email',
        name: 'email',
        required: true,
    },
    {
        title: 'Industry',
        type: 'text',
        name: 'industry',
        id: 'companyDetails',
    },
    {
        title: 'Website',
        type: 'text',
        name: 'website',
        id: 'companyDetails',
    },
    {
        title: 'Job Title',
        type: 'text',
        name: 'jobTitle',
        id: 'companyDetails',
    },
    {
        title: 'PermissionLevel',
        type: 'select',
        name: 'permissionLevel',
        required: true,
        options: [{ name: 'User', value: 'User' }, { name: 'Admin  ', value: 'Admin' }, {name: 'Flight Arranger', value: 'Flight Arranger'}]
    },
    {
        title: 'Status',
        type: 'select',
        name: 'status',
        required: true,
        options: [
            { name: 'Active', value: 'active' },
            { name: 'New', value: 'new' },
            { name: 'Exempted', value: 'exempted' },
            { name: 'Renewal', value: 'renewal' },
            { name: 'Expired', value: 'Expired' },
        ]
    },
]

export const accountInputs = [
    {
        title: 'Member ID',
        type: 'text',
        name: 'member_id',
        required: true,
        disabled: true
    },
    {
        title: 'Account Status',
        type: 'text',
        name: 'status',
        required: true,
        disabled: true
    },
    {
        title: 'Joined',
        type: 'date',
        name: 'joinDate',
        id: 'membershipDetails',
        disabled: true
    },
    {
        title: 'Renewal',
        type: 'date',
        name: 'renewalDate',
        id: 'membershipDetails',
        disabled: true
    },
    {
        title: 'Expiry',
        type: 'date',
        name: 'expiryDate',
        id: 'membershipDetails',
        disabled: true
    },
    {
        title: 'Yearly Fee (RM)',
        min: 0,
        step: '0.01',
        type: 'number',
        name: 'yearlyFee',
        id: 'membershipDetails',
        disabled: true
    },
    {
        title: 'Notes',
        type: 'textarea',
        name: 'notes',
        id: 'membershipDetails',
        visibleToAdmin: true
    },
]

export const personalDetailsInputs = [
    {
        title: 'Title',
        type: 'text',
        name: 'title',
        id: 'personalDetails',
    },    
    {
        title: 'Display Name',
        type: 'text',
        name: 'displayName',
        id: 'personalDetails',
    },
    {
        title: 'Full Name',
        type: 'text',
        name: 'name',
        id: 'personalDetails',
     
    },
    {
        title: 'Gender',
        type: 'select',
        name: 'gender',
        id: 'personalDetails',
        // required: true,
        options: [{ value: '', name: '' }, { value: 'male', name: 'Male' }, { value: 'female', name: 'Female' }]
    },

    {
        title: 'Email',
        type: 'email',
        name: 'email',
    },
 
    {  
        title: 'Contact Number',
        type: 'text',
        name: 'contactNumber',
    },
    {
        title: 'Birth Date',
        type: 'date',
        name: 'birthdate',
        id: 'personalDetails',
        hidden: true,
    },
    {
        title: 'IC/Passport Number',
        type: 'text',
        name: 'ic_passport',
        id: 'personalDetails',
        hidden: true,
        disabled: true
    },
    {
        title: 'Nationality',
        type: 'select',
        name: 'nationality',
        id: 'personalDetails',
        defaultValue:'Malaysian',
        options: countryList.map(e => {
            return {
                value: e.nationality,
                name: e.nationality,
            }
        }),
        hidden: true,
    },
    {
        title: 'Golf Handicap',
        type: 'number',
        min: 0,
        max: 54,
        step: 1,
        name: 'handicap',
        id: 'personalDetails',
        hidden: true,
        disabled: true
    },
]

export const addressInputs = [
    {
        title: 'Address line 1',
        type: 'text',
        name: 'line1',
        id: 'personalDetails',
        // hidden: true,
    },
    {
        title: 'Address line 2',
        type: 'text',
        name: 'line2',
        id: 'personalDetails',
        // hidden: true,
    },
    {
        title: 'Postal Code',
        type: 'text',
        name: 'postcode',
        id: 'personalDetails',
        // hidden: true,
    },
    {
        title: 'City',
        type: 'text',
        name: 'city',
        id: 'personalDetails',
        // hidden: true,
    },
    {
        title: 'State',
        type: 'text',
        name: 'state',
        id: 'personalDetails',
        // hidden: true,
    },
    {
        title: 'Country',
        type: 'text',
        name: 'country',
        id: 'personalDetails',
        // hidden: true,
    },
]

export const companyDetailsInput = [
    {
        title: 'Company Name',
        type: 'text',
        name: 'name',
        id: 'companyDetails',
        disabled: true,
        mobileSequence: 1
    },
    {
        title: 'Industry',
        type: 'text',
        name: 'industry',
        id: 'companyDetails',
        disabled: true,
        mobileSequence: 2
    },
    {
        title: 'Job Title',
        type: 'text',
        name: 'jobTitle',
        id: 'companyDetails',
        mobileSequence: 3
    },
    {
        title: 'Office Phone',
        type: 'text',
        name: 'phone',
        id: 'companyDetails',
        mobileSequence: 6
    },
    {
        title: 'Fax',
        type: 'text',
        name: 'fax',
        id: 'companyDetails',
        mobileSequence: 7
    },
    {
        title: 'Description',
        type: 'textarea',
        name: 'description',
        id: 'companyDetails',
        mobileSequence: 4
    },
    {
        title: 'Direct Line',
        type: 'text',
        name: 'directLine',
        id: 'companyDetails',
        mobileSequence: 5
    },
    {
        title: 'Website',
        type: 'text',
        name: 'website',
        id: 'companyDetails',
        mobileSequence: 8
    }
]

export const socialMediaInputs = [
    

    {   icon: faLinkedin,
        title: 'LinkedIn',
        type: 'text',
        name: 'linkedIn',
        id: 'socialMedia',
        fieldType: 'personal'
    },

    {
        icon: faTwitter,
        title: 'Twitter',
        type: 'text',
        name: 'twitter',
        id: 'socialMedia',
        fieldType: 'personal'
    }, 
    {   
        icon:faFacebook,
        title: 'Facebook',
        type: 'text',
        name: 'facebook',
        id: 'socialMedia',
        fieldType: 'personal'
    }, 
    {
        icon: faInstagram,
        title: 'Instagram',
        type: 'text',
        name: 'instagram',
        id: 'socialMedia',
        fieldType: 'personal'
    },
    {   icon: faLinkedin,
        title: 'LinkedIn',
        type: 'text',
        name: 'companyLinkedIn',
        id: 'socialMedia',
        fieldType: 'company'
    },

    {
        icon: faTwitter,
        title: 'Twitter',
        type: 'text',
        name: 'companyTwitter',
        id: 'socialMedia',
        fieldType: 'company'
    }, 
    {   
        icon:faFacebook,
        title: 'Facebook',
        type: 'text',
        name: 'companyFacebook',
        id: 'socialMedia',
        fieldType: 'company'
    }, 
    {
        icon: faInstagram,
        title: 'Instagram',
        type: 'text',
        name: 'companyInstagram',
        id: 'socialMedia',
        fieldType: 'company'
    },
]


export const defaultEvent = {
    name: '',
    description: '',
    date: '',
    time: '',
    location: '',
    eventType: 'Golf Event',
    bocEvent: false,
    facebookURL: '',
    youtubeURL: '',
    status: 'Inactive',
    showScoresAt: null
}

export const eventInputs = [
    {
        title: 'Name',
        type: 'text',
        name: 'name',
        required: true,
    },
    {
        title: 'Schedule',
        type: 'textarea',
        name: 'description',
        required: true,
    },
    {
        title: 'Additional Details',
        type: 'textarea',
        name: 'additionalDetails',
        required: true,
    },
    {
        title: 'Date',
        type: 'date',
        name: 'date',
        required: true,
    },
    {
        title: 'Time',
        type: 'time',
        name: 'time',
        required: true,
    },
    {
        title: 'Location Name',
        type: 'text',
        name: 'location',
        required: true,
    },
    {
        title: 'Location (Link)',
        type: 'text',
        name: 'locationURL',
        required: true,
    },
    {
        title: 'Facebook (Link)',
        type: 'text',
        name: 'facebookURL',
    },
    {
        title: 'YouTube (Link)',
        type: 'text',
        name: 'youtubeURL',
    },
    {
        title: 'Status',
        type: 'select',
        name: 'status',
        options: [{ value: 'Inactive', name: 'Inactive' }, { value: 'Active', name: 'Active' }, { value: 'Closed', name: 'Closed' }],
        required: true,
    },
    {
        title: 'Event Type',
        type: 'select',
        name: 'eventType',
        options: [{ value: '', name: 'Please select...' }, { value: 'Golf Event', name: 'Golf Event' }, { value: 'Webinar', name: 'Webinar' }, { value: 'Other', name: 'Other' }],
        // required: true,
    },
    {
        title: 'Is it a BOC Event?',
        type: 'select',
        name: 'bocEvent',
        options: [{ value: false, name: 'No' }, { value: true, name: 'Yes' }],
        required: true,
    },
    {
        title: 'Country',
        type: 'select',
        name: 'country',
        options: [
            {"name": "Please select", "value": ""},
            {"name": "All Countries", "value": "ALL"},
            {"name": "Albania", "value": "AL"}, 
            {"name": "Algeria", "value": "DZ"}, 
            {"name": "Angola", "value": "AO"}, 
            {"name": "Anguilla", "value": "AI"}, 
            {"name": "Antarctica", "value": "AQ"}, 
            {"name": "Antigua and Barbuda", "value": "AG"}, 
            {"name": "Argentina", "value": "AR"}, 
            {"name": "Armenia", "value": "AM"}, 
            {"name": "Aruba", "value": "AW"}, 
            {"name": "Australia", "value": "AU"}, 
            {"name": "Austria", "value": "AT"}, 
            {"name": "Azerbaijan", "value": "AZ"}, 
            {"name": "Bahamas", "value": "BS"}, 
            {"name": "Bahrain", "value": "BH"}, 
            {"name": "Bangladesh", "value": "BD"}, 
            {"name": "Barbados", "value": "BB"}, 
            {"name": "Belarus", "value": "BY"}, 
            {"name": "Belgium", "value": "BE"}, 
            {"name": "Belize", "value": "BZ"}, 
            {"name": "Bhutan", "value": "BT"}, 
            {"name": "Bolivia", "value": "BO"}, 
            {"name": "Bosnia and Herzegovina", "value": "BA"}, 
            {"name": "Brazil", "value": "BR"}, 
            {"name": "British Indian Ocean Territory", "value": "IO"}, 
            {"name": "Brunei Darussalam", "value": "BN"}, 
            {"name": "Bulgaria", "value": "BG"}, 
            {"name": "Burkina Faso", "value": "BF"}, 
            {"name": "Burundi", "value": "BI"}, 
            {"name": "Cambodia", "value": "KH"}, 
            {"name": "Cameroon", "value": "CM"}, 
            {"name": "Canada", "value": "CA"}, 
            {"name": "Cape Verde", "value": "CV"}, 
            {"name": "Cayman Islands", "value": "KY"}, 
            {"name": "Central African Republic", "value": "CF"}, 
            {"name": "Chad", "value": "TD"}, 
            {"name": "Chile", "value": "CL"}, 
            {"name": "China", "value": "CN"}, 
            {"name": "Christmas Island", "value": "CX"}, 
            {"name": "Cocos (Keeling) Islands", "value": "CC"}, 
            {"name": "Colombia", "value": "CO"}, 
            {"name": "Comoros", "value": "KM"}, 
            {"name": "Congo", "value": "CG"}, 
            {"name": "Congo, The Democratic Republic of the", "value": "CD"}, 
            {"name": "Cook Islands", "value": "CK"}, 
            {"name": "Costa Rica", "value": "CR"},
            {"name": "Croatia", "value": "HR"}, 
            {"name": "Cuba", "value": "CU"}, 
            {"name": "Cyprus", "value": "CY"}, 
            {"name": "Czech Republic", "value": "CZ"}, 
            {"name": "Denmark", "value": "DK"}, 
            {"name": "Djibouti", "value": "DJ"}, 
            {"name": "Dominica", "value": "DM"}, 
            {"name": "Dominican Republic", "value": "DO"}, 
            {"name": "Ecuador", "value": "EC"}, 
            {"name": "Egypt", "value": "EG"}, 
            {"name": "El Salvador", "value": "SV"}, 
            {"name": "Equatorial Guinea", "value": "GQ"}, 
            {"name": "Eritrea", "value": "ER"}, 
            {"name": "Estonia", "value": "EE"}, 
            {"name": "Ethiopia", "value": "ET"}, 
            {"name": "Falkland Islands (Malvinas)", "value": "FK"}, 
            {"name": "Faroe Islands", "value": "FO"}, 
            {"name": "Fiji", "value": "FJ"}, 
            {"name": "Finland", "value": "FI"}, 
            {"name": "France", "value": "FR"}, 
            {"name": "French Guiana", "value": "GF"}, 
            {"name": "French Polynesia", "value": "PF"}, 
            {"name": "French Southern Territories", "value": "TF"}, 
            {"name": "Gabon", "value": "GA"}, 
            {"name": "Gambia", "value": "GM"}, 
            {"name": "Georgia", "value": "GE"}, 
            {"name": "Germany", "value": "DE"}, 
            {"name": "Ghana", "value": "GH"}, 
            {"name": "Gibraltar", "value": "GI"}, 
            {"name": "Greece", "value": "GR"}, 
            {"name": "Greenland", "value": "GL"}, 
            {"name": "Grenada", "value": "GD"}, 
            {"name": "Guadeloupe", "value": "GP"}, 
            {"name": "Guam", "value": "GU"}, 
            {"name": "Guatemala", "value": "GT"}, 
            {"name": "Guernsey", "value": "GG"}, 
            {"name": "Guinea", "value": "GN"}, 
            {"name": "Guinea-Bissau", "value": "GW"}, 
            {"name": "Guyana", "value": "GY"}, 
            {"name": "Haiti", "value": "HT"}, 
            {"name": "Heard Island and Mcdonald Islands", "value": "HM"}, 
            {"name": "Holy See (Vatican City State)", "value": "VA"}, 
            {"name": "Honduras", "value": "HN"}, 
            {"name": "Hong Kong", "value": "HK"}, 
            {"name": "Hungary", "value": "HU"}, 
            {"name": "Iceland", "value": "IS"}, 
            {"name": "India", "value": "IN"}, 
            {"name": "Indonesia", "value": "ID"}, 
            {"name": "Iran, Islamic Republic Of", "value": "IR"}, 
            {"name": "Iraq", "value": "IQ"}, 
            {"name": "Ireland", "value": "IE"}, 
            {"name": "Isle of Man", "value": "IM"}, 
            {"name": "Israel", "value": "IL"}, 
            {"name": "Italy", "value": "IT"}, 
            {"name": "Jamaica", "value": "JM"}, 
            {"name": "Japan", "value": "JP"}, 
            {"name": "Jersey", "value": "JE"}, 
            {"name": "Jordan", "value": "JO"}, 
            {"name": "Kazakhstan", "value": "KZ"}, 
            {"name": "Kenya", "value": "KE"}, 
            {"name": "Kiribati", "value": "KI"}, 
            {"name": "Korea, Republic of", "value": "KR"}, 
            {"name": "Kuwait", "value": "KW"}, 
            {"name": "Kyrgyzstan", "value": "KG"}, 
            {"name": "Lao Democratic Republic", "value": "LA"}, 
            {"name": "Latvia", "value": "LV"}, 
            {"name": "Lebanon", "value": "LB"}, 
            {"name": "Lesotho", "value": "LS"}, 
            {"name": "Liberia", "value": "LR"}, 
            {"name": "Libyan Arab Jamahiriya", "value": "LY"}, 
            {"name": "Liechtenstein", "value": "LI"}, 
            {"name": "Lithuania", "value": "LT"}, 
            {"name": "Luxembourg", "value": "LU"}, 
            {"name": "Macao", "value": "MO"}, 
            {"name": "Macedonia, The Former Yugoslav Republic of", "value": "MK"}, 
            {"name": "Madagascar", "value": "MG"}, 
            {"name": "Malawi", "value": "MW"}, 
            {"name": "Malaysia", "value": "MY"}, 
            {"name": "Maldives", "value": "MV"}, 
            {"name": "Mali", "value": "ML"}, 
            {"name": "Malta", "value": "MT"}, 
            {"name": "Marshall Islands", "value": "MH"}, 
            {"name": "Martinique", "value": "MQ"}, 
            {"name": "Mauritania", "value": "MR"}, 
            {"name": "Mauritius", "value": "MU"}, 
            {"name": "Mayotte", "value": "YT"}, 
            {"name": "Mexico", "value": "MX"}, 
            {"name": "Micronesia, Federated States of", "value": "FM"}, 
            {"name": "Moldova, Republic of", "value": "MD"}, 
            {"name": "Monaco", "value": "MC"}, 
            {"name": "Mongolia", "value": "MN"}, 
            {"name": "Montenegro", "value": "ME"},
            {"name": "Montserrat", "value": "MS"},
            {"name": "Morocco", "value": "MA"}, 
            {"name": "Mozambique", "value": "MZ"}, 
            {"name": "Myanmar", "value": "MM"}, 
            {"name": "Namibia", "value": "NA"}, 
            {"name": "Nauru", "value": "NR"}, 
            {"name": "Nepal", "value": "NP"}, 
            {"name": "Netherlands", "value": "NL"}, 
            {"name": "Netherlands Antilles", "value": "AN"}, 
            {"name": "New Caledonia", "value": "NC"}, 
            {"name": "New Zealand", "value": "NZ"}, 
            {"name": "Nicaragua", "value": "NI"}, 
            {"name": "Niger", "value": "NE"}, 
            {"name": "Nigeria", "value": "NG"}, 
            {"name": "Niue", "value": "NU"}, 
            {"name": "Norfolk Island", "value": "NF"}, 
            {"name": "Northern Mariana Islands", "value": "MP"}, 
            {"name": "Norway", "value": "NO"}, 
            {"name": "Oman", "value": "OM"}, 
            {"name": "Pakistan", "value": "PK"}, 
            {"name": "Palau", "value": "PW"}, 
            {"name": "Palestinian Territory, Occupied", "value": "PS"}, 
            {"name": "Panama", "value": "PA"}, 
            {"name": "Papua New Guinea", "value": "PG"}, 
            {"name": "Paraguay", "value": "PY"}, 
            {"name": "Peru", "value": "PE"}, 
            {"name": "Philippines", "value": "PH"}, 
            {"name": "Pitcairn", "value": "PN"}, 
            {"name": "Poland", "value": "PL"}, 
            {"name": "Portugal", "value": "PT"}, 
            {"name": "Puerto Rico", "value": "PR"}, 
            {"name": "Qatar", "value": "QA"}, 
            {"name": "Reunion", "value": "RE"}, 
            {"name": "Romania", "value": "RO"}, 
            {"name": "Russian Federation", "value": "RU"}, 
            {"name": "RWANDA", "value": "RW"}, 
            {"name": "Saint Helena", "value": "SH"}, 
            {"name": "Saint Kitts and Nevis", "value": "KN"}, 
            {"name": "Saint Lucia", "value": "LC"}, 
            {"name": "Saint Pierre and Miquelon", "value": "PM"}, 
            {"name": "Saint Vincent and the Grenadines", "value": "VC"}, 
            {"name": "Samoa", "value": "WS"}, 
            {"name": "San Marino", "value": "SM"}, 
            {"name": "Sao Tome and Principe", "value": "ST"}, 
            {"name": "Saudi Arabia", "value": "SA"}, 
            {"name": "Senegal", "value": "SN"}, 
            {"name": "Serbia", "value": "RS"}, 
            {"name": "Seychelles", "value": "SC"}, 
            {"name": "Sierra Leone", "value": "SL"}, 
            {"name": "Singapore", "value": "SG"}, 
            {"name": "Slovakia", "value": "SK"}, 
            {"name": "Slovenia", "value": "SI"}, 
            {"name": "Solomon Islands", "value": "SB"}, 
            {"name": "Somalia", "value": "SO"}, 
            {"name": "South Africa", "value": "ZA"}, 
            {"name": "South Georgia and the South Sandwich Islands", "value": "GS"}, 
            {"name": "Spain", "value": "ES"}, 
            {"name": "Sri Lanka", "value": "LK"}, 
            {"name": "Sudan", "value": "SD"}, 
            {"name": "Suriname", "value": "SR"}, 
            {"name": "Svalbard and Jan Mayen", "value": "SJ"}, 
            {"name": "Swaziland", "value": "SZ"}, 
            {"name": "Sweden", "value": "SE"}, 
            {"name": "Switzerland", "value": "CH"}, 
            {"name": "Syrian Arab Republic", "value": "SY"}, 
            {"name": "Taiwan, Province of China", "value": "TW"}, 
            {"name": "Tajikistan", "value": "TJ"}, 
            {"name": "Tanzania, United Republic of", "value": "TZ"}, 
            {"name": "Thailand", "value": "TH"}, 
            {"name": "Timor-Leste", "value": "TL"}, 
            {"name": "Togo", "value": "TG"}, 
            {"name": "Tokelau", "value": "TK"}, 
            {"name": "Tonga", "value": "TO"}, 
            {"name": "Trinidad and Tobago", "value": "TT"}, 
            {"name": "Tunisia", "value": "TN"}, 
            {"name": "Turkey", "value": "TR"}, 
            {"name": "Turkmenistan", "value": "TM"}, 
            {"name": "Turks and Caicos Islands", "value": "TC"}, 
            {"name": "Tuvalu", "value": "TV"}, 
            {"name": "Uganda", "value": "UG"}, 
            {"name": "Ukraine", "value": "UA"}, 
            {"name": "United Arab Emirates", "value": "AE"}, 
            {"name": "United Kingdom", "value": "GB"}, 
            {"name": "United States", "value": "US"}, 
            {"name": "United States Minor Outlying Islands", "value": "UM"}, 
            {"name": "Uruguay", "value": "UY"}, 
            {"name": "Uzbekistan", "value": "UZ"}, 
            {"name": "Vanuatu", "value": "VU"}, 
            {"name": "Venezuela", "value": "VE"}, 
            {"name": "Viet Nam", "value": "VN"}, 
            {"name": "Virgin Islands, British", "value": "VG"}, 
            {"name": "Virgin Islands, U.S.", "value": "VI"}, 
            {"name": "Wallis and Futuna", "value": "WF"}, 
            {"name": "Western Sahara", "value": "EH"}, 
            {"name": "Yemen", "value": "YE"}, 
            {"name": "Zambia", "value": "ZM"}, 
            {"name": "Zimbabwe", "value": "ZW"} 
            ],
        required: true,
    },
    {
        title: 'Show Scores After',
        type: 'date',
        name: 'showScoresAt',
        required: true,
    }
]

export const defaultGroup = {
    name: '',
    description: '',
    country: '',
    status: 'Inactive'
}

export const groupInputs = [
    {
        title: 'Group Name',
        type: 'text',
        name: 'name',
        required: true,
    },
    {
        title: 'Group Description',
        type: 'textarea',
        name: 'description',
        required: true,
    },
    {
        title: 'Country',
        type: 'select',
        name: 'country',
        options: [ 
            {"name": "Albania", "value": "AL"}, 
            {"name": "Algeria", "value": "DZ"}, 
            {"name": "Angola", "value": "AO"}, 
            {"name": "Anguilla", "value": "AI"}, 
            {"name": "Antarctica", "value": "AQ"}, 
            {"name": "Antigua and Barbuda", "value": "AG"}, 
            {"name": "Argentina", "value": "AR"}, 
            {"name": "Armenia", "value": "AM"}, 
            {"name": "Aruba", "value": "AW"}, 
            {"name": "Australia", "value": "AU"}, 
            {"name": "Austria", "value": "AT"}, 
            {"name": "Azerbaijan", "value": "AZ"}, 
            {"name": "Bahamas", "value": "BS"}, 
            {"name": "Bahrain", "value": "BH"}, 
            {"name": "Bangladesh", "value": "BD"}, 
            {"name": "Barbados", "value": "BB"}, 
            {"name": "Belarus", "value": "BY"}, 
            {"name": "Belgium", "value": "BE"}, 
            {"name": "Belize", "value": "BZ"}, 
            {"name": "Bhutan", "value": "BT"}, 
            {"name": "Bolivia", "value": "BO"}, 
            {"name": "Bosnia and Herzegovina", "value": "BA"}, 
            {"name": "Brazil", "value": "BR"}, 
            {"name": "British Indian Ocean Territory", "value": "IO"}, 
            {"name": "Brunei Darussalam", "value": "BN"}, 
            {"name": "Bulgaria", "value": "BG"}, 
            {"name": "Burkina Faso", "value": "BF"}, 
            {"name": "Burundi", "value": "BI"}, 
            {"name": "Cambodia", "value": "KH"}, 
            {"name": "Cameroon", "value": "CM"}, 
            {"name": "Canada", "value": "CA"}, 
            {"name": "Cape Verde", "value": "CV"}, 
            {"name": "Cayman Islands", "value": "KY"}, 
            {"name": "Central African Republic", "value": "CF"}, 
            {"name": "Chad", "value": "TD"}, 
            {"name": "Chile", "value": "CL"}, 
            {"name": "China", "value": "CN"}, 
            {"name": "Christmas Island", "value": "CX"}, 
            {"name": "Cocos (Keeling) Islands", "value": "CC"}, 
            {"name": "Colombia", "value": "CO"}, 
            {"name": "Comoros", "value": "KM"}, 
            {"name": "Congo", "value": "CG"}, 
            {"name": "Congo, The Democratic Republic of the", "value": "CD"}, 
            {"name": "Cook Islands", "value": "CK"}, 
            {"name": "Costa Rica", "value": "CR"},
            {"name": "Croatia", "value": "HR"}, 
            {"name": "Cuba", "value": "CU"}, 
            {"name": "Cyprus", "value": "CY"}, 
            {"name": "Czech Republic", "value": "CZ"}, 
            {"name": "Denmark", "value": "DK"}, 
            {"name": "Djibouti", "value": "DJ"}, 
            {"name": "Dominica", "value": "DM"}, 
            {"name": "Dominican Republic", "value": "DO"}, 
            {"name": "Ecuador", "value": "EC"}, 
            {"name": "Egypt", "value": "EG"}, 
            {"name": "El Salvador", "value": "SV"}, 
            {"name": "Equatorial Guinea", "value": "GQ"}, 
            {"name": "Eritrea", "value": "ER"}, 
            {"name": "Estonia", "value": "EE"}, 
            {"name": "Ethiopia", "value": "ET"}, 
            {"name": "Falkland Islands (Malvinas)", "value": "FK"}, 
            {"name": "Faroe Islands", "value": "FO"}, 
            {"name": "Fiji", "value": "FJ"}, 
            {"name": "Finland", "value": "FI"}, 
            {"name": "France", "value": "FR"}, 
            {"name": "French Guiana", "value": "GF"}, 
            {"name": "French Polynesia", "value": "PF"}, 
            {"name": "French Southern Territories", "value": "TF"}, 
            {"name": "Gabon", "value": "GA"}, 
            {"name": "Gambia", "value": "GM"}, 
            {"name": "Georgia", "value": "GE"}, 
            {"name": "Germany", "value": "DE"}, 
            {"name": "Ghana", "value": "GH"}, 
            {"name": "Gibraltar", "value": "GI"}, 
            {"name": "Greece", "value": "GR"}, 
            {"name": "Greenland", "value": "GL"}, 
            {"name": "Grenada", "value": "GD"}, 
            {"name": "Guadeloupe", "value": "GP"}, 
            {"name": "Guam", "value": "GU"}, 
            {"name": "Guatemala", "value": "GT"}, 
            {"name": "Guernsey", "value": "GG"}, 
            {"name": "Guinea", "value": "GN"}, 
            {"name": "Guinea-Bissau", "value": "GW"}, 
            {"name": "Guyana", "value": "GY"}, 
            {"name": "Haiti", "value": "HT"}, 
            {"name": "Heard Island and Mcdonald Islands", "value": "HM"}, 
            {"name": "Holy See (Vatican City State)", "value": "VA"}, 
            {"name": "Honduras", "value": "HN"}, 
            {"name": "Hong Kong", "value": "HK"}, 
            {"name": "Hungary", "value": "HU"}, 
            {"name": "Iceland", "value": "IS"}, 
            {"name": "India", "value": "IN"}, 
            {"name": "Indonesia", "value": "ID"}, 
            {"name": "Iran, Islamic Republic Of", "value": "IR"}, 
            {"name": "Iraq", "value": "IQ"}, 
            {"name": "Ireland", "value": "IE"}, 
            {"name": "Isle of Man", "value": "IM"}, 
            {"name": "Israel", "value": "IL"}, 
            {"name": "Italy", "value": "IT"}, 
            {"name": "Jamaica", "value": "JM"}, 
            {"name": "Japan", "value": "JP"}, 
            {"name": "Jersey", "value": "JE"}, 
            {"name": "Jordan", "value": "JO"}, 
            {"name": "Kazakhstan", "value": "KZ"}, 
            {"name": "Kenya", "value": "KE"}, 
            {"name": "Kiribati", "value": "KI"}, 
            {"name": "Korea, Republic of", "value": "KR"}, 
            {"name": "Kuwait", "value": "KW"}, 
            {"name": "Kyrgyzstan", "value": "KG"}, 
            {"name": "Lao Democratic Republic", "value": "LA"}, 
            {"name": "Latvia", "value": "LV"}, 
            {"name": "Lebanon", "value": "LB"}, 
            {"name": "Lesotho", "value": "LS"}, 
            {"name": "Liberia", "value": "LR"}, 
            {"name": "Libyan Arab Jamahiriya", "value": "LY"}, 
            {"name": "Liechtenstein", "value": "LI"}, 
            {"name": "Lithuania", "value": "LT"}, 
            {"name": "Luxembourg", "value": "LU"}, 
            {"name": "Macao", "value": "MO"}, 
            {"name": "Macedonia, The Former Yugoslav Republic of", "value": "MK"}, 
            {"name": "Madagascar", "value": "MG"}, 
            {"name": "Malawi", "value": "MW"}, 
            {"name": "Malaysia", "value": "MY"}, 
            {"name": "Maldives", "value": "MV"}, 
            {"name": "Mali", "value": "ML"}, 
            {"name": "Malta", "value": "MT"}, 
            {"name": "Marshall Islands", "value": "MH"}, 
            {"name": "Martinique", "value": "MQ"}, 
            {"name": "Mauritania", "value": "MR"}, 
            {"name": "Mauritius", "value": "MU"}, 
            {"name": "Mayotte", "value": "YT"}, 
            {"name": "Mexico", "value": "MX"}, 
            {"name": "Micronesia, Federated States of", "value": "FM"}, 
            {"name": "Moldova, Republic of", "value": "MD"}, 
            {"name": "Monaco", "value": "MC"}, 
            {"name": "Mongolia", "value": "MN"}, 
            {"name": "Montenegro", "value": "ME"},
            {"name": "Montserrat", "value": "MS"},
            {"name": "Morocco", "value": "MA"}, 
            {"name": "Mozambique", "value": "MZ"}, 
            {"name": "Myanmar", "value": "MM"}, 
            {"name": "Namibia", "value": "NA"}, 
            {"name": "Nauru", "value": "NR"}, 
            {"name": "Nepal", "value": "NP"}, 
            {"name": "Netherlands", "value": "NL"}, 
            {"name": "Netherlands Antilles", "value": "AN"}, 
            {"name": "New Caledonia", "value": "NC"}, 
            {"name": "New Zealand", "value": "NZ"}, 
            {"name": "Nicaragua", "value": "NI"}, 
            {"name": "Niger", "value": "NE"}, 
            {"name": "Nigeria", "value": "NG"}, 
            {"name": "Niue", "value": "NU"}, 
            {"name": "Norfolk Island", "value": "NF"}, 
            {"name": "Northern Mariana Islands", "value": "MP"}, 
            {"name": "Norway", "value": "NO"}, 
            {"name": "Oman", "value": "OM"}, 
            {"name": "Pakistan", "value": "PK"}, 
            {"name": "Palau", "value": "PW"}, 
            {"name": "Palestinian Territory, Occupied", "value": "PS"}, 
            {"name": "Panama", "value": "PA"}, 
            {"name": "Papua New Guinea", "value": "PG"}, 
            {"name": "Paraguay", "value": "PY"}, 
            {"name": "Peru", "value": "PE"}, 
            {"name": "Philippines", "value": "PH"}, 
            {"name": "Pitcairn", "value": "PN"}, 
            {"name": "Poland", "value": "PL"}, 
            {"name": "Portugal", "value": "PT"}, 
            {"name": "Puerto Rico", "value": "PR"}, 
            {"name": "Qatar", "value": "QA"}, 
            {"name": "Reunion", "value": "RE"}, 
            {"name": "Romania", "value": "RO"}, 
            {"name": "Russian Federation", "value": "RU"}, 
            {"name": "RWANDA", "value": "RW"}, 
            {"name": "Saint Helena", "value": "SH"}, 
            {"name": "Saint Kitts and Nevis", "value": "KN"}, 
            {"name": "Saint Lucia", "value": "LC"}, 
            {"name": "Saint Pierre and Miquelon", "value": "PM"}, 
            {"name": "Saint Vincent and the Grenadines", "value": "VC"}, 
            {"name": "Samoa", "value": "WS"}, 
            {"name": "San Marino", "value": "SM"}, 
            {"name": "Sao Tome and Principe", "value": "ST"}, 
            {"name": "Saudi Arabia", "value": "SA"}, 
            {"name": "Senegal", "value": "SN"}, 
            {"name": "Serbia", "value": "RS"}, 
            {"name": "Seychelles", "value": "SC"}, 
            {"name": "Sierra Leone", "value": "SL"}, 
            {"name": "Singapore", "value": "SG"}, 
            {"name": "Slovakia", "value": "SK"}, 
            {"name": "Slovenia", "value": "SI"}, 
            {"name": "Solomon Islands", "value": "SB"}, 
            {"name": "Somalia", "value": "SO"}, 
            {"name": "South Africa", "value": "ZA"}, 
            {"name": "South Georgia and the South Sandwich Islands", "value": "GS"}, 
            {"name": "Spain", "value": "ES"}, 
            {"name": "Sri Lanka", "value": "LK"}, 
            {"name": "Sudan", "value": "SD"}, 
            {"name": "Suriname", "value": "SR"}, 
            {"name": "Svalbard and Jan Mayen", "value": "SJ"}, 
            {"name": "Swaziland", "value": "SZ"}, 
            {"name": "Sweden", "value": "SE"}, 
            {"name": "Switzerland", "value": "CH"}, 
            {"name": "Syrian Arab Republic", "value": "SY"}, 
            {"name": "Taiwan, Province of China", "value": "TW"}, 
            {"name": "Tajikistan", "value": "TJ"}, 
            {"name": "Tanzania, United Republic of", "value": "TZ"}, 
            {"name": "Thailand", "value": "TH"}, 
            {"name": "Timor-Leste", "value": "TL"}, 
            {"name": "Togo", "value": "TG"}, 
            {"name": "Tokelau", "value": "TK"}, 
            {"name": "Tonga", "value": "TO"}, 
            {"name": "Trinidad and Tobago", "value": "TT"}, 
            {"name": "Tunisia", "value": "TN"}, 
            {"name": "Turkey", "value": "TR"}, 
            {"name": "Turkmenistan", "value": "TM"}, 
            {"name": "Turks and Caicos Islands", "value": "TC"}, 
            {"name": "Tuvalu", "value": "TV"}, 
            {"name": "Uganda", "value": "UG"}, 
            {"name": "Ukraine", "value": "UA"}, 
            {"name": "United Arab Emirates", "value": "AE"}, 
            {"name": "United Kingdom", "value": "GB"}, 
            {"name": "United States", "value": "US"}, 
            {"name": "United States Minor Outlying Islands", "value": "UM"}, 
            {"name": "Uruguay", "value": "UY"}, 
            {"name": "Uzbekistan", "value": "UZ"}, 
            {"name": "Vanuatu", "value": "VU"}, 
            {"name": "Venezuela", "value": "VE"}, 
            {"name": "Viet Nam", "value": "VN"}, 
            {"name": "Virgin Islands, British", "value": "VG"}, 
            {"name": "Virgin Islands, U.S.", "value": "VI"}, 
            {"name": "Wallis and Futuna", "value": "WF"}, 
            {"name": "Western Sahara", "value": "EH"}, 
            {"name": "Yemen", "value": "YE"}, 
            {"name": "Zambia", "value": "ZM"}, 
            {"name": "Zimbabwe", "value": "ZW"} 
            ],
        required: true,
    },
    {
        title: 'Status',
        type: 'select',
        name: 'status',
        options: [{ value: 'Inactive', name: 'Inactive' }, { value: 'Active', name: 'Active' }],
        required: true,
    },
    {
        title: 'Photo Link',
        type: 'text',
        name: 'photo',
        required: false,
    }
]