import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, InputGroup, Table } from 'reactstrap'
import './UserList.css'
import { downloadUserList, getUsers } from '../../redux/actions/userActions'

//Import Variables
import { defaultUser } from '../variables'

//Import Components
import UserTable from './UserTable/UserTable'
import UserModal from './UserModal/UserModal'
import InvoiceModal from './InvoiceModal/InvoiceModal'
import FloatingButton from '../QR/FloatingButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilter } from '@fortawesome/free-solid-svg-icons';

function UserList() {
    const dispatch = useDispatch()
    const [user, setUser] = useState(defaultUser)
    const [initialUser, setInitialUser] = useState(null)
    const [addMode, setAddMode] = useState(false)
    const [filter, setFilter] = useState('')
    const { users } = useSelector(state => state.userReducer)
    const { token } = useSelector(state => state.authReducer)
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        dispatch(getUsers())
    }, [])

    // For managing User Modal
    const [userModal, setUserModal] = useState(false)
    const openUserModal = (user = defaultUser, addMode = false) => {
        setUserModal(!userModal)
        setUser(user)
        setInitialUser(user)
        setAddMode(addMode)
    }

    // For managing Invoice Modal
    const [invoiceModal, setInvoiceModal] = useState(false)
    const openInvoiceModal = (user = defaultUser) => {
        setInvoiceModal(!invoiceModal)
        setUser(user)
    }

    const onChange = e => {
        var object = { ...user }
        const { id, value, name } = e.target
        if (id) {
            // To create an object if it doesn't exist yet
            if (!object[id]) object[id] = {}
            object[id] = { ...object[id], [name]: value }
        } else {
            object[name] = value
        }
        setUser(object)
    }

    return (
        <Fragment>
            <br />
            <Button color='primary' onClick={() => openUserModal(defaultUser, true)}>Add Member</Button>
            <Button style={{ backgroundColor: "#1D6F42", marginLeft: "0.5rem" }} disabled={!(users?.length > 0) || disabled}>
                <a
                    href={`/api/users/download/${token}`}
                    target="_blank"
                    style={{ textDecoration: 'none', color: 'white' }}
                >
                    <FontAwesomeIcon icon={faFileExcel} /> Export CSV
                </a>
            </Button>
            <br />
            <br />
            <UserModal
                openUserModal={openUserModal}
                userModal={userModal}
                user={user}
                onChange={onChange}
                addMode={addMode}
                initialUser={initialUser}
            />
            <InvoiceModal
                openInvoiceModal={openInvoiceModal}
                invoiceModal={invoiceModal}
                user={user}
            />
            <UserTable
                openUserModal={openUserModal}
                openInvoiceModal={openInvoiceModal}
            />

            <FloatingButton />
        </Fragment>
    );
}

export default UserList;