import axios from 'axios'
import { baseURL } from '../helpers'
import { ADD_NOTIFICATION_EMAIL, ADD_USER, GET_NOTIFICATION_EMAILS, NOTIFICATION_EMAILS_FAIL, USER_FAIL } from '../types'
import { tokenConfig } from './authActions'
import { clearErrors, returnErrors } from './errorActions'

export const getNotificationEmails = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/notificationEmails`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_NOTIFICATION_EMAILS,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, NOTIFICATION_EMAILS_FAIL));
            dispatch({
                type: NOTIFICATION_EMAILS_FAIL,
            })
        })
    dispatch(clearErrors())
}

export const updateNotificationEmail = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/notificationEmails`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: ADD_NOTIFICATION_EMAIL,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, NOTIFICATION_EMAILS_FAIL));
            dispatch({
                type: NOTIFICATION_EMAILS_FAIL,
            })
        })
}
