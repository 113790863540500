import React, { useState, useEffect } from 'react';
import axios from 'axios'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Card,
    CardBody
} from 'reactstrap'
import { Redirect } from 'react-router-dom';

const ResetPassword = props => {
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [redirect, setRedirect] = useState(false)
    const [loading, setLoading] = useState(false)

    const onSubmit = async e => {
        e.preventDefault();
        //Clear errors
        setErrorMessage('')
        setSuccessMessage('')
        setLoading(true)
        if (password !== password2) {
            setErrorMessage(`Passwords Don't Match`);
            setLoading(false)
            return
        }
        if (password.length < 8) {
            setErrorMessage(`Password should have at least 8 characters`);
            setLoading(false)
            return
        }
        var hasUpperCase = /[A-Z]/.test(password);
        var hasLowerCase = /[a-z]/.test(password);
        var hasNumbers = /\d/.test(password);
        var hasNonalphas = /\W/.test(password);
        if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 4) {
            setErrorMessage(`Password does not meet requirements`);
            setLoading(false)
            return
        }
        const token = props.match.params.token

        // create a user object
        const newPassword = {
            token,
            password,
            password2
        }
        const config = {
            headers: {}
        }
        config.headers['x-auth-token'] = token
        await axios.post('/api/auth/resetPassword', newPassword, config)
            .then(res => {
                setSuccessMessage(res.data.message)
                setTimeout(() => {
                    setRedirect(true)
                }, 3000);
            })
            .catch(err => {
                setErrorMessage(err.response.data.message)
                setLoading(false)
            })
    }

    if (redirect) return <Redirect to='/' />
    return (
        <div>
            <Card
                style={
                    {
                        width: "40%",
                        margin: "auto"
                    }
                }
            >
                <CardBody
                    style={
                        {
                            padding: "5%"
                        }
                    }
                >
                    {errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}
                    {successMessage ? <Alert color="success">{successMessage}</Alert> : null}
                    <Form onSubmit={onSubmit}>
                        <p>Password Requirements</p>
                        <span style={{ fontSize: '12px' }}>At least 8 characters—the more characters, the better.</span><br></br>
                        <span style={{ fontSize: '12px' }}>A mixture of both uppercase and lowercase letters.</span><br></br>
                        <span style={{ fontSize: '12px' }}>A mixture of letters and numbers.</span><br></br>
                        <span style={{ fontSize: '12px' }}>Inclusion of at least one special character, e.g., ! @ # ? ]</span><br></br><br></br>
                        <FormGroup>
                            <Label for="password" >Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                onChange={e => setPassword(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password2" >Confirm Password</Label>
                            <Input
                                type="password"
                                name="password2"
                                id="password2"
                                onChange={e => setPassword2(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <Button
                            color="dark"
                            style={{ marginTop: "2rem", cursor: loading && 'progress' }}
                            disabled={loading}
                            block
                        >
                            Reset Password
                        </Button>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

export default ResetPassword