import { POST_FAIL, GET_POSTS, ADD_POST, DELETE_POST, UPDATE_POST, SET_POST_UPDATED, GET_POST, SET_POST } from '../types';

function initialState() {
    return {
        posts: [],
        post: null,
        updated: false,
        success: null
    };
}

const postReducer = function (state = initialState(), action) {
    const { type, payload } = action
    switch (type) {
        case GET_POSTS:
            return {
                ...state,
                posts: payload,
            }
        case SET_POST:
            return {
                ...state,
                post: payload
            }
        case GET_POST:
            return {
                ...state,
                post: payload,
            }
        case ADD_POST:
            return {
                ...state,
                posts: [...state.posts, payload],
                updated: true,
            }
        case DELETE_POST:
            return {
                ...state,
                posts: state.posts.filter(({ _id }) => _id !== payload),
            }
        case UPDATE_POST:
            return {
                ...state,
                post: payload,
                posts: state.posts.map(post => {
                    if (post._id === payload._id) {
                        return payload
                    } else {
                        return post
                    }
                }),
                updated: true,
            }
        case SET_POST_UPDATED:
            return {
                ...state,
                updated: payload
            }
        case POST_FAIL:
        default:
            return {
                ...state,
                success: null
            }
    }
};

export default postReducer;
