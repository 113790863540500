import { ADD_GROUP, DELETE_GROUP, GROUP_FAIL, GET_GROUP, GET_GROUPS, SET_GROUP_UPDATED, UPDATE_GROUP, SET_GROUP, SET_GROUP_PHOTO } from '../types'
import axios from 'axios'
import { tokenConfig } from './authActions'
import { returnErrors, clearErrors } from './errorActions'
import { baseURL } from '../helpers'

export const getGroups = () => (dispatch, getState) => {
    dispatch(clearErrors())
    dispatch({
        type: GET_GROUPS
    })
}

export const getGroupList = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/groups/grouplist`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_GROUPS,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, GROUP_FAIL));
            dispatch({
                type: GROUP_FAIL,
            })
        })
}

export const getGroup = (id) => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/groups/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_GROUP,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, GROUP_FAIL));
            dispatch({
                type: GROUP_FAIL,
            })
        })
}

export const addGroup = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/groups`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: ADD_GROUP,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, GROUP_FAIL));
            dispatch({
                type: GROUP_FAIL,
            })
        })
}

export const deleteGroup = id => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.delete(`${baseURL}/groups/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: DELETE_GROUP,
                payload: id
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, GROUP_FAIL));
            dispatch({
                type: GROUP_FAIL,
            })
        })
}

export const updateGroup = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.patch(`${baseURL}/groups`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_GROUP,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, GROUP_FAIL));
            dispatch({
                type: GROUP_FAIL,
            })
        })
}


export const uploadFile = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/groups/uploadfile`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_GROUP,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, GROUP_FAIL));
            dispatch({
                type: GROUP_FAIL,
            })
        })
}

export const uploadCompanyFile = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/groups/uploadCompanyPhoto`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_GROUP,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, GROUP_FAIL));
            dispatch({
                type: GROUP_FAIL,
            })
        })
}

export const getPhoto = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/groups/getPhoto`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: SET_GROUP_PHOTO,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, GROUP_FAIL));
            dispatch({
                type: GROUP_FAIL,
            })
        })
}

export const setGroup = group => (dispatch) => {
    dispatch({
        type: SET_GROUP,
        payload: group
    })
}

export const setUpdated = state => (dispatch) => {
    dispatch({
        type: SET_GROUP_UPDATED,
        payload: state,
    })
}