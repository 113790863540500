import { ADD_POST, DELETE_POST, POST_FAIL, GET_POST, GET_POSTS, SET_POST_UPDATED, UPDATE_POST, SET_POST } from '../types'
import axios from 'axios'
import { tokenConfig } from './authActions'
import { returnErrors, clearErrors } from './errorActions'
import { baseURL } from '../helpers'

export const getPosts = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/posts`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_POSTS,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, POST_FAIL));
            dispatch({
                type: POST_FAIL,
            })
        })
}

export const getPostList = () => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/posts/postlist`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_POSTS,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, POST_FAIL));
            dispatch({
                type: POST_FAIL,
            })
        })
}

export const getPost = (id) => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.get(`${baseURL}/posts/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: GET_POST,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, POST_FAIL));
            dispatch({
                type: POST_FAIL,
            })
        })
}

export const addPost = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.post(`${baseURL}/posts`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: ADD_POST,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, POST_FAIL));
            dispatch({
                type: POST_FAIL,
            })
        })
}

export const deletePost = id => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.delete(`${baseURL}/posts/${id}`, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: DELETE_POST,
                payload: id
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, POST_FAIL));
            dispatch({
                type: POST_FAIL,
            })
        })
}

export const updatePost = requestBody => (dispatch, getState) => {
    dispatch(clearErrors())
    axios.patch(`${baseURL}/posts`, requestBody, tokenConfig(getState))
        .then(response => {
            dispatch({
                type: UPDATE_POST,
                payload: response.data
            })
        })
        .catch(err => {
            console.error(err);
            dispatch(returnErrors(err?.response?.data, err?.response?.status, POST_FAIL));
            dispatch({
                type: POST_FAIL,
            })
        })
}

export const setPost = user => (dispatch) => {
    dispatch({
        type: SET_POST,
        payload: user
    })
}

export const setUpdated = state => (dispatch) => {
    dispatch({
        type: SET_POST_UPDATED,
        payload: state,
    })
}