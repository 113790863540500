import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getEvent } from '../../redux/actions/eventActions'
import './Awards.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { baseURL } from '../../redux/helpers';
import axios from "axios";

function EditPoints(props) {
    const dispatch = useDispatch();
    const { eventId } = props;
    const { event } = useSelector(state => state.eventReducer);
    const { users } = useSelector(state => state.userReducer);

    const [userId, setUserId] = useState();
    const [points, setPoints] = useState(0);
    const [priority, setPriority] = useState(1);
    const [thisEventScores, setThisEventScores] = useState([]);
    const [editScoreModalOpen, setEditScoreModalOpen] = useState(false);
    const [scoreToEdit, setScoreToEdit] = useState();

    const loadEventScores = () => {
        axios.post(`${baseURL}/scores/event_id`, { event_id: event._id })
            .then(response => {
                if (Array.isArray(response.data)) {
                    setThisEventScores(response.data);
                };
            });
    };

    useEffect(() => {
        dispatch(getEvent(eventId));
    }, [eventId]);

    useEffect(() => {
        if (event && thisEventScores.length < 1) {
            loadEventScores();
        };
    }, [event]);

    useEffect(() => {
        setUserId(null);
        setPoints(0);
    }, [thisEventScores]);

    function toggleEditScoreModal() {
        if (editScoreModalOpen) {
            setEditScoreModalOpen(!editScoreModalOpen)
            setUserId(null)
            setPoints(0)
            setPriority(1)
        } else {
            setEditScoreModalOpen(!editScoreModalOpen)
        }
    };

    function isDisabled() {
        if (userId && points && priority && points >= 0 && priority > 0) {
            return false
        } else {
            return true
        }
    };

    function getUserName(id) {
        var user = users.find(u => u._id == id)
        if (user) {
            return user.personalDetails.name
        }
        return 'N/A'
    };

    function findMatchingScore(id) {
        var result = thisEventScores?.find(s => s.user_id == id)
        if (result && result.award_type == 'normal') {
            return result
        }
        return false
    };

    const sortUsersAlphabetically = (users) => {
        return users.sort((a, b) => getUserName(a._id)?.toUpperCase() > getUserName(b._id)?.toUpperCase() ? 1 : -1)
    }

    const ParticipantsTable = () => {
        let RSVPYes = []
        let RSVPNo = []
        event.users.forEach(user => {
            if (user.RSVP == "Yes") {
                RSVPYes.push(user)
            } else {
                RSVPNo.push(user)
            }
        })

        return [...sortUsersAlphabetically(RSVPYes), ...sortUsersAlphabetically(RSVPNo)]
            .map(u => {
                if (findMatchingScore(u._id)) {
                    const theScore = findMatchingScore(u._id);
                    return <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <p style={{ width: '250px' }}>
                            {getUserName(theScore.user_id)}
                        </p> : <p style={{ color: '#28A745', width: '80px' }}>
                            {theScore.value}
                        </p>
                        <span style={{ cursor: 'pointer' }} onClick={() => {
                            toggleEditScoreModal();
                            setScoreToEdit(theScore);
                            setUserId(theScore.user_id);
                            setPoints(theScore.value);
                        }}>
                            <FontAwesomeIcon icon={faEdit} /> &nbsp;
                        </span>
                    </div>
                } else {
                    return <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <p style={{ width: '250px', color: u.RSVP !== 'Yes' && "red" }}>
                            {getUserName(u._id)}
                        </p> : <p style={{ color: '#ffbb33', width: '80px' }}>
                            {0}
                        </p>
                        <span style={{ cursor: 'pointer' }} onClick={() => {
                            toggleEditScoreModal();
                            setScoreToEdit(null);
                            setUserId(u._id)
                        }}>
                            <FontAwesomeIcon icon={faEdit} /> &nbsp;
                        </span>
                    </div>
                }
            })
    };

    function handleAddScore() {
        var date = new Date(event.date);
        var year = date.getFullYear();
        var score = {
            value: Number(points),
            award_type: 'normal',
            priority: Number(priority),
            user_id: userId,
            event_id: eventId,
            event_year: year,
        }
        toggleEditScoreModal();
        axios.post(`${baseURL}/scores/addScoreAndUpdateRanks`, score)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setThisEventScores(response.data);
                };
            });
    };

    function handleEditScore() {
        var score = scoreToEdit;
        score.value = Number(points);
        score.priority = Number(priority);
        toggleEditScoreModal();
        axios.post(`${baseURL}/scores/editScoreAndUpdateRanks`, score)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setThisEventScores(response.data);
                };
            });
    };

    return (<>
        <Fragment>
            {event?.users.length ?
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <h3>{event.name}</h3>
                    <p style={{ marginBottom: '50px' }}>
                        Here you can add points to the event users, just select a user, type howmuch points then click 'Add'
                    </p>

                    <div style={{ marginTop: '50px' }}>
                        <h3>Current Points</h3>
                        <ParticipantsTable />
                    </div>
                </div>
                : <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    This Event has no users!
                </div>
            }
        </Fragment>
        <Modal isOpen={editScoreModalOpen} toggle={() => toggleEditScoreModal()}>
            <ModalHeader toggle={() => toggleEditScoreModal()}>
                <div className='addMemTitle1'>Edit Points</div>
                <div className='addMemTitle2'>
                    For: {scoreToEdit ? getUserName(scoreToEdit?.user_id) : getUserName(userId)}
                </div>
            </ModalHeader>
            <ModalBody>
                <div>
                    <label>Priority <br />
                        <input
                            className='usersDropdown'
                            placeholder='Priority'
                            type="number"
                            style={{ width: '100px', paddingLeft: '20px' }}
                            value={priority}
                            onChange={(e) => setPriority(e.target.value)}
                        />
                    </label>

                    <label>Points <br />
                        <input
                            className='usersDropdown'
                            placeholder='Points total'
                            type="number"
                            style={{ width: '100px', paddingLeft: '20px' }}
                            value={points}
                            onChange={(e) => setPoints(e.target.value)}
                        />
                    </label>

                    <Button
                        color='primary'
                        disabled={isDisabled()}
                        style={{ marginTop: '-10px' }}
                        onClick={() => {
                            if (scoreToEdit) {
                                handleEditScore();
                            } else {
                                handleAddScore();
                            };
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    </>);
}

export default EditPoints;