import React, { useEffect } from 'react';
import { getTokensHistory } from '../Tokens.redux';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import warningCircle from '../../../Assets/warningCircle.png';
import css from "./TokensUser.module.css";

const AffectedEventsIds = [
    "6539ee94ab8077dd3543fb9e",
    "659be7774a200400fca16e8a",
];

const stateColors = {
    active: { bg: 'green', clr: 'white' },
    new: { bg: '#FFAA29', clr: 'white' },
    renewal: { bg: '#F32828', clr: 'white' },
    exempted: { bg: '#72bcd4', clr: 'black' },
    expired: { bg: 'black', clr: 'white' },
};

const TokensUser = (props) => {
    const { currentUser, userToShow, setUserToShow } = props;
    const dispatch = useDispatch();
    const { tokensHistory } = useSelector(state => state.tokenManagementReducer);

    const getTokenColor = count => {
        if (count > 3) {
            return stateColors.active.bg;
        } else if (count > 0) {
            return stateColors.new.bg;
        } else {
            return stateColors.renewal.bg;
        };
    };

    const userToUse = userToShow ? userToShow : currentUser.user;
    const { personalDetails, availableTokens, member_id, status, _id } = userToUse;

    useEffect(() => {
        if (_id) {
            dispatch(getTokensHistory({ user_id: _id }));
        };
    }, [_id]);

    return <div className={css.mainTokensDiv}>
        <h2 style={{ textAlign: "center", marginBottom: "50px" }}>
            Token Board
        </h2>
        {userToShow ? <Button color='outline-primary' onClick={() => {
            setUserToShow(null);
        }}>
            Back to all users
        </Button> : null}

        <div className={css.memberCard}>
            <div className={css.memberCardTop}>
                <div className={css.memberCardItem}>
                    <span className={css.availableTokens}>Available Tokens: </span>
                    <span
                        className={css.availableTokens}
                        style={{ color: getTokenColor(availableTokens) }}
                    >
                        {availableTokens}
                    </span>
                    {availableTokens < 4 ? <div className={css.tooltip}>
                        <img src={warningCircle} />
                        <span className={css.tooltiptext}>
                            You are low on tokens, please renew membership!
                        </span>
                    </div> : null}
                </div>
                <div
                    className={css.memberCardStatus}
                    style={{
                        backgroundColor: stateColors[status?.toLowerCase()].bg,
                        color: stateColors[status?.toLowerCase()].clr
                    }}
                >
                    {status.toUpperCase()}
                </div>
            </div>
            <div className={css.memberCardContent}>
                <div className={css.memberCardItem}>
                    <strong>Name: </strong>{personalDetails?.name}
                </div>
                <div className={css.memberCardItem}>
                    <strong>Member Id: </strong>{member_id}
                </div>
            </div>
        </div>
        <div className={css.activitySection}>
            <h4>Recent activity</h4>
            {tokensHistory.map((h, i) => {
                const {
                    amount,
                    comment,
                    createdAt,
                    error_message,
                    userTokensAfter,
                    event_id,
                } = h;

                const isAdding = amount > 0;
                const isAffectedEvent = AffectedEventsIds.includes(event_id);
                const correctNumberOfTokensAfter = isAffectedEvent && amount !== 0 ? userTokensAfter + 1 : userTokensAfter;

                return <div className={css.historyItem} key={i}>
                    <div>&#11044;</div>
                    <div>
                        <div>
                            {createdAt.substring(0, 10)}
                        </div>
                        <div>
                            <strong>
                                {Math.abs(amount)}
                            </strong> token{amount > 1 && "s"} {
                                isAdding ? <span style={{ color: stateColors.active.bg }}>
                                    added
                                </span> : <span style={{ color: stateColors.renewal.bg }}>
                                    deducted
                                </span>
                            }
                            {error_message ? <>
                                <span>{" "}because{" "}</span>
                                <span style={{ color: 'orange' }}>
                                    {error_message}
                                </span>
                            </> : null}
                        </div>
                        <div style={{ color: '#727272', fontSize: '12px' }}>
                            Balance after change: {correctNumberOfTokensAfter}
                        </div>
                        <div style={{ color: '#727272' }}>
                            {comment}
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>
};

export default TokensUser;