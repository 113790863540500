import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Table, Alert } from 'reactstrap';
import { getInvoicesById } from '../../../redux/actions/invoiceActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
// import members from './MemberList csv.json'

import CreateInvoice from './CreateInvoice/CreateInvoice'
import InvoiceView from './InvoiceView/InvoiceView'
import InvoiceTable from './InvoiceTable/InvoiceTable'

import '../UserTable/UserTable.css'

const UserUpdate = ({ openInvoiceModal, invoiceModal, user, addMode }) => {
    const dispatch = useDispatch()
    const [invoiceState, setInvoiceState] = useState(false)
    const [viewState, setViewState] = useState(false)
    const [invoice, setInvoice] = useState(null)
    const { invoices, success } = useSelector(state => state.invoiceReducer)
    const [visible, setVisible] = useState(false);

    const onDismiss = () => setVisible(false);

    useEffect(() => {
        if (user) {
            dispatch(getInvoicesById({ user_id: user._id }))
        }
    }, [user._id])

    useEffect(() => {
        if (success) {
            setVisible(true)
            toggleViewState()
        }
    }, [success])

    const onSubmit = e => {
        e.preventDefault()
        toggleInvoiceState()
    }

    const viewInvoice = invoice => {
        setInvoice(invoice)
        toggleViewState()
    }

    const toggleInvoiceState = () => {
        setInvoiceState(!invoiceState)
    }

    const toggleViewState = () => {
        setViewState(!viewState)
    }

    if (!user) return null
    return (
        <Fragment>
            <CreateInvoice
                invoiceState={invoiceState}
                toggleInvoiceState={toggleInvoiceState}
                user={user}
            />
            <InvoiceView
                viewState={viewState}
                toggleViewState={toggleViewState}
                user={user}
                invoice={invoice}
            />
            <Modal isOpen={invoiceModal} toggle={() => openInvoiceModal()}>
                <ModalHeader toggle={() => openInvoiceModal()}>Invoices</ModalHeader>
                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        <Alert color="info" isOpen={visible} toggle={onDismiss}>
                            Invoice Sent
                        </Alert>
                        <InvoiceTable viewInvoice={viewInvoice} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type='submit'>Create New Invoice</Button>{' '}
                        <Button color="secondary" onClick={() => openInvoiceModal()}>Close</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    );
}

export default UserUpdate;