import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Compress from '../../../ImageCompression/imageCompression';
import UploadPNG from '../../../../Assets/upload.png'

const UpdatePhotoModal = (props) => {
    const {
        imageSrc,
        id,
        disabled,
        setImageSrc,
        setTempFile,
        setUploadButton,
        isVisitor,
        isAdmin,
        uploadAnswer,
        uploadButton,
        profileDisplay,
        setProfileDisplay,
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () =>(!isVisitor || isAdmin)? setModal(!modal):false;
    return (
        <Fragment>
            {
                imageSrc ? <div className='imageContainer' onClick={toggle} >
                    <img className='image' src={imageSrc} />
                </div> : <div className='emptyImage' onClick={toggle} >
                    <div>
                        <img src={UploadPNG} />
                    </div>
                    <div className='mt-2'>
                        Upload Image
                    </div>

                </div>}


            <Modal isOpen={modal} toggle={toggle}>
                <ModalBody >
                    <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="flex-shrink-0">
                            <div className="profile-wrapper">
                                <Compress
                                    type='file'
                                    accept='image/*'
                                    imageSrc={imageSrc}
                                    id={id}
                                    disabled={isVisitor ? !isAdmin : false}
                                    setImageSrc={setImageSrc}
                                    setTempFile={setTempFile}
                                    setUploadButton={setUploadButton}
                                    profileDisplay={profileDisplay}
                                    setProfileDisplay={setProfileDisplay}
                                />
                            </div>

                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button style={{ backgroundColor: '#10a12b' }}
                        block
                        onClick={e => { uploadAnswer(e); toggle() }}
                        disabled={!uploadButton}
                    >Save</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default UpdatePhotoModal;