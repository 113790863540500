import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Alert, Label, Input, FormGroup, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

// import members from './EventList csv.json'

import { sendEventInvite, updateSingleEvent } from '../../../redux/actions/eventActions'

const ParticipantModal = ({ setParticipantsModal, participantsModal, displayList, addParticipant }) => {

    return (
        <div>
            <Modal isOpen={participantsModal} toggle={() => setParticipantsModal(false)} size='lg'>
                <ModalHeader toggle={() => setParticipantsModal(false)}></ModalHeader>
                <ModalBody>
                    <ListGroup>
                        {displayList.map(user => <ListGroupItem key={user._id}
                            style={{ cursor: 'pointer' }}
                            onClick={() => addParticipant(user)}
                        >{user?.personalDetails.name}
                            <span style={{ float: 'right' }}><FontAwesomeIcon icon={faPlus} /></span>
                        </ListGroupItem>)}
                    </ListGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setParticipantsModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
        </div >
    );
}

export default ParticipantModal;