import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Alert, Label, Input, FormGroup } from 'reactstrap';
import { updateGroup, setUpdated, getGroupList } from '../../../redux/actions/groupActions'
import { updateUser, getUserList } from '../../../redux/actions/userActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import profilePlaceholder from '../../../Assets/placeholder.png';
import './GroupModal.css';
import axios from 'axios';
import { baseURL } from '../../../redux/helpers';

const EditMembersModal = ({ openEditMembersModal, editMembersModal, editContent, setEditContent, group, addMode, openGroupModal }) => {
    const dispatch = useDispatch();
    const { users } = useSelector(state => state.userReducer);
    const { updated, groups } = useSelector(state => state.groupReducer);
    const token = useSelector(state => state.authReducer.token);
    const [usersWithPhoto, setUsersWithPhoto] = useState([]);

    useEffect(() => {
        dispatch(getGroupList());
    }, []);

    useEffect(() => {
        if (updated) {
            dispatch(setUpdated(false));
        }
    }, [updated]);

    useEffect(async () => {
        if (users?.length) {
            setUsersWithPhoto(users)

            // Headers
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            //if there is a token then add it to the header
            if (token) {
                config.headers['x-auth-token'] = token;
            }
            const usersWithPhoto = []
            for (var i = 0; i < users.length; i++) {
                if (users[i].photo) {
                    const thePhotoType = users[i].photo.substring(0, 4);
                    if (thePhotoType == 'http' || thePhotoType == 'data') {
                        const originalPhoto = users[i].photo;
                        usersWithPhoto.push({ ...users[i], photoSRC: originalPhoto });
                    } else {
                        const { data } = await axios.post(`${baseURL}/users/getPhoto`, { photo: users[i].photo, _id: users[i]._id }, config);
                        let buf = Buffer.from(data.Body);
                        const file = users[i].photo.split('.')
                        const fileType = file[file.length - 1]
                        let base64 = buf.toString('base64');
                        const photoSRC = `data:image/${fileType};base64,${base64}`
                        usersWithPhoto.push({ ...users[i], photoSRC })
                    }
                } else {
                    const photoSRC = profilePlaceholder
                    usersWithPhoto.push({ ...users[i], photoSRC })
                }
            }
            setUsersWithPhoto(usersWithPhoto)
        } else {
            dispatch(getUserList())
        }
    }, [users]);

    function getCheckedUsersIds(array) {
        var holder = [];
        var theIds = [];
        for (let i = 0; i < users.length; i++) {
            holder.push(array[i]);
        }
        const checkedUsers = holder.filter(user =>
            user?.checked
        )
        checkedUsers.map(user => {
            theIds.push(user.id)
        })
        return theIds
    };

    function findArrayElementById(array, id) {
        return array.find((element) => {
            return element._id === id;
        })
    };

    const removeMember = (id) => {
        if (group) {
            var groupHolder = group;
            groupHolder.members = groupHolder.members.filter(function (item) {
                return item !== id
            });
            dispatch(updateGroup(groupHolder));
            openGroupModal(group);
            openEditMembersModal(group);
        }
    }

    const onSubmit = e => {
        e.preventDefault()
        if (group) {
            var groupHolder = group;
            var theIds = getCheckedUsersIds(e.target);
            groupHolder.members = theIds;
            dispatch(updateGroup(groupHolder));
            setEditContent(true);
            openEditMembersModal(group);
        }
    };

    const UserCards = () => {
        var allUsers = group.members?.map(user => {
            const fullUser = findArrayElementById(usersWithPhoto, user);
            if (!fullUser) { return }
            return <><div className='userContainerPc'>
                <div>
                    <img className="profile-avatar mr-3" src={fullUser.photoSRC ? fullUser.photoSRC : profilePlaceholder}></img>
                </div>
                <div className='nameContainer'>
                    <strong>{fullUser.personalDetails.name}</strong><br />{fullUser.companyDetails.jobTitle}
                </div>
                <div className='companyContainer'>
                    <strong>{fullUser.companyDetails.name}</strong><br />{fullUser.companyDetails.industry}
                </div>
                <div className='statusContainer'>
                    <span className={fullUser.status?.toLowerCase() == 'active' ? 'green-state' : 'red-state'}>{fullUser.status ? fullUser.status : 'Inactive'}</span>
                </div>
                <div className='otherInfoContainer'>
                    {fullUser.membershipDetails?.joinDate ? fullUser.membershipDetails.joinDate : 'N/A'}
                </div>
                <div className='otherInfoContainer'>
                    {fullUser.member_id ? fullUser.member_id : 'N/A'}
                </div>
                <div className="remove-mem-btn" onClick={() => removeMember(fullUser._id)}>
                    <FontAwesomeIcon icon={faTrash} /> Remove
                </div>
            </div>
                <div className='userContainerMobile'>
                    <div className='mobileRow'>
                        <img className="profile-avatar mr-3" src={fullUser.photoSRC ? fullUser.photoSRC : profilePlaceholder}></img>
                        <div style={{ marginTop: '-8px' }}>
                            <strong>{fullUser.personalDetails.name}</strong><br />{fullUser.companyDetails.jobTitle}
                        </div>
                        <div className="remove-mem-btn" onClick={() => removeMember(fullUser._id)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </div>
                    </div>
                    <div className='companyContainer'>
                        <strong>{fullUser.companyDetails.name}</strong><br />{fullUser.companyDetails.industry}
                    </div>
                    <br />
                    <div className='statusContainer'>
                        <span className={fullUser.status?.toLowerCase() == 'active' ? 'green-state' : 'red-state'}>{fullUser.status ? fullUser.status : 'Inactive'}</span>
                    </div>
                    <div className='mobileRow'>
                        <div className='otherInfoContainer'>
                            {fullUser.membershipDetails?.joinDate ? fullUser.membershipDetails.joinDate : 'N/A'}
                        </div>
                        <div className='otherInfoContainer'>
                            {fullUser.member_id ? fullUser.member_id : 'N/A'}
                        </div>
                    </div>
                </div></>
        })
        return allUsers || <p>No users</p>
    };

    const EditMembersContent = () => {
        return (
            <div>
                <Modal className='editMemModal' isOpen={editMembersModal} toggle={() => openEditMembersModal(group)}>
                    <ModalHeader toggle={() => openEditMembersModal(group)}>
                        <div className='addMemTitle1'>{'Group Name: ' + group?.name}</div>
                        <div className='addMemTitle2'>Current Members : {group.members?.length}</div>
                    </ModalHeader>
                    <Form onSubmit={onSubmit}>
                        <ModalBody className='editMemModalBody'>
                            <div className='topGroupActionsContainer'>
                                <Button className='addMemberButton' color="primary" onClick={() => setEditContent(false)}>{'Edit Members'}</Button>
                            </div>
                            <br />
                            <UserCards />
                        </ModalBody>
                    </Form>
                </Modal>
            </div >
        );
    };

    const AddMemersContent = () => {
        function isChecked(group, user) {
            if (tempIdsHolder?.includes(user._id)) {
                return true
            } else if (group.members.includes(user._id)) {
                if (trash?.includes(user._id)) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        };

        function changeCheckValue(e) {
            if (e.target.checked) {
                setTempIdsHolder([...tempIdsHolder, e.target.name])
                setTrash(trash.filter(function (item) {
                    return item !== e.target.id
                }))
            } else {
                setTempIdsHolder(tempIdsHolder.filter(function (item) {
                    return item !== e.target.id
                }))
                setTrash([...trash, e.target.name])
            }
            setRndValue(Math.random());
        };

        function shouldShowUser(user_id, group_id) {
            var theGroup = groups.find((group) => {
                return group.members.includes(user_id);
            })
            if (!theGroup || theGroup._id == group_id) {
                return true
            } else {
                return false
            }
        };

        const [rndValue, setRndValue] = useState(123);
        const [trash, setTrash] = useState([]);
        const [tempIdsHolder, setTempIdsHolder] = useState([]);

        return (
            <div>
                <Modal isOpen={editMembersModal} toggle={() => openEditMembersModal(group)}>
                    <ModalHeader toggle={() => openEditMembersModal(group)}>
                        <div className='addMemTitle1'>{'Select Members'}</div>
                    </ModalHeader>
                    <Form onSubmit={onSubmit}>
                        <ModalBody>
                            <FormGroup>
                                {users && rndValue ? users.map(user => {
                                    if (shouldShowUser(user._id, group._id)) {
                                        return <><label for={user._id}>
                                            <input type="checkbox" key={user._id} id={user._id} name={user._id} value={user._id} checked={isChecked(group, user)} onChange={e => { changeCheckValue(e) }}></input>
                                            {' ' + user.personalDetails.title + ' '}{user.personalDetails.name}
                                        </label>
                                            <br /></>
                                    }
                                }) : null}
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type='submit'>{'Update Members'}</Button>{' '}
                            <Button color="secondary" onClick={() => openEditMembersModal(group)}>Close</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div >
        );
    }

    return (
        editContent ? <EditMembersContent /> : <AddMemersContent />
    );
}

export default EditMembersModal;