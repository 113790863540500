import React, { useState } from 'react';
import { ListGroupItem, Modal, ModalHeader, ModalBody, ListGroup } from 'reactstrap';

const RSVPModal = ({ toggle, modal, userList }) => {

    return (
        <div>
            <Modal isOpen={modal} toggle={() => toggle()}>
                <ModalHeader toggle={() => toggle()}>Responses</ModalHeader>
                <ModalBody>
                    <ListGroup>
                        {userList.map(({ name, _id }) => <ListGroupItem key={_id}>{name}</ListGroupItem>)}
                    </ListGroup>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default RSVPModal;